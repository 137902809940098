<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Supplements</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">

    <div class="dashboard-item">
      <div>
        <h4 class="left">Supplements</h4>
        <div class="right">
          <img src="assets/icons/add.png" alt="Add Icon" class="image-button right" (click)="supplementEditor.open(0, listObject.id, null)">
        </div>
      </div>

      <ng-container *ngIf="listObject != null && listObject.supplements != null">
        <ng-container *ngFor="let supplement of listObject.supplements">
          <div class="bordered-item hoverable" [ngClass]="{'selected': supplement == selectedSupplement}" (click)="selectSupplement(supplement)" (dblclick)="supplementEditor.open(1, null, supplement.id)">
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <p><strong><ng-container *ngIf="supplement.details != null && supplement.details.length > 0">{{supplement.details[0].name}}</ng-container></strong></p>
              <p><ng-container *ngIf="supplement.details != null && supplement.details.length > 0">{{supplement.details[0].description}}</ng-container></p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img *ngIf="supplement.sequence > 0" (click)="moveUp(supplement, getSupplementBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="supplement.sequence < listObject.supplements.length - 1" (click)="moveDown(supplement, getSupplementBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img (click)="supplementEditor.open(1, null, supplement.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
              </div>
              <div class="dashboard-bordered-item-sub-right-bottom">
                <ng-container *ngIf="supplement.price != null">{{supplement.price | currency: 'EUR'}}</ng-container><ng-container *ngIf="supplement.price == null">N/A</ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <app-trailer></app-trailer>

  </div>

  <div class="menu-half-1-content">

    <div class="dashboard-item">
      <ng-container *ngIf="selectedSupplement != null">
        <div>
          <h4 class="left">Products</h4>
          <div class="right">
            <img class="image-button" (click)="supplementProductEditor.open(0, selectedSupplement.id, null)" src="assets/icons/add.png">
          </div>
        </div>

        <ng-container *ngIf="selectedSupplement.supplementProducts != null">
          <ng-container *ngFor="let supplementProduct of selectedSupplement.supplementProducts">

            <div class="bordered-item hoverable" (dblclick)="supplementProductEditor.open(1, null, supplementProduct.id)">
              <div class="left">
                <strong><ng-container *ngIf="supplementProduct != null && supplementProduct.product != null && supplementProduct.product.details != null && supplementProduct.product.details.length > 0">{{supplementProduct.product.details[0].name}}</ng-container></strong>
              </div>
              <div class="right">
                <div class="image-button-box"><img *ngIf="supplementProduct.sequence > 0" (click)="moveUp(supplementProduct, getSupplementProductBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="supplementProduct.sequence < selectedSupplement.supplementProducts.length - 1" (click)="moveDown(supplementProduct, getSupplementProductBaseEndpoint())" class="image-button" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img (click)="supplementProductEditor.open(1, null, supplementProduct.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="supplementProductEditor.open(2, null, supplementProduct.id)" src="assets/icons/delete_dark.png"></div>
              </div>
            </div>

          </ng-container>
        </ng-container>

      </ng-container>
    </div>

    <app-trailer></app-trailer>
  </div>

  <div>
    <app-dashboard-menu-supplement-editor [supplementsComponent]="this"></app-dashboard-menu-supplement-editor>
    <app-dashboard-menu-create-supplement-product [supplementsComponent]="this"></app-dashboard-menu-create-supplement-product>
  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
