import {Component, Input} from '@angular/core';
import {AbstractEditorComponent} from '../../../../../abstract/AbstractEditorComponent';
import {CatalogProductVariantCreateObject} from './CatalogProductVariantCreateObject';
import {CatalogProductVariant} from '../../../../../../domain/stock/CatalogProductVariant';
import {HttpClient} from '@angular/common/http';
import {IInit} from '../../../../../abstract/IInit';
import {Defaults} from '../../../../../../constants/defaults';
import {CatalogEditComponent} from '../../../edit.component';

@Component({
  selector: 'app-dashboard-catalog-product-variant-editor',
  templateUrl: './product-variant-editor.component.html',
  styleUrls: ['./product-variant-editor.component.css']
})
export class CatalogProductVariantEditorComponent extends AbstractEditorComponent<CatalogProductVariantCreateObject, CatalogProductVariant>{
  @Input() catalogEditComponent: CatalogEditComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.catalogEditComponent.setProductVariantEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_PRODUCT_VARIANT;
  }

  protected getInitableComponent(): IInit {
    return this.catalogEditComponent;
  }

  protected getTheObject(): CatalogProductVariant {
    return this.object.productVariant;
  }

}
