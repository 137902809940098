<app-normal-popup [editorComponent]="this" title="supplement -> product">
  <ng-container *ngIf="object != null && object.supplementProduct != null && object.categories != null">
    <table>
      <tr>
        <td width="64"></td>
        <th align="left" class="table-sub-header">Select product</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Category</td>
        <td>
          <label>
            <select [disabled]="mode === 2" [(ngModel)]="categoryId">
              <ng-container *ngFor="let category of object.categories"><option  ngValue="{{category.id}}">{{category.details[0].name}}</option></ng-container>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Product</td>
        <td>
          <label>
            <select [disabled]="mode === 2" [(ngModel)]="object.supplementProduct.productId">
              <ng-container *ngFor="let category of object.categories">
                <ng-container *ngFor="let product of category.products">
                  <ng-container *ngIf="(categoryId == null || categoryId === product.categoryId) || object.supplementProduct.productId === product.id"><option ngValue="{{product.id}}">{{product.details[0].name}} ({{product.price}})</option></ng-container>
                </ng-container>
              </ng-container>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Options</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Default option</td>
        <td>
          <label>
            <select [disabled]="mode === 2" [(ngModel)]="object.supplementProduct.defaultOption">
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <td>Condition key</td>
        <td><input type="text" value="0" [(ngModel)]="object.supplementProduct.conditionKey"/></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Condition</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/variants.png"></td>
        <td>Enabled by condition</td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.supplementProduct.conditionEnabled">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

      <tr>
        <td align="right"></td>
        <td>Condition</td>
        <td><input type="text" [disabled]="mode === 2" [(ngModel)]="object.supplementProduct.enablingCondition"></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
