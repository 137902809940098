<app-normal-popup [editorComponent]="this" title="variants, supplements, condiments and extra's to">
  <ng-container *ngIf="productBulkRequestObject != null && productBulkRequestObject.productId != null && categories != null">
    <table>
      <tr>
        <td width="64"></td>
        <th align="left" class="table-sub-header">Select product</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Category</td>
        <td>
          <label>
            <select [(ngModel)]="productBulkRequestObject.categoryId">
              <ng-container *ngFor="let category of categories"><option  ngValue="{{category.id}}">{{category.details[0].name}}</option></ng-container>
            </select>
          </label>
        </td>
      </tr>

      <ng-container *ngFor="let productBulkProductSelectObject of productBulkProductSelectObjects">
        <ng-container *ngIf="productBulkProductSelectObject.product.categoryId === productBulkRequestObject.categoryId">
          <tr>
            <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
            <td>{{productBulkProductSelectObject.product.details[0].name}}</td>
            <td><input type="checkbox" [(ngModel)]="productBulkProductSelectObject.selected"></td>
          </tr>
        </ng-container>
      </ng-container>

    </table>
  </ng-container>
</app-normal-popup>
