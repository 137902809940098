<app-normal-popup [editorComponent]="this" title="catalog">
  <ng-container *ngIf="object != null">
    <tr>
      <td></td>
      <th align="left" class="table-sub-header">Product Status</th>
      <td></td>
    </tr>
    <tr>
      <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
      <td>Status</td>
      <td>
        <label>
          <select [(ngModel)]="object.active">
            <option value="A">Active</option>
            <option value="I">Inactive</option>
          </select>
        </label>
      </td>
    </tr>

    <tr>
      <td></td>
      <th align="left" class="table-sub-header">Catalog Details</th>
      <td></td>
    </tr>

    <ng-container *ngFor="let item of object.details">
      <tr>
        <td rowspan="2" valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
        <td>Name</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
      </tr>
      <tr>
        <td valign="top" aria-autocomplete="none">Description</td>
        <td><textarea rows="3" style="margin-bottom: 4px; resize: vertical" [(ngModel)]="item.description"></textarea></td>
      </tr>
    </ng-container>
  </ng-container>
</app-normal-popup>

<app-dashboard-select-shop1 [iHaveSelectShopComponent]="this"></app-dashboard-select-shop1>
