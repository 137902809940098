<app-left-side-container [iHaveMyHttpHandler]="this">
  <ng-container *ngIf="listObject != null">
    <div class="dashboard-item">
      <div>
        <h4 class="left">Languages</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="shopLanguageEditor.open(0, listObject.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="listObject.shopLanguages != null">
        <ng-container *ngFor="let shopLanguage of listObject.shopLanguages">
          <div class="bordered-item hoverable" [ngClass]="{'selected': shopLanguage == selectedShopLanguage}" (click)="selectShopLanguage(shopLanguage)" (dblclick)="shopLanguageEditor.open(1, null, shopLanguage.id)">
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <p><strong><ng-container *ngIf="shopLanguage.language != null">{{shopLanguage.language.name}} ({{shopLanguage.languageCode}})</ng-container></strong></p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img (click)="shopLanguageEditor.open(1, null, shopLanguage.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                <div class="image-button-box"><img (click)="shopLanguageEditor.open(2, null, shopLanguage.id)" class="image-button" src="assets/icons/delete_dark.png"></div>
              </div>
              <div class="dashboard-bordered-item-sub-right-bottom">
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </ng-container>
</app-left-side-container>

<app-shop-language-editor [shopLanguageComponent]="this"></app-shop-language-editor>
