<div class="theleft">
  <div class="nav">
    <table>
      <ng-container *ngIf="userWorkgroups != null">
        <ng-container *ngFor="let userWorkgroup of userWorkgroups">
          <tr title="Select Menu" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'select'}" (click)="openLeftComponent('select')">
            <td class="nav-item-img"><img src="assets/icons/menu_light.png"></td>
            <td class="nav-item-title"><h4>{{userWorkgroup.workgroup.name}}</h4></td>
          </tr>

          <tr class="nav-separator"><td></td></tr>
          <tr class="nav-separator"><td></td></tr>

          <ng-container *ngIf="userWorkgroup != null && userWorkgroup.workgroup != null && userWorkgroup.workgroup.shops != null">
            <ng-container *ngFor="let shop of userWorkgroup.workgroup.shops">
              <tr title="Menu" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'edit'}" (click)="openLeftComponent('edit')">
                <td class="nav-item-img"><img src="assets/icons/shop_light.png"></td>
                <td class="nav-item-title"><h4>{{shop.name}}</h4></td>
              </tr>
            </ng-container>
          </ng-container>

          <tr class="nav-separator"><td></td></tr>
          <tr class="nav-separator"><td></td></tr>
          <tr class="nav-separator"><td></td></tr>
          <tr class="nav-separator"><td></td></tr>
          <tr class="nav-separator"><td></td></tr>
          <tr class="nav-separator"><td></td></tr>
        </ng-container>
      </ng-container>
    </table>
  </div>
</div>
