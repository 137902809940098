<app-normal-popup [editorComponent]="this" title="news image">
<ng-container *ngIf="object != null && object.newsImage != null && object.images != null">
  <table>
    <tr>
      <td width="64"></td>
      <th align="left" class="table-sub-header">Select Image</th>
      <td></td>
    </tr>

    <tr>
      <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
      <td>Variant</td>
      <td>
        <label>
          <select [disabled]="mode === 2" [(ngModel)]="object.newsImage.imageId">
            <ng-container *ngFor="let image of object.images"><option  ngValue="{{image.id}}">{{image.title}}</option></ng-container>
          </select>
        </label>
      </td>
    </tr>

    <tr>
      <td width="64"></td>
      <th align="left" class="table-sub-header">Format</th>
      <td></td>
    </tr>

    <tr>
      <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
      <td>Size (width)</td>
      <td>
        <label>
          <select [disabled]="mode === 2" [(ngModel)]="object.newsImage.size">
            <option value="64">64px</option>
            <option value="96">96px</option>
            <option value="128">128px</option>
            <option value="256">256px</option>
            <option value="512">512px</option>
            <option value="1024">1024px</option>
          </select>
        </label>
      </td>
    </tr>

    <tr>
      <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
      <td>Aspect ratio</td>
      <td>
        <label>
          <select [disabled]="mode === 2" [(ngModel)]="object.newsImage.aspectRatio">
            <optgroup label="Square">
              <option value="4-4">4/4 (Square)</option>
            </optgroup>
            <optgroup label="Landscape">
              <option value="4-3">4/3 (Rectangular)</option>
              <option value="4-2">4/2 (Wide)</option>
              <option value="4-1">4/1 (Ultra wide)</option>
            </optgroup>
            <optgroup label="Portrait">
              <option value="4-3">3/4 (Rectangular)</option>
            </optgroup>
          </select>
        </label>
      </td>
    </tr>
  </table>
</ng-container>
</app-normal-popup>
