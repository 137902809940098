import {MyHttpHandlerComponent} from './my-http-handler.component';
import {HttpClient} from '@angular/common/http';
import {MenuSubComponent} from '../menu/MenuSubComponent';

export abstract class IHaveMyHttpHandler implements MenuSubComponent {
  public myHttpHandlerComponent: MyHttpHandlerComponent;

  public isForcedToClose = false;

  protected constructor() {
  }

  public setMyHttpHandlerComponent(myHttpHandlerComponent: MyHttpHandlerComponent): void {
    this.myHttpHandlerComponent = myHttpHandlerComponent;
    this.init();
  }

  public abstract init(): void;

  public forceClose(forceClose: boolean): void {
    this.isForcedToClose = forceClose;
  }

}
