import {HttpClient} from '@angular/common/http';
import {SelectShopComponent1} from '../shop/select/select-shop.component';
import {IHaveSelectShopComponent} from '../shop/select/IHaveSelectShopComponent';
import {AbstractEditorComponent} from './AbstractEditorComponent';
import {EditorMode} from './EditorMode';
import {AbstractObject} from '../../domain/common/AbstractObject';

export abstract class AbstractRootEditorComponent<C extends AbstractObject> extends AbstractEditorComponent<C, C> implements IHaveSelectShopComponent {

  public selectShopComponent: SelectShopComponent1;

  protected abstract getApiEditingEndpoint(): string;

  protected constructor(http: HttpClient) {
    super(http);
  }

  public open(mode: EditorMode, parentId: string, objectId: string): void {
    this.mode = mode;

    if ( mode === EditorMode.CREATE ) {
      this.selectShopComponent.open(this.getApiEditingEndpoint(), (shopId: string) => {
        super.open(mode, shopId, null);
      });
    }
    else {
      super.open(mode, null, objectId);
    }
  }

  public setSelectShopComponent(selectShopComponent: SelectShopComponent1) {
    this.selectShopComponent = selectShopComponent;
  }
}
