<app-normal-popup [editorComponent]="this" title="catalog image">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Title</th>
        <td></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td>Name</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.title"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Image</th>
        <td></td>
      </tr>

      <tr>
        <img style="width: 48px; height: 48px" src="{{imageRoot}}get/64/4-4/{{object.imageId}}.png">
        <td>File</td>
        <td><div><input class="left" type="file" (change)="onFileSelect($event)"><button class="right"></button></div></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
