import {ChartType, Row} from 'angular-google-charts';

export class Chart {
  public name: string = "Name";
  public title: string = "Test";
  public type = ChartType.LineChart;
  public data: Row[];
  public columnNames = ["A", "B"];
  public options: any[];

  constructor() {
    this.data = [
      [1, 583],
      [2, 560],
      [3, 444],
      [4, 495],
      [5, 256],
      [6, 392],
      [7, 746],
      [8, 281],
      [9, 311],
      [10, 20]
    ]
  }
}
