import {Component, Input} from '@angular/core';
import {MenuComponent} from '../menu.component';
import {menuCondimentEditor} from './subpanels/create-condiment/menu-condiment-editor.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {Condiment} from '../../../domain/menu/Condiment';
import {Menu} from '../../../domain/menu/menu';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {MenuSubComponent} from '../MenuSubComponent';

@Component({
  selector: 'app-dashboard-menu-condiments',
  templateUrl: './condiments.component.html',
  styleUrls: ['./condiments.component.css']
})
export class CondimentsComponent extends SortableObjectPanel<Menu, Condiment> implements MenuSubComponent {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  public condimentEditor: menuCondimentEditor;

  public selectedCondiment: Condiment;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);
    super.init();
  }

  public setCreateCondimentComponent(condimentEditor: menuCondimentEditor): void {
    this.condimentEditor = condimentEditor;
  }

  public selectCondiment(condiment: Condiment): void {
    this.selectedCondiment = condiment;
  }

  public getObjectBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_CONDIMENT;
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_CONDIMENT;
  }
}
