<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Variants</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">

    <ng-container *ngIf="listObject != null">

      <div class="dashboard-item">
        <div>
          <h4 class="left">Variants</h4>
          <div class="right">
            <img src="assets/icons/add.png" class="image-button right" (click)="variantEditor.open(0, listObject.id, null)" alt="Add Icon">
          </div>
        </div>

        <ng-container *ngIf="listObject.variants != null">
          <ng-container *ngFor="let variant of listObject.variants">
            <div class="bordered-item hoverable" [ngClass]="{'selected': variant == selectedVariant}" (click)="selectVariant(variant)" (dblclick)="variantEditor.open(1, null, variant.id)">
              <div class="dashboard-bordered-item-sub-left width-img-4buttons">
                <p><strong><ng-container *ngIf="variant.details != null && variant.details.length > 0">{{variant.details[0].name}}</ng-container><ng-container *ngIf="variant.tip != null && variant.tip.length > 0"> ({{variant.tip}})</ng-container></strong></p>
              </div>
              <div class="dashboard-bordered-item-sub-right">
                <div class="dashboard-bordered-item-sub-right-top">
                  <div class="image-button-box"><img *ngIf="variant.sequence > 0" (click)="moveUp(variant, getObjectBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                  <div class="image-button-box"><img *ngIf="variant.sequence < listObject.variants.length - 1" (click)="moveDown(variant, getObjectBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                  <div class="image-button-box"><img (click)="variantEditor.open(1, null, variant.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

      </div>
    </ng-container>

    <div>
      <app-trailer></app-trailer>
    </div>

  </div>

  <div class="menu-half-1-content">
    <div class="dashboard-item">
      <ng-container *ngIf="selectedVariant != null">
        <div>
          <h4 class="left">Options</h4>
          <div class="right">
            <img class="image-button" (click)="variantOptionEditorComponent.open(0, selectedVariant.id, null)" src="assets/icons/add.png">
          </div>
        </div>

        <ng-container *ngIf="selectedVariant.variantOptions != null">
          <ng-container *ngFor="let variantOption of selectedVariant.variantOptions">

            <div class="bordered-item hoverable" (dblclick)="variantOptionEditorComponent.open(1, null, variantOption.id)">
              <div class="left">
                <strong><ng-container *ngIf="variantOption != null && variantOption != null && variantOption.details != null && variantOption.details.length > 0">{{variantOption.details[0].name}}</ng-container></strong>
              </div>
              <div class="right">
                <div class="image-button-box"><img *ngIf="variantOption.sequence > 0" (click)="moveUp(variantOption, getVariantOptionBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="variantOption.sequence < selectedVariant.variantOptions.length - 1" (click)="moveDown(variantOption, getVariantOptionBaseEndpoint())" class="image-button" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img (click)="variantOptionEditorComponent.open(1, null, variantOption.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
              </div>
            </div>

          </ng-container>
        </ng-container>

      </ng-container>
    </div>
  </div>
</div>
  <div>
    <app-dashboard-menu-variant-editor [variantsComponent]="this"></app-dashboard-menu-variant-editor>
    <app-dashboard-menu-variant-option-editor [variantsComponent]="this"></app-dashboard-menu-variant-option-editor>
  </div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
