import {Component, Input} from '@angular/core';
import {Shop} from '../../../domain/shop/shop';
import {Router} from '@angular/router';
import {ShopsComponent} from '../shops.component';
import {IHaveMyHttpHandler} from '../../my-http-handler/IHaveMyHttpHandler';

@Component({
  selector: 'app-dashboard-shops-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class ShopsNavigationComponent extends IHaveMyHttpHandler {
  @Input() public component: string;
  @Input() public shopId: string;
  @Input() public shopsComponent: ShopsComponent;

  public shops: Shop[];

  constructor(private router: Router) {
    super();
  }

  public init(): void {
  }

  public openComponent(component: string): void {
    this.shopsComponent.openComponent(component);
  }

  public getComponent(): string {
    return this.component;
  }
}
