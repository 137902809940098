<div class="theleft">
  <div class="nav">
    <table>

      <tr id="gallery" title="Menu" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'edit'}" (click)="openComponent('edit')">
        <td class="nav-item-img"><img src="assets/icons/gallery.png"></td>
        <td class="nav-item-title"><h4>Gallery</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>

      <tr id="tokens" title="Supplements" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'tokens'}" (click)="openComponent('tokens')">
        <td class="nav-item-img"><img src="assets/icons/token.png"></td>
        <td class="nav-item-title"><h4>Tokens</h4></td>
      </tr>

    </table>
  </div>
</div>
