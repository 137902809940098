<app-normal-popup [editorComponent]="this" title="news">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">News status</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Status</td>
        <td>
          <label>
            <select [disabled]="mode === 2 || mode === 99" [(ngModel)]="object.news.active">
              <option value="A">Active</option>
              <option value="I">Inactive</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">News details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.news.details">
        <tr>
          <td rowspan="2" valign="top" style="width: 64px"><img width="40" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [disabled]="mode === 2 || mode === 99" [(ngModel)]="item.name"></td>
        </tr>
        <tr>
          <td valign="top" aria-autocomplete="none">Description</td>
          <td><textarea rows="3" [disabled]="mode === 2 || mode === 99" [(ngModel)]="item.description"></textarea></td>
        </tr>
      </ng-container>

      <tr>
        <td style="width: 64px"><img class="image-icon" src="assets/icons/condiment_light.png" alt="CONDIMENTS"></td>
        <td>Email template</td>
        <td>
          <select [disabled]="mode === 2 || mode === 99" [(ngModel)]="object.news.templateId">
            <option *ngFor="let emailTemplate of object.emailTemplates" ngValue="{{emailTemplate.id}}">{{emailTemplate.details[0].name}}</option>
          </select>
        </td>
      </tr>

      <tr *ngIf="mode === 99">
        <td></td>
        <th align="left" class="table-sub-header">Test</th>
        <td></td>
      </tr>

      <tr *ngIf="mode === 99">
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Test</td>
        <td>
          <label>
            <select [(ngModel)]="simulation">
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </label>
        </td>
      </tr>
    </table>
  </ng-container>
</app-normal-popup>
