import {Component, Input, OnInit} from '@angular/core';
import {NormalPopupComponent} from '../normal-popup/normal-popup.component';
import {AbstractNormalPopupContent} from '../normal-popup/AbstractNormalPopupContent';

@Component({
  selector: 'app-popup-content-test',
  templateUrl: './popup-content-test.component.html',
  styleUrls: ['./popup-content-test.component.css']
})
export class PopupContentTestComponent extends AbstractNormalPopupContent implements OnInit {
  @Input() popupComponent: NormalPopupComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  public submit(): void {
    alert('Submit ok');
  }

}
