<app-dashboard-header mainComponent="stock"></app-dashboard-header>
<ng-container *ngIf="user != null && user.stockAppEnabled">
  <app-dashboard-stock-navigation [component]="getComponent()" [stockComponent]="this"></app-dashboard-stock-navigation>
</ng-container>
<main>
  <ng-container *ngIf="user != null && user.stockAppEnabled">
    <div class="menu-full-tools" style="z-index: 2">
      <app-dashboard-stock-editing-catalog [stockComponent]="this"></app-dashboard-stock-editing-catalog>
    </div>
    <app-dashboard-catalog-edit *ngIf="getComponent() === 'edit'" [stockComponent]="this" [parameter]="getParameter()" pageIndex="0"></app-dashboard-catalog-edit>
    <app-dashboard-catalog-image *ngIf="getComponent() === 'image'" [stockComponent]="this"></app-dashboard-catalog-image>
    <app-dashboard-stock-variant *ngIf="getComponent() === 'variants'" [stockComponent]="this"></app-dashboard-stock-variant>
    <app-dashboard-catalog-news *ngIf="getComponent() === 'news'" [stockComponent]="this"></app-dashboard-catalog-news>
    <app-dashboard-stock-email-configurations *ngIf="getComponent() === 'emailConfigurations'" [stockComponent]="this"></app-dashboard-stock-email-configurations>
    <app-dashboard-stock-email-templates *ngIf="getComponent() === 'emailTemplates'" [stockComponent]="this"></app-dashboard-stock-email-templates>
    <app-dashboard-stock-email-subscriptions *ngIf="getComponent() === 'emailSubscriptions'" [stockComponent]="this"></app-dashboard-stock-email-subscriptions>
    <app-dashboard-catalog-promotion *ngIf="getComponent() === 'promotion'" [stockComponent]="this"></app-dashboard-catalog-promotion>
    <app-empty *ngIf="getComponent() == 'empty'" pageIndex="0"></app-empty>
    <div class="menu-middle-tools">
      <div class="menu-middle-tools-box">
      </div>
      <div class="menu-middle-tools-box">
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="user != null && !user.stockAppEnabled">
    <div>
      <div style="display: flex; align-content: center; justify-content: center; padding-bottom: 15px">
        <img width="128" height="128" src="assets/illustrations/streamline-icon-program-error-2@128x128.png">
      </div>
      <h3 style="clear: both">Sorry, stock application is not activated for this user.</h3>
    </div>
  </ng-container>
</main>

<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
