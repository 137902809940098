import {Component, Input} from '@angular/core';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {CatalogNewsProperty} from '../../../../domain/stock/CatalogNewsProperty';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {IInit} from '../../../abstract/IInit';
import {CatalogNewsProduct} from '../../../../domain/stock/CatalogNewsProduct';
import {NewsComponent} from '../news.component';

@Component({
  selector: 'app-dashboard-catalog-news-property-editor',
  templateUrl: './catalog-news-property-editor.component.html',
  styleUrls: ['./catalog-news-property-editor.component.css']
})
export class CatalogNewsPropertyEditorComponent extends AbstractEditorComponent<CatalogNewsProperty, CatalogNewsProperty> {
  @Input() newsComponent: NewsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.newsComponent.setNewsPropertyEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_NEWS_PROPERTY;
  }

  protected getInitableComponent(): IInit {
    return this.newsComponent;
  }

  protected getTheObject(): CatalogNewsProperty {
    return this.object;
  }
}
