import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {StockSubComponent} from './suppliers/StockSubComponent';
import {User} from '../../domain/user/user';
import {IHaveMyHttpHandler} from '../my-http-handler/IHaveMyHttpHandler';
import {HttpHandler} from '../../handlers/httpHandler';
import {Defaults} from '../../constants/defaults';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.css']
})
export class StockComponent extends IHaveMyHttpHandler {
  private subComponent: StockSubComponent;

  public user: User;

  private componentNames: Array<string> = ['select', 'edit', 'image', 'suppliers', 'clients', 'variants', 'news', 'emailConfigurations', 'emailTemplates', 'emailSubscriptions', 'promotion', 'allergens', 'availability', 'zones', 'printers', 'images', 'qrconfig', 'tablets', 'empty'];
  private component: string;
  private parameter: string;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    super();
  }

  public init(): void {
    this.setComponents();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        this.setComponents();
      }
    });

    this.getUser();
  }

  private getUser(): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<User>(this.http.get<User>(Defaults.SERVER_API_USER, { headers }), (result: User) => {
      this.user = result;
    })
  }

  private setComponents(): void {
    this.component = this.route.snapshot.paramMap.get('component');
    this.parameter = this.route.snapshot.paramMap.get('parameter');

    if (this.componentNames.indexOf(this.component) === -1) {
      this.component = this.componentNames[this.componentNames.length - 1];
    }
    if (this.parameter === null){
      this.parameter = 'root';
    }
  }

  public getComponent(): string {
    return this.component;
  }

  public getParameter(): string {
    return this.parameter;
  }

  public openComponent(component: string): void {
    this.component = component;
    this.parameter = 'root';

    this.navigate();
  }

  public setParameter(parameter: string): void {
    this.parameter = parameter;

    this.navigate();
  }

  private navigate(): void {
    let url = '/stock/';

    url += this.component;
    url += '/' + this.parameter;

    this.router.navigateByUrl(url);
  }

  public setSubComponent(subComponent: StockSubComponent) {
    this.subComponent = subComponent;
  }

  public selectionChanged(): void {
    if ( this.subComponent != null ) {
      this.subComponent.init();
    }
  }
}
