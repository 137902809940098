import {Component, Input} from '@angular/core';
import {AbstractSelectComponent} from '../../../../menu/editing-menu/popups/select-menu/AbstractSelectComponent';
import {AbstractEditingSelectionComponent} from '../../../../menu/editing-menu/AbstractEditingSelectionComponent';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {Shop} from '../../../../../domain/shop/shop';
import {IInit} from '../../../../abstract/IInit';
import {ShopCreateObject} from '../../../shop/update-shop-component/ShopCreateObject';

@Component({
  selector: 'app-dashboard-shop-select-shop',
  templateUrl: './select-shop.component.html',
  styleUrls: ['./select-shop.component.css']
})
export class SelectShopComponent extends AbstractSelectComponent<ShopCreateObject, Shop> {
  @Input() public editingSelectionComponent: AbstractEditingSelectionComponent<ShopCreateObject, Shop>;

  constructor(http: HttpClient) {
    super(http);
  }

  protected getAbstractEditingSelectionComponent(): AbstractEditingSelectionComponent<ShopCreateObject, Shop> {
    return this.editingSelectionComponent;
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_SHOP_SELECTION;
  }

  protected getSelectedShopId(): string {
    return this.object.user.selectedShopId;
  }

  protected getSelectionId(): string {
    return null;
  }

  protected getSelectedLanguageCode(): string {
    return null;
  }

  public init(): void {
    this.editingSelectionComponent.setSelectComponent(this);
  }

  protected getInitableComponent(): IInit {
    return null;
  }

  protected getTheObject(): Shop {
    return null;
  }
}
