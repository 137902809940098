<script src="hrc-menu/edit/menu.js"></script>
<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Delivery</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">

    <!-- Menu Delivery -->
    <div class="dashboard-item">
      <ng-container *ngIf="shop != null">
        <div>
          <h4 class="left">Townships</h4>
          <div class="right">
            <img class="image-button" (click)="deliveryEditor.open(0, shop.id, null)" src="assets/icons/add.png">
          </div>
        </div>

        <ng-container *ngIf="shop.menuDeliveries != null">
          <ng-container *ngFor="let delivery of shop.menuDeliveries">
            <div class="bordered-item hoverable" [ngClass]="{'selected': delivery == selectedDelivery}" (dblclick)="deliveryEditor.open(1, null, delivery.id)" (click)="selectTownship(delivery)">
              <div class="left">
                <strong>{{delivery.details[0].name}}</strong>
                <p><span [ngClass]="{'strike': !delivery.monday}">Ma</span>, <span [ngClass]="{'strike': !delivery.tuesday}">Di</span>, <span [ngClass]="{'strike': !delivery.wednesday}">Wo</span>, <span [ngClass]="{'strike': !delivery.thursday}">Do</span>, <span [ngClass]="{'strike': !delivery.friday}">Vr</span>, <span [ngClass]="{'strike': !delivery.saturday}">Za</span>, <span [ngClass]="{'strike': !delivery.sunday}">Zo</span></p>
              </div>
              <div class="right">
                <div class="image-button-box"><img *ngIf="delivery.sequence > 0" (click)="moveUp(delivery, getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="delivery.sequence < shop.menuDeliveries.length - 1" (click)="moveDown(delivery, getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img class="image-button" src="assets/icons/edit_dark.png" (click)="deliveryEditor.open(1, null, delivery.id)"></div>
              </div>
            </div>

          </ng-container>
        </ng-container>

      </ng-container>
    </div>

    <app-trailer></app-trailer>
  </div>

  <div>
    <app-dashboard-menu-create-delivery [deliveryComponent]="this"></app-dashboard-menu-create-delivery>
  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
