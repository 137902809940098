<app-left-side-container [iHaveMyHttpHandler]="this">
  <ng-container *ngIf="listObject != null">

    <div class="dashboard-item">
      <div>
        <h4 class="left">Promotions</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="promotionEditorComponent.open(0, listObject.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="listObject.promotions != null">
        <ng-container *ngFor="let promotion of listObject.promotions">
          <div class="bordered-item hoverable" [ngClass]="{'selected': promotion == selectedPromotion}" (click)="selectPromotion(promotion)" (dblclick)="promotionEditorComponent.open(1, null, promotion.id)">
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <p><strong><ng-container *ngIf="promotion.details != null && promotion.details.length > 0">{{promotion.details[0].name}}</ng-container></strong></p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img *ngIf="promotion.sequence > 0" (click)="moveUp(promotion, getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="promotion.sequence < listObject.promotions.length - 1" (click)="moveDown(promotion, getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img (click)="promotionEditorComponent.open(1, null, promotion.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>

  </ng-container>
</app-left-side-container>

<app-dashboard-catalog-promotion-editor [promotionComponent]="this"></app-dashboard-catalog-promotion-editor>
