import {Component, Input, OnInit} from '@angular/core';
import {MenuComponent} from '../menu.component';

@Component({
  selector: 'app-dashboard-menu-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class MenuNavigationComponent implements OnInit {
  @Input() leftComponent: string;
  @Input() menuComponent: MenuComponent;

  constructor() {
  }

  ngOnInit(): void {
  }

  public getLeftComponent(): string {
    return this.leftComponent;
  }

  public openComponent(component: string): void {
    this.menuComponent.openComponent(component);
  }
}
