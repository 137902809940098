<app-left-side-container [iHaveMyHttpHandler]="this">
  <ng-container *ngIf="object != null">
    <div class="dashboard-item">
      <div>
        <h4 class="left">Address</h4>
        <div class="right">

        </div>
      </div>
    </div>

    <div class="dashboard-item">
      <div>
        <h4 class="left">Contact</h4>
        <div class="right">

        </div>
      </div>
    </div>
  </ng-container>
</app-left-side-container>
