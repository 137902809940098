import {Component, Input} from '@angular/core';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {CatalogNewsImage} from '../../../../domain/stock/CatalogNewsImage';
import {CatalogNewsImageCreateObject} from './CatalogNewsImageCreateObject';
import {HttpClient} from '@angular/common/http';
import {NewsComponent} from '../news.component';
import {IInit} from '../../../abstract/IInit';
import {Defaults} from '../../../../constants/defaults';

@Component({
  selector: 'app-dashboard-catalog-news-image-editor',
  templateUrl: './catalog-news-image-editor.component.html',
  styleUrls: ['./catalog-news-image-editor.component.css']
})
export class CatalogNewsImageEditorComponent extends AbstractEditorComponent<CatalogNewsImageCreateObject, CatalogNewsImage>{
  @Input() newsComponent: NewsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.newsComponent.setNewsImageEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_NEWS_IMAGE;
  }

  protected getInitableComponent(): IInit {
    return this.newsComponent;
  }

  protected getTheObject(): CatalogNewsImage {
    return this.object.newsImage;
  }
}
