<div>
  <div id="half-window" class="menu-half-0-content">
    <div>

      <div class="dashboard-item">
        <div>
          <div class="menu-tree-item menu-tree-item-m" (click)="openCategory('root')"><p>MENU</p></div>
          <ng-container *ngIf="category != null">
            <ng-container *ngFor="let category of categoryRoot; let i = index">
              <img class="menu-tree-arrow" src="assets/icons/menu_tree_arrow.png">
              <div class="menu-tree-item menu-tree-item-{{i}}" (click)="openCategory(category.id)"><p><ng-container *ngIf="category.details != null && category.details.length > 0">{{category.details[0].name}}</ng-container></p></div>
            </ng-container>
          </ng-container>
        </div>

        <ng-container *ngIf="menu != null">
          <div class="bordered-item hoverable" [ngClass]="{'inactive-item': menu.active !== 'A'}">
            <div class="dashboard-bordered-item-sub-left">
              <p><strong><ng-container *ngIf="menu.details != null && menu.details.length > 0">{{menu.details[0].name}}</ng-container></strong></p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right">
                <img width="24" style="margin: 0 10px 0 8px" src="assets/flags/{{menu.details[0].languageCode}}-s.jpg" alt="FLAG">{{menu.details[0].languageCode}}
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="category != null">
          <div class="bordered-item hoverable" [ngClass]="{'inactive-item': category.active !== 'A'}" (dblclick)="categoryEditorComponent.open(1, null, category.id)">
            <div class="dashboard-bordered-item-sub-left">
              <div class="table-menu-image-container"><img class="table-menu-image" src="{{SERVER_CONTENT}}/menu/8563332019131217091257114363.jpeg"></div>
            </div>
            <div class="dashboard-bordered-item-sub-left width-img-2buttons">
              <p><strong><ng-container *ngIf="category.details != null && category.details.length > 0">{{category.details[0].name}}</ng-container></strong></p>
              <p><ng-container *ngIf="category.details != null && category.details.length > 0">{{category.details[0].description}}</ng-container></p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img (click)="categoryEditorComponent.open(1, null, category.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="dashboard-item">
        <div>
          <h4 class="left"><ng-container *ngIf="category != null">Sub </ng-container>Categories</h4>
          <div class="right">
            <img src="assets/icons/add.png" alt="Add Icon" class="image-button right" (click)="categoryEditorComponent.open(0, parameter, null)">
            <select [(ngModel)]="orderBy" (ngModelChange)="categoryOrderChanged()">
              <option value="M">Menu order</option>
              <option value="P">Print order</option>
            </select>
          </div>
        </div>

        <!-- Categories -->
        <ng-container *ngIf="categories != null">
          <ng-container *ngFor="let category of categories">
            <div class="bordered-item hoverable" [ngClass]="{'selected': category == selectedCategory, 'inactive-item': category.active !== 'A'}" (click)="selectCategory(category)" (dblclick)="openCategory(category.id)">
              <div class="dashboard-bordered-item-sub-left">
                <div class="table-menu-image-container"><img class="table-menu-image" src="{{SERVER_CONTENT}}/menu/8563332019131217091257114363.jpeg"></div>
              </div>
              <div class="dashboard-bordered-item-sub-left width-img-4buttons">
                <p><strong><ng-container *ngIf="category.details != null && category.details.length > 0">{{category.details[0].name}}</ng-container></strong></p>
                <p><ng-container *ngIf="category.details != null && category.details.length > 0">{{category.details[0].description}}</ng-container></p>
              </div>
              <div class="dashboard-bordered-item-sub-right">
                <div class="dashboard-bordered-item-sub-right-top">
                  <div class="image-button-box"><img *ngIf="category.sequence > 0" (click)="orderBy === 'M'? moveUp(category, getCategoryBaseEndpoint()): moveUpSecondSequence(category, getCategoryBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                  <div class="image-button-box"><img *ngIf="category.sequence < categories.length - 1" (click)="orderBy === 'M'? moveDown(category, getCategoryBaseEndpoint()): moveDownSecondSequence(category, getCategoryBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                  <div class="image-button-box"><img (click)="openCategory(category.id)" class="image-button" src="assets/icons/select_dark.png" alt="SELECT"></div>
                  <div class="image-button-box"><img (click)="categoryEditorComponent.open(1, null, category.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <!-- Products -->
      <div class="dashboard-item">
        <ng-container *ngIf="parameter !== 'root'">
          <div>
            <h4 class="left">Products</h4>
            <div class="right">
              <img src="assets/icons/add.png" alt="Add Icon" class="image-button right" (click)="productEditorComponent.open(0, parameter, null)">
            </div>
          </div>

          <ng-container *ngIf="products != null">
            <ng-container *ngFor="let product of products">
              <div class="bordered-item hoverable" [ngClass]="{'selected': product == selectedProduct, 'inactive-item': product.active !== 'A'}" (click)="selectProduct(product)" (dblclick)="productEditorComponent.open(1, null, product.id)">
                <div class="dashboard-bordered-item-sub-left">
                  <div class="table-menu-image-container"><img class="table-menu-image" src="{{imageRoot}}image/3/128/{{product.productImageId}}.png"></div>
                </div>
                <div class="dashboard-bordered-item-sub-left width-img-4buttons">
                  <p><strong><ng-container *ngIf="product.details != null && product.details.length > 0">{{product.details[0].name}}</ng-container></strong></p>
                  <p><ng-container *ngIf="product.details != null && product.details.length > 0">{{product.details[0].description}}</ng-container></p>
                </div>
                <div class="dashboard-bordered-item-sub-right">
                  <div class="dashboard-bordered-item-sub-right-top">
                    <div class="image-button-box"><img *ngIf="product.sequence > 0" (click)="moveUp(product, getProductBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                    <div class="image-button-box"><img *ngIf="product.sequence < products.length - 1" (click)="moveDown(product, getProductBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                    <div class="image-button-box"><img (click)="copyRelationsComponent.open(9, product.id, null)" class="image-button" src="assets/icons/streamline-icon-task-list-multiple@140x140.png"></div>
                    <div class="image-button-box"><img (click)="productEditorComponent.open(1, null, product.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                  </div>
                  <div class="dashboard-bordered-item-sub-right-bottom">
                    <ng-container *ngIf="product.price != null">{{product.price / 100 | currency: 'EUR'}}</ng-container><ng-container *ngIf="product.price == null">N/A</ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <app-trailer></app-trailer>
    </div>
  </div>

  <div class="menu-half-1-content">

    <!-- Product Variants -->
    <div class="dashboard-item">
      <ng-container *ngIf="selectedProduct != null">
        <div>
          <h4 class="left">Variants</h4>
          <div class="right">
            <img class="image-button" (click)="productVariantEditor.open(0, selectedProduct.id, null)" src="assets/icons/add.png">
          </div>
        </div>

        <ng-container *ngIf="selectedProduct.productVariants != null">
          <ng-container *ngFor="let productVariant of selectedProduct.productVariants">
            <div class="bordered-item hoverable" (dblclick)="productVariantEditor.open(1, null, productVariant.id)">
              <div class="left">
                <strong>{{productVariant.variant.details[0].name}}<ng-container *ngIf="productVariant.variant.tip != null && productVariant.variant.tip.length > 0"> ({{productVariant.variant.tip}})</ng-container></strong>
              </div>
              <div class="right">
                <div class="image-button-box"><img *ngIf="productVariant.sequence > 0" (click)="moveUp(productVariant, getProductVariantBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="productVariant.sequence < selectedProduct.productVariants.length - 1" (click)="moveDown(productVariant, getProductVariantBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="productVariantEditor.open(1, null, productVariant.id)" src="assets/icons/edit_dark.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="productVariantEditor.open(2, null, productVariant.id)" src="assets/icons/delete_dark.png"></div>
              </div>
            </div>

          </ng-container>
        </ng-container>

      </ng-container>
    </div>

    <!-- Product Supplements -->
    <div class="dashboard-item">
      <ng-container *ngIf="selectedProduct != null">
        <div>
          <h4 class="left">Supplements</h4>
          <div class="right">
            <img class="image-button" (click)="productSupplementEditor.open(0, selectedProduct.id, null)" src="assets/icons/add.png">
          </div>
        </div>

        <ng-container *ngIf="selectedProduct.productSupplements != null">
          <ng-container *ngFor="let productSupplement of selectedProduct.productSupplements">
            <div class="bordered-item hoverable" (dblclick)="productSupplementEditor.open(1, null, productSupplement.id)">
              <div class="left">
                <strong>{{productSupplement.supplement.details[0].name}}</strong>
              </div>
              <div class="right">
                <div class="image-button-box"><img *ngIf="productSupplement.sequence > 0" (click)="moveUp(productSupplement, getProductSupplementBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="productSupplement.sequence < selectedProduct.productSupplements.length - 1" (click)="moveDown(productSupplement, getProductSupplementBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="productSupplementEditor.open(1, null, productSupplement.id)" src="assets/icons/edit_dark.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="productSupplementEditor.open(2, null, productSupplement.id)" src="assets/icons/delete_dark.png"></div>
              </div>
            </div>

          </ng-container>
        </ng-container>

      </ng-container>
    </div>

    <!-- Product Condiments -->
    <div class="dashboard-item">
      <ng-container *ngIf="selectedProduct != null">
        <div>
          <h4 class="left">Condiments</h4>
          <div class="right">
            <img class="image-button" (click)="productCondimentEditor.open(0, selectedProduct.id, null)" src="assets/icons/add.png">
          </div>
        </div>

        <ng-container *ngIf="selectedProduct.productCondiments != null">
          <ng-container *ngFor="let productCondiment of selectedProduct.productCondiments">
            <div class="bordered-item hoverable" (dblclick)="productCondimentEditor.open(1, null, productCondiment.id)">
              <div class="left">
                <strong><ng-container *ngIf="productCondiment.condiment.details != null && productCondiment.condiment.details.length > 0">{{productCondiment.condiment.details[0].name}}</ng-container></strong>
              </div>
              <div class="right">
                <div class="image-button-box"><img *ngIf="productCondiment.sequence > 0" (click)="moveUp(productCondiment, getProductCondimentBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="productCondiment.sequence < selectedProduct.productCondiments.length - 1" (click)="moveDown(productCondiment, getProductCondimentBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="productCondimentEditor.open(1, null, productCondiment.id)" src="assets/icons/edit_dark.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="productCondimentEditor.open(2, null, productCondiment.id)" src="assets/icons/delete_dark.png"></div>
              </div>
            </div>

          </ng-container>
        </ng-container>

      </ng-container>
    </div>

    <!-- Product Extra's -->
    <div class="dashboard-item">
      <ng-container *ngIf="selectedProduct != null">
        <div>
          <h4 class="left">Extra's</h4>
          <div class="right">
            <img class="image-button" (click)="productExtraEditor.open(0, selectedProduct.id, null)" src="assets/icons/add.png">
          </div>
        </div>

        <ng-container *ngIf="selectedProduct.productExtras != null">
          <ng-container *ngFor="let productExtra of selectedProduct.productExtras">
            <div class="bordered-item hoverable" (dblclick)="productExtraEditor.open(1, null, productExtra.id)">
              <div class="left">
                <strong><ng-container *ngIf="productExtra.condiment.details != null && productExtra.condiment.details.length > 0">{{productExtra.condiment.details[0].name}}</ng-container> ({{productExtra.condiment.price / 100 | currency: 'EUR'}})</strong>
              </div>
              <div class="right">
                <div class="image-button-box"><img *ngIf="productExtra.sequence > 0" (click)="moveUp(productExtra, getProductExtraBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="productExtra.sequence < selectedProduct.productExtras.length - 1" (click)="moveDown(productExtra, getProductExtraBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="productExtraEditor.open(1, null, productExtra.id)" src="assets/icons/edit_dark.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="productExtraEditor.open(2, null, productExtra.id)" src="assets/icons/delete_dark.png"></div>
              </div>
            </div>

          </ng-container>
        </ng-container>

      </ng-container>
    </div>

    <!-- Product Promotions -->
    <div class="dashboard-item">
      <ng-container *ngIf="selectedProduct != null">
        <div>
          <h4 class="left">Promotions</h4>
          <div class="right">
            <img class="image-button" (click)="productPromotionEditor.open(0, selectedProduct.id, null)" src="assets/icons/add.png">
          </div>
        </div>

        <ng-container *ngIf="selectedProduct.productPromotions != null">
          <ng-container *ngFor="let productPromotion of selectedProduct.productPromotions">
            <div class="bordered-item hoverable" (dblclick)="productPromotionEditor.open(1, null, productPromotion.id)">
              <div class="left">
                <strong><ng-container *ngIf="productPromotion.promotion.details != null && productPromotion.promotion.details.length > 0">{{productPromotion.promotion.details[0].name}} (</ng-container><ng-container *ngIf="productPromotion.promotion.valueMode === 'A'">Discount {{productPromotion.promotion.value / 100 | currency: 'EUR'}}</ng-container><ng-container *ngIf="productPromotion.promotion.valueMode === 'N'">New price {{productPromotion.promotion.value / 100 | currency: 'EUR'}}</ng-container><ng-container *ngIf="productPromotion.promotion.valueMode === 'P'">Discount {{productPromotion.promotion.value}}%</ng-container>)</strong>
              </div>
              <div class="right">
                <div class="image-button-box"><img *ngIf="productPromotion.sequence > 0" (click)="moveUp(productPromotion, getProductExtraBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="productPromotion.sequence < selectedProduct.productExtras.length - 1" (click)="moveDown(productPromotion, getProductExtraBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="productPromotionEditor.open(1, null, productPromotion.id)" src="assets/icons/edit_dark.png"></div>
                <div class="image-button-box"><img class="image-button" (click)="productPromotionEditor.open(2, null, productPromotion.id)" src="assets/icons/delete_dark.png"></div>
              </div>
            </div>

          </ng-container>
        </ng-container>

      </ng-container>
    </div>

    <app-trailer></app-trailer>
  </div>

  <div>
    <app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>

    <!-- ---------- EDIT POPUPS HERE ---------- -->
    <app-dashboard-menu-category-editor [editComponent]="this"></app-dashboard-menu-category-editor>
    <app-dashboard-menu-product-editor [editComponent]="this"></app-dashboard-menu-product-editor>

    <app-dashboard-menu-product-variant-editor [editComponent]="this"></app-dashboard-menu-product-variant-editor>
    <app-dashboard-menu-add-supplement [editComponent]="this"></app-dashboard-menu-add-supplement>
    <app-dashboard-menu-add-condiment [editComponent]="this"></app-dashboard-menu-add-condiment>
    <app-dashboard-menu-add-extra [editComponent]="this"></app-dashboard-menu-add-extra>
    <app-dashboard-menu-add-product-promotion [editComponent]="this"></app-dashboard-menu-add-product-promotion>

    <app-dashboard-menu-copy-relations [editComponent]="this"></app-dashboard-menu-copy-relations>
  </div>
</div>
