<app-normal-popup [editorComponent]="this" title="supplement">
  <ng-container *ngIf="object != null">
    <table>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Supplement details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td rowspan="2" valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
        <tr>
          <td valign="top" aria-autocomplete="none">Description</td>
          <td><textarea rows="3" style="margin-bottom: 4px; resize: vertical" [(ngModel)]="item.description"></textarea></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Supplement options</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Mode</td>
        <td>
          <label>
            <select [(ngModel)]="object.mode">
              <option value="F">Free</option>
              <option value="D">Discount</option>
              <option value="P">Payed</option>
            </select>
          </label>
        </td>
      </tr>

      <ng-container *ngIf="object.mode === 'D' || object.mode === 'E'">
        <tr>
          <td></td>
          <td>Discount (cents)</td>
          <td><input type="number" autocomplete="none" [(ngModel)]="object.price"></td>
        </tr>
      </ng-container>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Required</td>
        <td>
          <label>
            <select [(ngModel)]="object.required">
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </label>
        </td>
      </tr>

      <ng-container *ngIf="object.required === 'N'">
        <tr>
          <td></td>
          <th align="left" class="table-sub-header">Empty option details</th>
          <td></td>
        </tr>

        <ng-container *ngFor="let item of object.details">
          <tr>
            <td rowspan="2" valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
            <td>Name</td>
            <td><input type="text" autocomplete="none" [(ngModel)]="item.emptyOptionName"></td>
          </tr>
          <tr>
            <td valign="top" aria-autocomplete="none">Description</td>
            <td><textarea rows="3" style="margin-bottom: 4px; resize: vertical" [(ngModel)]="item.emptyOptionDescription"></textarea></td>
          </tr>
        </ng-container>
      </ng-container>

    </table>
  </ng-container>
</app-normal-popup>
