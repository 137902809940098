import {Component, Input, OnInit} from '@angular/core';
import {PosTabletsComponent} from '../../tablets.component';
import {IHaveMyHttpHandler} from '../../../../my-http-handler/IHaveMyHttpHandler';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {PosTablet} from '../../../../../domain/pos/PosTablet';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-pos-update-tablet',
  templateUrl: './menu-pos-tablet-editor.component.html',
  styleUrls: ['./menu-pos-tablet-editor.component.css']
})
export class MenuPosTabletEditorComponent extends AbstractEditorComponent<PosTablet, PosTablet>{
  @Input() tabletsComponent: PosTabletsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.tabletsComponent.setMenuPosTabletEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_POS_TABLET;
  }

  protected getInitableComponent(): IInit {
    return this.tabletsComponent;
  }

  protected getTheObject(): PosTablet {
    return this.object;
  }

}
