<app-normal-popup [editorComponent]="this" title="news -> variant">
  <ng-container *ngIf="object != null && object.variants != null">
    <table>
      <tr>
        <td width="64"></td>
        <th align="left" class="table-sub-header">Select variant</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Variant</td>
        <td>
          <label>
            <select [disabled]="mode === 2" [(ngModel)]="object.newsVariant.variantId">
              <ng-container *ngFor="let variant of object.variants"><option  ngValue="{{variant.id}}">{{variant.details[0].name}}</option></ng-container>
            </select>
          </label>
        </td>
      </tr>
    </table>
  </ng-container>
</app-normal-popup>
