<div class="menu-title">
  <div *ngIf="displayNames != null">
    <div class="menu-tree-item menu-tree-item-4"><p>{{displayNames.shopName}}</p></div>
    <div class="menu-tree-item menu-tree-item-4"><p>{{displayNames.selectionName}}</p></div>
    <div class="menu-tree-item menu-tree-item-4"><p>{{displayNames.languageCode}}</p></div>
  </div>
  <div *ngIf="displayNames == null">
    <div class="menu-tree-item menu-tree-item-4" (click)="abstractSelectComponent.open(8, null, null)"><p>Click here to select catalog</p></div>
  </div>
</div>
<div class="menu-tools">
  <div class="image-button-box"><img class="image-button" src="assets/icons/select_dark.png" alt="SELECT MENU" (click)="abstractSelectComponent.open(8, null, null)"></div>
  <div class="image-button-box"></div>
  <div class="image-button-box"><img class="image-button" src="assets/icons/add.png" alt="CREATE" (click)="editorComponent.open(0, null, null)"></div>
  <div class="image-button-box"><img class="image-button" src="assets/icons/edit_dark.png" alt="EDIT" (click)="editorComponent.open(1, null, displayNames.selectionId)"></div>
</div>
<app-dashboard-stock-select-catalog [editingSelectionComponent]="this"></app-dashboard-stock-select-catalog>
<app-dashboard-catalog-editor [editingCatalogComponent]="this"></app-dashboard-catalog-editor>
<!-- <app-update-menu [editingMenuComponent]="this"></app-update-menu>
<app-delete-menu [editingMenuComponent]="this"></app-delete-menu> -->
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
