<app-normal-popup [editorComponent]="this" title="variant">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Variant Details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let detail of object.details">
        <tr>
          <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{detail.languageCode}}-s.jpg" alt="Language illustration flag"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="detail.name"></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Options</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Mode</td>
        <td>
          <label>
            <select [(ngModel)]="object.priceMode">
              <option value="D">Product price and options with difference</option>
              <option value="S">No product price, sum in options</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Properties</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Tip</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.tip"></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
