import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../../constants/defaults';
import {IInit} from '../../../../../abstract/IInit';
import {AbstractEditorComponent} from '../../../../../abstract/AbstractEditorComponent';
import {CatalogProductPromotion} from '../../../../../../domain/stock/CatalogProductPromotion';
import {CatalogProductPromotionCreateObject} from '../../../../../../domain/stock/CatalogProductPromotionCreateObject';
import {CatalogEditComponent} from '../../../edit.component';

@Component({
  selector: 'app-dashboard-catalog-product-promotion-editor',
  templateUrl: './product-promotion-editor.component.html',
  styleUrls: ['./product-promotion-editor.component.css']
})
export class CatalogProductPromotionEditorComponent extends AbstractEditorComponent<CatalogProductPromotionCreateObject, CatalogProductPromotion> {
  @Input() editComponent: CatalogEditComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setProductPromotionEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_PRODUCT_PROMOTION;
  }

  protected getTheObject(): CatalogProductPromotion {
    return this.object.productPromotion;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }
}
