import {Component, Input} from '@angular/core';
import {SupplementsComponent} from '../../supplements.component';
import {Product} from '../../../../../domain/menu/product';
import {SupplementProductCreateObject} from '../SupplementProductCreateObject';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {SupplementProduct} from '../../../../../domain/menu/SupplementProduct';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-create-supplement-product',
  templateUrl: './menu-supplement-product-editor.component.html',
  styleUrls: ['./menu-supplement-product-editor.component.css']
})
export class MenuSupplementProductEditor extends AbstractEditorComponent<SupplementProductCreateObject, SupplementProduct> {
  @Input() supplementsComponent: SupplementsComponent;

  public categoryId: string;
  public products: Product[];

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.supplementsComponent.setSupplementProductEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_SUPPLEMENT_PRODUCT;
  }

  protected getTheObject(): SupplementProduct {
    return this.object.supplementProduct;
  }

  protected getInitableComponent(): IInit {
    return this.supplementsComponent;
  }
}
