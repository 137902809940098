<app-left-side-container [iHaveMyHttpHandler]="this">
  <ng-container *ngIf="listObject != null && listObject.emailConfigurations != null">

    <div class="dashboard-item">
      <div>
        <h4 class="left">Email configurations</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="emailConfigurationEditorComponent.open(0, listObject.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="listObject.emailConfigurations != null">
        <ng-container *ngFor="let emailConfiguration of listObject.emailConfigurations">
          <div class="bordered-item hoverable" (dblclick)="emailConfigurationEditorComponent.open(1, null, emailConfiguration.id)">
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <p><strong>{{emailConfiguration.host}}:{{emailConfiguration.port}} ({{emailConfiguration.protocol}})</strong> {{emailConfiguration.userName}}</p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img *ngIf="emailConfiguration.sequence > 0" (click)="moveUp(emailConfiguration, getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="emailConfiguration.sequence < listObject.emailConfigurations.length - 1" (click)="moveDown(emailConfiguration, getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img (click)="emailConfigurationEditorComponent.open(1, null, emailConfiguration.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>

  </ng-container>
</app-left-side-container>

<app-dashboard-stock-email-configuration-editor [emailConfigurationsComponent]="this"></app-dashboard-stock-email-configuration-editor>
