<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>QR Configuration</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">

    <ng-container *ngIf="listObject != null">
      <div class="dashboard-item">
        <div class="left">
          <h3>QR Configurations</h3>
        </div>
        <div class="right">
          <img class="image-button" src="assets/icons/add.png" (click)="qrConfigEditor.open(0, listObject.id, null)" alt="CREATE QR Configuration"/>
        </div>
      </div>

      <ng-container *ngIf="listObject.qrConfigurations != null">
        <ng-container *ngFor="let qrConfiguration of listObject.qrConfigurations">
          <div class="bordered-item selectable" (dblclick)="qrConfigEditor.open(1, null, qrConfiguration.id)">
            <div class="left">
              <strong>{{qrConfiguration.name}}</strong>
            </div>
            <div class="right">
              <div class="image-button-box"><img *ngIf="qrConfiguration.sequence > 0" (click)="moveUp(qrConfiguration, getQrConfigurationBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
              <div class="image-button-box"><img *ngIf="qrConfiguration.sequence < listObject.qrConfigurations.length - 1" (click)="moveDown(qrConfiguration, getQrConfigurationBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
              <img (click)="qrConfigEditor.open(1, null, qrConfiguration.id)" class="image-button" src="assets/icons/edit_dark.png" alt="UPDATE">
              <img (click)="qrConfigEditor.open(2, null, qrConfiguration.id)" class="image-button" src="assets/icons/delete_dark.png" alt="DELETE">
            </div>
          </div>

          <div class="bordered-item-separator-20"></div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="listObject.qrConfigurations == null || listObject.qrConfigurations.length == 0">
        <div class="bordered-item-not-selectable">
          <div class="left">
            <strong>There are no QR Configurations yet.</strong>
          </div>
        </div>
      </ng-container>
    </ng-container>

  </div>

  <div>
    <app-dashboard-menu-create-qr [qrComponent]="this"></app-dashboard-menu-create-qr>
  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
