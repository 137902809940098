import {Component, Input} from '@angular/core';
import {MenuComponent} from '../menu.component';
import {IHaveMyHttpHandler} from '../../my-http-handler/IHaveMyHttpHandler';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {MenuMultipleRevenueObject} from '../../../domain/menu/MenuMultipleRevenueObject';
import {Chart} from './Chart';
import {MenuSubComponent} from '../MenuSubComponent';
import {DateTimeUtil} from '../../DateTimeUtil/DateTimeUtil';

@Component({
  selector: 'app-dashboard-menu-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class PosStatisticsComponent extends IHaveMyHttpHandler implements MenuSubComponent {
  @Input() menuComponent: MenuComponent;

  public from: string;
  public to: string;

  public chart = new Chart();

  public multipleRevenueObject: MenuMultipleRevenueObject;

  constructor(private http: HttpClient) {
    super();
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);

    this.getRevenue();

    /* const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    this.from = new Date(year, month, 1).toISOString().slice(0, 16);
    this.to = new Date(year, month + 1, 0).toISOString().slice(0, 16);

    console.log(this.from);
    console.log(this.to); */
  }

  public getRevenue(): void {
    let from = '';
    let to = '';
    if ( this.from != null && this.from.length != 0 && this.to != null && this.to.length != 0 ) {
      from = DateTimeUtil.isoToCustom(this.from);
      to = DateTimeUtil.isoToCustom(this.to);
    }

    const headers = new HttpHeaders({
      sessionId: Defaults.getSessionId(),
      sessionToken: Defaults.getSessionToken(),
      fromTimeString: from,
      toTimeString: to
    });

    this.myHttpHandlerComponent.get<MenuMultipleRevenueObject>(this.http.get<MenuMultipleRevenueObject>(Defaults.SERVER_API_MENU_STATISTICS + 'revenue', { headers }), (result: MenuMultipleRevenueObject) => {
      this.multipleRevenueObject = result;

      this.from = DateTimeUtil.customToIso(result.from);
      this.to = DateTimeUtil.customToIso(result.to);
    });
  }

}
