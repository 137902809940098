<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Condiments</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">
    <div>

      <ng-container *ngIf="listObject != null">

        <div class="dashboard-item">
          <div>
            <h4 class="left">Condiments</h4>
            <div class="right">
              <img src="assets/icons/add.png" class="image-button right" (click)="condimentEditor.open(0, listObject.id, null)" alt="Add Icon">
            </div>
          </div>

          <ng-container *ngIf="listObject.condiments != null">
            <ng-container *ngFor="let condiment of listObject.condiments">
              <div class="bordered-item hoverable" [ngClass]="{'selected': condiment == selectedCondiment}" (click)="selectCondiment(condiment)" (dblclick)="condimentEditor.open(1, null, condiment.id)">
                <div class="dashboard-bordered-item-sub-left width-img-4buttons">
                  <p><strong><ng-container *ngIf="condiment.details != null && condiment.details.length > 0">{{condiment.details[0].name}}</ng-container></strong></p>
                </div>
                <div class="dashboard-bordered-item-sub-right">
                  <div class="dashboard-bordered-item-sub-right-top">
                    <div class="image-button-box"><img *ngIf="condiment.sequence > 0" (click)="moveUp(condiment, getObjectBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                    <div class="image-button-box"><img *ngIf="condiment.sequence < listObject.condiments.length - 1" (click)="moveDown(condiment, getObjectBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                    <div class="image-button-box"><img (click)="condimentEditor.open(1, null, condiment.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                  </div>
                  <div class="dashboard-bordered-item-sub-right-bottom">
                    <ng-container *ngIf="condiment.price != null">{{condiment.price / 100 | currency: 'EUR'}}</ng-container><ng-container *ngIf="condiment.price == null">N/A</ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

        </div>
      </ng-container>

    </div>
  </div>

  <div>
    <app-dashboard-menu-condiment-editor [condimentsComponent]="this"></app-dashboard-menu-condiment-editor>
  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
