<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Zones</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>

  <div id="half-window" class="menu-half-0-content">

    <div class="dashboard-item">
      <ng-container *ngIf="listObject != null && listObject.menuZones != null">
        <div>
          <h4 class="left">Zones</h4>
          <div class="right">
            <img class="image-button" src="assets/icons/add.png" (click)="zoneEditor.open(0, listObject.id, null)" alt="CREATE ZONE"/>
          </div>
        </div>

        <ng-container *ngFor="let zone of listObject.menuZones">
          <div class="bordered-item hoverable" [ngClass]="{'selected': zone == selectedZone}" (click)="selectZone(zone)" (dblclick)="zoneEditor.open(1, null, zone.id)">
            <div class="left">
              <strong><ng-container *ngIf="zone.details != null && zone.details.length > 0">{{zone.details[0].name}}</ng-container></strong>
            </div>
            <div class="right">
              <div class="image-button-box"><img *ngIf="zone.sequence > 0" (click)="moveUp(zone, getZoneBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
              <div class="image-button-box"><img *ngIf="zone.sequence < listObject.menuZones.length - 1" (click)="moveDown(zone, getZoneBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
              <img (click)="zoneEditor.open(1, null, zone.id)" class="image-button" src="assets/icons/edit_dark.png" alt="UPDATE">
            </div>
          </div>

        </ng-container>

      </ng-container>
    </div>

  </div>

  <div id="half-window-right" class="menu-half-1-content">

    <div class="dashboard-item">
      <ng-container *ngIf="selectedZone != null">
        <div>
          <h4 class="left">Tables</h4>
          <div class="right">
            <img class="image-button" src="assets/icons/add.png" (click)="tableEditor.open(0, selectedZone.id, null)" alt="CREATE ZONE"/>
          </div>
        </div>

        <ng-container *ngIf="selectedZone.tables != null">
          <ng-container *ngFor="let table of selectedZone.tables">
            <div class="bordered-item hoverable" (dblclick)="tableEditor.open(1, null, table.id)">
              <div class="left">
                <strong>{{table.details[0].name}}</strong>
              </div>
              <div class="right">
                <div class="image-button-box"><img *ngIf="table.sequence > 0" (click)="moveUp(table, getTableBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="table.sequence < selectedZone.tables.length - 1" (click)="moveDown(table, getTableBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <img class="image-button" src="assets/icons/edit_dark.png" (click)="tableEditor.open(1, null, table.id)">
              </div>
            </div>

          </ng-container>
        </ng-container>

      </ng-container>
    </div>
  </div>

  <div>
    <app-dashboard-menu-create-zone [zonesComponent]="this"></app-dashboard-menu-create-zone>
    <app-dashboard-menu-create-table [zonesComponent]="this"></app-dashboard-menu-create-table>
  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
