import {Component, Input} from '@angular/core';
import {MenuComponent} from '../menu.component';
import {HttpClient} from '@angular/common/http';
import {HttpHandler} from '../../../handlers/httpHandler';
import {Defaults} from '../../../constants/defaults';
import {MenuDelivery} from '../../../domain/menu/MenuDelivery';
import {MenuDeliveryEditor} from './create-delivery/menu-delivery-editor.component';
import {Shop} from '../../../domain/shop/shop';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {MenuSubComponent} from '../MenuSubComponent';
import {Menu} from '../../../domain/menu/menu';

@Component({
  selector: 'app-dashboard-menu-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent extends SortableObjectPanel<Menu, MenuDelivery> implements MenuSubComponent {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  public deliveryEditor: MenuDeliveryEditor;

  public shop: Shop;

  public selectedDelivery: MenuDelivery;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);
    this.getVariants();
  }

  private getVariants(): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<Shop>(this.http.get<Shop>(Defaults.SERVER_API_MENU_DELIVERY + 'all', { headers }), (result: Shop) => {
      this.shop = result;
    });
  }

  public setMenuDeliveryEditor(deliveryEditor: MenuDeliveryEditor): void {
    this.deliveryEditor = deliveryEditor;
  }

  public selectTownship(delivery: MenuDelivery): void {
    this.selectedDelivery = delivery;
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_DELIVERY;
  }


}
