<app-normal-popup [editorComponent]="this" title="product -> extra">
  <ng-container *ngIf="object != null && object.condiments != null && object.variants != null">
    <table>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Extra</th>
        <td></td>
      </tr>

      <tr>
        <td style="width: 64px"><img class="image-icon" src="assets/icons/condiment_light.png" alt="CONDIMENTS"></td>
        <td>Condiment</td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.productExtra.condimentId">
            <option *ngFor="let condiment of object.condiments" ngValue="{{condiment.id}}">{{condiment.details[0].name}} ({{condiment.price / 100 | currency: 'EUR'}})</option>
          </select>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Condition</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/variants.png"></td>
        <td>Enabled by condition</td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.productExtra.conditionEnabled">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

      <tr>
        <td align="right"></td>
        <td>Condition</td>
        <td><input type="text" [disabled]="mode === 2" [(ngModel)]="object.productExtra.enablingCondition"></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
