import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { MenuComponent } from './dashboard/menu/menu.component';
import { ShopsComponent } from './dashboard/shops/shops.component';
import { ScreenComponent } from './dashboard/screen/screen.component';
import { StockComponent } from './dashboard/stock/stock.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { LoginComponent } from './dashboard/user/login/login.component';
import { RegisterComponent } from './dashboard/user/register/register.component';
import {MymoneyComponent} from './dashboard/mymoney/mymoney.component';
import {GalleryComponent} from './dashboard/gallery/gallery.component';


const routes: Routes = [
  {path: '', redirectTo: 'profile', pathMatch: 'full'},

  {path: 'profile', component: ProfileComponent},

  {path: 'user/login', component: LoginComponent},
  {path: 'user/register', component: RegisterComponent},

  {path: 'shop/:component', component: ShopsComponent},

  {path: 'menu/:component/:parameter', component: MenuComponent},

  {path: 'stock/:component/:parameter', component: StockComponent},

  {path: 'screen', component: ScreenComponent},

  {path: 'gallery/:component/:parameter', component: GalleryComponent},

  {path: 'mymoney', component: MymoneyComponent},
  {path: 'mymoney/workgroup/:workgroupId', component: MymoneyComponent},
  {path: 'mymoney/shop/:shopId', component: MymoneyComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
