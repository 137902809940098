import {Component, Input} from '@angular/core';
import {EditComponent} from '../edit.component';
import {Category} from '../../../../domain/menu/category';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-category-editor',
  templateUrl: './menu-category-editor.component.html',
  styleUrls: ['./menu-category-editor.component.css']
})
export class MenuCategoryEditorComponent extends AbstractEditorComponent<Category, Category> {
  @Input() editComponent: EditComponent;
  @Input() menuCategoryId: number;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setCategoryEditorComponent(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_CATEGORY;
  }

  protected getTheObject(): Category {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }
}
