<app-normal-popup [editorComponent]="this" title="menu">
  <table>
    <ng-container *ngIf="object != null">
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Gallery details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0px 0px 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
      </ng-container>

    </ng-container>

  </table>
</app-normal-popup>
<app-dashboard-select-shop1 [iHaveSelectShopComponent]="this"></app-dashboard-select-shop1>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
