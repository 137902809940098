import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import * as Sha512 from 'js-sha512';
import { Category } from '../../../domain/menu/category';
import { Defaults } from '../../../constants/defaults';
import {Product} from '../../../domain/menu/product';
import {User} from '../../../domain/user/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public email = '';
  public password = '';

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void {

  }

  public login(): void {
    const email = this.email;
    const password = this.password;

    let headers = new HttpHeaders({
      email: email
    });

    this.http.get<SaltData>(Defaults.SERVER_API + 'user/salt', { headers })
      .subscribe(
        (result: SaltData) => {
          const saltedPassword = Sha512.sha512(password + result.salt);
          const challengedPassword = Sha512.sha512(saltedPassword + result.challenge);

          headers = new HttpHeaders({
            session: result.sessionId
          });

          const params = new HttpParams()
            .append('hash', challengedPassword);

          this.http.post<UserSessionData>(Defaults.SERVER_API + 'user/login', params, { headers }).subscribe(
            (userSessionData: UserSessionData) => {
              Defaults.setSessionId(userSessionData.sessionId);
              Defaults.setSessionToken(userSessionData.sessionToken);
              localStorage.setItem('firstName', userSessionData.user.firstName);
              localStorage.setItem('lastName', userSessionData.user.lastName);

              this.router.navigateByUrl('/profile');
            },
            error => {},
            () => {});
        },
        error => {
        },
        () => {
        });
  }
}

class SaltData {
  public sessionId: string;
  public salt: string;
  public challenge: string;
}

class UserSessionData {
  public sessionId: string;
  public sessionToken: string;
  public start: string;
  public duration: number;

  public user: User;
}
