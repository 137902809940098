<app-normal-popup [editorComponent]="this" title="email configuration">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Configuration</th>
        <td></td>
      </tr>

      <tr>
        <td rowspan="2" valign="top" style="width: 64px"></td>
        <td>Host</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.host"></td>
      </tr>
      <tr>
        <td>Port</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.port"></td>
      </tr>
      <tr>
        <td></td>
        <td>Username</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.userName"></td>
      </tr>
      <tr>
        <td></td>
        <td>Password</td>
        <td><input type="password" autocomplete="none" [(ngModel)]="object.password"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Email</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Sender email</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.senderEmail"></td>
      </tr>
      <tr>
        <td></td>
        <td>Test email</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.testEmail"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Complicated options</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Protocol</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.protocol"></td>
      </tr>
      <tr>
        <td></td>
        <td>Charset</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.charset"></td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Auth</td>
        <td>
          <label>
            <select [(ngModel)]="object.useAuth">
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </label>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Ssl</td>
        <td>
          <label>
            <select [(ngModel)]="object.useSsl">
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </label>
        </td>
      </tr>
    </table>
  </ng-container>
</app-normal-popup>
