<app-normal-popup [editorComponent]="this" title="category">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Category status</th>
        <td></td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Status</td>
        <td>
          <label>
            <select [(ngModel)]="object.active">
              <option value="A">Active</option>
              <option value="I">Inactive</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Category details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td rowspan="2" valign="top" style="width: 64px"><img width="40" style="margin: 8px 0px 0px 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
        <tr>
          <td valign="top" aria-autocomplete="none">Description</td>
          <td><textarea rows="4" style="margin-bottom: 4px; resize: vertical" [(ngModel)]="item.description"></textarea></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Category layout QR</th>
        <td></td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/header.png"></td>
        <td>Show Header image</td>
        <td>
          <select [(ngModel)]="object.showHeaderImageQR">
            <optgroup label="NO">
              <option value="0">NO</option>
            </optgroup>
            <optgroup label="Yes">
              <option value="3">3/1</option>
              <option value="6">16/9</option>
            </optgroup>
          </select>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/3_rows.png"></td>
        <td>Column count</td>
        <td>
          <select [(ngModel)]="object.columnCountQR">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/product_images.png"></td>
        <td>Show product images</td>
        <td>
          <select [(ngModel)]="object.showProductImagesQR">
            <optgroup label="NO">
              <option value="0">NO</option>
            </optgroup>
            <optgroup label="Yes">
              <option value="1">1/1</option>
              <option value="4">4/3</option>
            </optgroup>
          </select>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Category Layout Tablet</th>
        <td></td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/header.png"></td>
        <td>Show header image</td>
        <td>
          <select [(ngModel)]="object.showHeaderImageTablet">
            <optgroup label="NO">
              <option value="0">NO</option>
            </optgroup>
            <optgroup label="Yes">
              <option value="3">3/1</option>
              <option value="6">16/9</option>
            </optgroup>
          </select>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/3_rows.png"></td>
        <td>Column count</td>
        <td>
          <select [(ngModel)]="object.columnCountTablet">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/product_images.png"></td>
        <td>Show product images</td>
        <td>
          <select [(ngModel)]="object.showProductImagesTablet">
            <optgroup label="NO">
              <option value="0">NO</option>
            </optgroup>
            <optgroup label="Yes">
              <option value="1">1/1</option>
              <option value="4">4/3</option>
            </optgroup>
          </select>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Category options</th>
        <td></td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/menu.png"></td>
        <td>Show in menu</td>
        <td>
          <select [(ngModel)]="object.inMenu">
            <option value="E">Everywhere</option>
            <option value="S">Only in supplements</option>
          </select>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/pos.png"></td>
        <td>Show in pos</td>
        <td>
          <select [(ngModel)]="object.inPos">
            <option value="E">Everywhere</option>
            <option value="S">Only in supplements</option>
          </select>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/web.png"></td>
        <td>Show on site</td>
        <td>
          <select [(ngModel)]="object.inApi">
            <option value="E">Everywhere</option>
            <option value="S">Only in supplements</option>
          </select>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/delivery.png"></td>
        <td>Show for delivery</td>
        <td>
          <select [(ngModel)]="object.inDelivery">
            <option value="D">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>
    </table>
  </ng-container>
</app-normal-popup>
