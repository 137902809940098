import {Component, Input} from '@angular/core';
import {CatalogNews} from '../../../domain/stock/CatalogNews';
import {HttpClient} from '@angular/common/http';
import {StockComponent} from '../stock.component';
import {Defaults} from '../../../constants/defaults';
import {Catalog} from '../../../domain/stock/Catalog';
import {CatalogNewsEditorComponent} from './news-editor/catalog-news-editor.component';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {CatalogNewsVariantOptionEditorComponent} from './news-variant-option-editor/catalog-news-variant-option-editor.component';
import {CatalogNewsPropertyEditorComponent} from './news-property-editor/catalog-news-property-editor.component';
import {CatalogNewsProductEditorComponent} from './news-product-editor/catalog-news-product-editor.component';
import {CatalogNewsVariantEditorComponent} from './news-variant-editor/catalog-news-variant-editor.component';
import {CatalogNewsImageEditorComponent} from './news-image-editor/catalog-news-image-editor.component';
import {NewsChannelComponent} from './news-channel/news-channel.component';

@Component({
  selector: 'app-dashboard-catalog-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent extends SortableObjectPanel<Catalog, CatalogNews>{
  @Input() stockComponent: StockComponent;

  public imageRoot = Defaults.SERVER_STOCK_CONTENT;

  public newsEditorComponent: CatalogNewsEditorComponent;
  private newsChannelComponent: NewsChannelComponent;

  public newsPropertyEditorComponent: CatalogNewsPropertyEditorComponent;
  public newsImageEditorComponent: CatalogNewsImageEditorComponent;
  public newsProductEditorComponent: CatalogNewsProductEditorComponent;
  public newsVariantEditorComponent: CatalogNewsVariantEditorComponent;
  public newsVariantOptionEditorComponent: CatalogNewsVariantOptionEditorComponent;

  public selectedNews: CatalogNews;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.stockComponent.setSubComponent(this);

    super.init();
  }

  protected getListDone() {
    super.getListDone();

    if ( this.selectedNews != null ) {
      for ( const news of this.listObject.news ) {
        if ( news.id === this.selectedNews.id ) {
          this.selectNews(news);
        }
      }
    }
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_NEWS;
  }

  public setNewsEditorComponent(newsEditorComponent: CatalogNewsEditorComponent): void {
    this.newsEditorComponent = newsEditorComponent;
  }

  public setNewsChannelComponent(newsChannelComponent: NewsChannelComponent): void {
    this.newsChannelComponent = newsChannelComponent;
  }

  public setNewsPropertyEditorComponent(newsPropertyEditorComponent: CatalogNewsPropertyEditorComponent): void {
    this.newsPropertyEditorComponent = newsPropertyEditorComponent;
  }

  public setNewsImageEditorComponent(newsImageEditorComponent: CatalogNewsImageEditorComponent): void {
    this.newsImageEditorComponent = newsImageEditorComponent;
  }

  public setNewsProductEditorComponent(newsProductEditorComponent: CatalogNewsProductEditorComponent): void {
    this.newsProductEditorComponent = newsProductEditorComponent;
  }

  public setNewsVariantEditorComponent(newsVariantEditorComponent: CatalogNewsVariantEditorComponent): void {
    this.newsVariantEditorComponent = newsVariantEditorComponent;
  }

  public setNewsVariantOptionEditorComponent(newsVariantOptionEditorComponent: CatalogNewsVariantOptionEditorComponent): void {
    this.newsVariantOptionEditorComponent = newsVariantOptionEditorComponent;
  }

  public selectNews(news: CatalogNews): void {
    this.selectedNews = news;
  }

  public notifyClients(news: CatalogNews): void {
    // TODO simulation
    this.newsEditorComponent.openForChannel(news, (newsChannel) => {
      this.newsChannelComponent.openToSend(newsChannel);
    });
  }
}


