<app-left-side-container [iHaveMyHttpHandler]="this">
  <ng-container *ngIf="object != null">

    <div class="dashboard-item">
      <div>
        <h4 class="left">Menu closures</h4>
        <div class="right">
          <img src="assets/icons/edit_dark.png" class="image-button right" (click)="menuClosureEditor.open(1, null, object.id)" alt="Edit Icon">
        </div>
      </div>
    </div>

    <div>
      <table>
        <tbody>
        <tr>
          <th style="padding-top: 25px; padding-bottom: 15px" colspan="2">Monday</th>
        </tr>
        <tr>
          <td>Closure board</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnMonday">{{object.menuClosureMonday}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnMonday">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Allow orders</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnMondayOrders">{{object.menuClosureMondayOrders}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnMondayOrders">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Local</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnMondayLocal">{{object.menuClosureMondayLocal}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnMondayLocal">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Pickup</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnMondayPickup">{{object.menuClosureMondayPickup}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnMondayPickup">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Delivery</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnMondayDelivery">{{object.menuClosureMondayDelivery}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnMondayDelivery">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Revenue</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnMondayRevenue">{{object.menuClosureMondayRevenue}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnMondayRevenue">Closed</ng-container></td>
        </tr>
        </tbody>
        <tbody>
        <tr>
          <th style="padding-top: 25px; padding-bottom: 15px" colspan="2">Tuesday</th>
        </tr>
        <tr>
          <td>Closure board</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnTuesday">{{object.menuClosureTuesday}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnTuesday">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Allow orders</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnTuesdayOrders">{{object.menuClosureTuesdayOrders}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnTuesdayOrders">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Local</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnTuesdayLocal">{{object.menuClosureTuesdayLocal}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnTuesdayLocal">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Pickup</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnTuesdayPickup">{{object.menuClosureTuesdayPickup}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnTuesdayPickup">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Delivery</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnTuesdayDelivery">{{object.menuClosureTuesdayDelivery}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnTuesdayDelivery">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Revenue</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnTuesdayRevenue">{{object.menuClosureTuesdayRevenue}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnTuesdayRevenue">Closed</ng-container></td>
        </tr>
        </tbody>
        <tbody>
        <tr>
          <th style="padding-top: 25px; padding-bottom: 15px" colspan="2">Wednesday</th>
        </tr>
        <tr>
          <td>Closure board</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnWednesday">{{object.menuClosureWednesday}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnWednesday">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Allow orders</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnWednesdayOrders">{{object.menuClosureWednesdayOrders}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnWednesdayOrders">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Local</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnWednesdayLocal">{{object.menuClosureWednesdayLocal}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnWednesdayLocal">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Pickup</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnWednesdayPickup">{{object.menuClosureWednesdayPickup}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnWednesdayPickup">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Delivery</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnWednesdayDelivery">{{object.menuClosureWednesdayDelivery}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnWednesdayDelivery">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Revenue</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnWednesdayRevenue">{{object.menuClosureWednesdayRevenue}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnWednesdayRevenue">Closed</ng-container></td>
        </tr>
        </tbody>
        <tbody>
        <tr>
          <th style="padding-top: 25px; padding-bottom: 15px" colspan="2">Thursday</th>
        </tr>
        <tr>
          <td>Closure board</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnThursday">{{object.menuClosureThursday}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnThursday">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Allow orders</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnThursdayOrders">{{object.menuClosureThursdayOrders}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnThursdayOrders">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Local</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnThursdayLocal">{{object.menuClosureThursdayLocal}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnThursdayLocal">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Pickup</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnThursdayPickup">{{object.menuClosureThursdayPickup}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnThursdayPickup">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Delivery</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnThursdayDelivery">{{object.menuClosureThursdayDelivery}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnThursdayDelivery">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Revenue</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnThursdayRevenue">{{object.menuClosureThursdayRevenue}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnThursdayRevenue">Closed</ng-container></td>
        </tr>
        </tbody>
        <tbody>
        <tr>
          <th style="padding-top: 25px; padding-bottom: 15px" colspan="2">Friday</th>
        </tr>
        <tr>
          <td>Closure board</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnFriday">{{object.menuClosureFriday}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnFriday">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Allow orders</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnFridayOrders">{{object.menuClosureFridayOrders}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnFridayOrders">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Local</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnFridayLocal">{{object.menuClosureFridayLocal}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnFridayLocal">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Pickup</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnFridayPickup">{{object.menuClosureFridayPickup}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnFridayPickup">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Delivery</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnFridayDelivery">{{object.menuClosureFridayDelivery}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnFridayDelivery">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Revenue</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnFridayRevenue">{{object.menuClosureFridayRevenue}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnFridayRevenue">Closed</ng-container></td>
        </tr>
        </tbody>
        <tbody>
        <tr>
          <th style="padding-top: 25px; padding-bottom: 15px" colspan="2">Saturday</th>
        </tr>
        <tr>
          <td>Closure board</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSaturday">{{object.menuClosureSaturday}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSaturday">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Allow orders</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSaturdayOrders">{{object.menuClosureSaturdayOrders}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSaturdayOrders">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Local</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSaturdayLocal">{{object.menuClosureSaturdayLocal}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSaturdayLocal">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Pickup</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSaturdayPickup">{{object.menuClosureSaturdayPickup}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSaturdayPickup">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Delivery</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSaturdayDelivery">{{object.menuClosureSaturdayDelivery}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSaturdayDelivery">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Revenue</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSaturdayRevenue">{{object.menuClosureSaturdayRevenue}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSaturdayRevenue">Closed</ng-container></td>
        </tr>
        </tbody>
        <tbody>
        <tr>
          <th style="padding-top: 25px; padding-bottom: 15px" colspan="2">Sunday</th>
        </tr>
        <tr>
          <td>Closure board</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSunday">{{object.menuClosureSunday}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSunday">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Allow orders</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSundayOrders">{{object.menuClosureSundayOrders}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSundayOrders">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Local</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSundayLocal">{{object.menuClosureSundayLocal}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSundayLocal">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Pickup</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSundayPickup">{{object.menuClosureSundayPickup}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSundayPickup">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Delivery</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSundayDelivery">{{object.menuClosureSundayDelivery}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSundayDelivery">Closed</ng-container></td>
        </tr>
        <tr>
          <td>Revenue</td>
          <td align="right"><ng-container *ngIf="object.menuClosureOpenOnSundayRevenue">{{object.menuClosureSundayRevenue}}</ng-container><ng-container *ngIf="!object.menuClosureOpenOnSundayRevenue">Closed</ng-container></td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</app-left-side-container>

<app-dashboard-shop-menu-closure-editor [menuClosureComponent]="this"></app-dashboard-shop-menu-closure-editor>
