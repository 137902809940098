import {SortableObjectPanel} from './SortableObjectPanel';
import {DoubleSortableObject} from './DoubleSortableObject';

export abstract class DoubleSortableObjectPanel<ListT, T extends DoubleSortableObject> extends SortableObjectPanel<ListT, T> {

  public moveUpSecondSequence(sortableObject: T, objectBaseEndpoint: string): void {
    this.move(sortableObject, objectBaseEndpoint, 'moveUpSecondSequence/');
  }

  public moveDownSecondSequence(sortableObject: T, objectBaseEndpoint: string): void {
    this.move(sortableObject, objectBaseEndpoint, 'moveDownSecondSequence/');
  }
}
