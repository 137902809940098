import {Component, Input} from '@angular/core';
import {AbstractSelectComponent} from '../../../menu/editing-menu/popups/select-menu/AbstractSelectComponent';
import {AbstractEditingSelectionComponent} from '../../../menu/editing-menu/AbstractEditingSelectionComponent';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {IInit} from '../../../abstract/IInit';
import {Gallery} from '../../../../domain/gallery/Gallery';
import {GalleryDetails} from '../../../../domain/gallery/GalleryDetails';

@Component({
  selector: 'app-dashboard-gallery-select-gallery',
  templateUrl: './select-gallery.component.html',
  styleUrls: ['./select-gallery.component.css']
})
export class SelectGalleryComponent extends AbstractSelectComponent<Gallery, Gallery> {
  @Input() public editingSelectionComponent: AbstractEditingSelectionComponent<Gallery, Gallery>;

  constructor(http: HttpClient) {
    super(http);
  }

  protected getAbstractEditingSelectionComponent(): AbstractEditingSelectionComponent<Gallery, Gallery> {
    return this.editingSelectionComponent;
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_GALLERY_SELECTION;
  }

  protected getSelectedShopId(): string {
    return this.object.user.selectedGalleryShopId;
  }

  protected getSelectionId(): string {
    return this.object.user.selectedGalleryId;
  }

  protected getSelectedLanguageCode(): string {
    return this.object.user.selectedGalleryLanguageCode;
  }

  public init(): void {
    this.editingSelectionComponent.setSelectComponent(this);
  }

  public getDetail(details: GalleryDetails[], languageCode: String): GalleryDetails {
    for ( const detail of details ) {
      if ( detail.languageCode === languageCode ) {
        return detail;
      }
    }

    return details[0];
  }

  protected getInitableComponent(): IInit {
    return null;
  }

  protected getTheObject(): Gallery {
    return null;
  }

}
