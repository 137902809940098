import {Component, Input} from '@angular/core';
import {VariantsComponent} from '../../variants.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {MenuVariantOption} from '../../../../../domain/menu/MenuVariantOption';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-variant-option-editor',
  templateUrl: './variant-option-editor.component.html',
  styleUrls: ['./variant-option-editor.component.css']
})
export class VariantOptionEditorComponent extends AbstractEditorComponent<MenuVariantOption, MenuVariantOption> {
  @Input() variantsComponent: VariantsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  init(): void {
    this.variantsComponent.setCreateVariantOptionEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_VARIANT_OPTION;
  }

  protected getTheObject(): MenuVariantOption {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.variantsComponent;
  }
}
