export class Defaults {
  static SERVER = 'https://api.bestelmandje.be';
  // static SERVER = 'http://localhost:8443';

  static SERVER_CONTENT = 'https://api.bestelmandje.be/';
  static SERVER_MENU_CONTENT = "https://api.bestelmandje.be/";
  static SERVER_STOCK_CONTENT = 'https://image-stock.bestelmandje.be/';
  // static SERVER_STOCK_CONTENT = 'http://localhost:8444/';
  static SERVER_GALLERY_CONTENT = 'https://image-gallery.bestelmandje.be/';

  static SERVER_API = Defaults.SERVER + '/';

  static SERVER_API_USER = Defaults.SERVER_API + 'user/';
  static SERVER_API_USER_SHOP = Defaults.SERVER_API + 'userShop/';

  static SERVER_API_SHOP = Defaults.SERVER_API + 'shop/';
  static SERVER_API_SHOP_LANGUAGE = Defaults.SERVER_API_SHOP + 'language/';
  static SERVER_API_SHOP_SELECTION = Defaults.SERVER_API_SHOP + 'selection/';

  static SERVER_API_MENU = Defaults.SERVER_API + 'menu/';
  static SERVER_API_MENU_SELECTION = Defaults.SERVER_API_MENU + 'selection/';
  static SERVER_API_MENU_STATISTICS = Defaults.SERVER_API_MENU + 'statistics/';
  static SERVER_API_MENU_CATEGORY = Defaults.SERVER_API_MENU + 'category/';
  static SERVER_API_MENU_PRODUCT = Defaults.SERVER_API_MENU + 'product/';
  static SERVER_API_MENU_PRODUCT_SUPPLEMENT = Defaults.SERVER_API_MENU_PRODUCT + 'supplement/';
  static SERVER_API_MENU_PRODUCT_VARIANT = Defaults.SERVER_API_MENU_PRODUCT + 'variant/';
  static SERVER_API_MENU_PRODUCT_CONDIMENT = Defaults.SERVER_API_MENU_PRODUCT + 'condiment/';
  static SERVER_API_MENU_PRODUCT_EXTRA = Defaults.SERVER_API_MENU_PRODUCT + 'extra/';
  static SERVER_API_MENU_PRODUCT_BULK = Defaults.SERVER_API_MENU_PRODUCT + 'bulk/';
  static SERVER_API_MENU_PRODUCT_PROMOTION = Defaults.SERVER_API_MENU_PRODUCT + 'promotion/'
  static SERVER_API_MENU_VARIANT = Defaults.SERVER_API_MENU + 'variant/';
  static SERVER_API_MENU_VARIANT_OPTION = Defaults.SERVER_API_MENU_VARIANT + 'option/';
  static SERVER_API_MENU_SUPPLEMENT = Defaults.SERVER_API_MENU + 'supplement/';
  static SERVER_API_MENU_SUPPLEMENT_PRODUCT = Defaults.SERVER_API_MENU_SUPPLEMENT + 'product/';
  static SERVER_API_MENU_CONDIMENT = Defaults.SERVER_API_MENU + 'condiment/';
  static SERVER_API_MENU_PROMOTION = Defaults.SERVER_API_MENU + 'promotion/';
  static SERVER_API_MENU_PROMOTION_LIST_ITEM = Defaults.SERVER_API_MENU_PROMOTION + 'listItem/';
  static SERVER_API_MENU_DISCOUNT_COUPON = Defaults.SERVER_API_MENU + 'discountCoupon/';
  static SERVER_API_MENU_DELIVERY = Defaults.SERVER_API_MENU + 'delivery/';
  static SERVER_API_MENU_QR_CONFIGURATION = Defaults.SERVER_API_MENU + 'qrConfiguration/';
  static SERVER_API_MENU_ZONE = Defaults.SERVER_API_MENU + 'zone/';
  static SERVER_API_MENU_TABLE = Defaults.SERVER_API_MENU + 'table/';

  static SERVER_API_CATALOG = Defaults.SERVER_API + 'catalog/';
  static SERVER_API_CATALOG_SELECTION = Defaults.SERVER_API_CATALOG + 'selection/';
  static CONTENT_SERVER_API_CATALOG = Defaults.SERVER_CONTENT + 'catalog/';
  static SERVER_API_CATALOG_IMAGE = Defaults.SERVER_API_CATALOG + 'image/';
  static SERVER_API_CATALOG_CATEGORY = Defaults.SERVER_API_CATALOG + 'category/';
  static CONTENT_SERVER_API_CATALOG_CATEGORY = Defaults.CONTENT_SERVER_API_CATALOG + 'category/';
  static SERVER_API_CATALOG_PRODUCT = Defaults.SERVER_API_CATALOG + 'product/';
  static CONTENT_SERVER_API_CATALOG_PRODUCT = Defaults.CONTENT_SERVER_API_CATALOG + 'product/';
  static SERVER_API_CATALOG_PRODUCT_VARIANT = Defaults.SERVER_API_CATALOG_PRODUCT + 'variant/';
  static SERVER_API_CATALOG_PRODUCT_PROMOTION = Defaults.SERVER_API_CATALOG_PRODUCT + 'promotion/';
  static SERVER_API_CATALOG_PRODUCT_IMAGE = Defaults.SERVER_API_CATALOG_PRODUCT + 'image/';
  static SERVER_API_CATALOG_VARIANT = Defaults.SERVER_API_CATALOG + 'variant/';
  static SERVER_API_CATALOG_VARIANT_OPTION = Defaults.SERVER_API_CATALOG_VARIANT + 'option/';
  static SERVER_API_CATALOG_NEWS = Defaults.SERVER_API_CATALOG + 'news/';
  static SERVER_API_CATALOG_NEWS_PROPERTY = Defaults.SERVER_API_CATALOG_NEWS + 'property/';
  static SERVER_API_CATALOG_NEWS_IMAGE = Defaults.SERVER_API_CATALOG_NEWS + 'image/';
  static SERVER_API_CATALOG_NEWS_PRODUCT = Defaults.SERVER_API_CATALOG_NEWS + 'product/';
  static SERVER_API_CATALOG_NEWS_VARIANT = Defaults.SERVER_API_CATALOG_NEWS + 'variant/';
  static SERVER_API_CATALOG_NEWS_VARIANT_OPTION = Defaults.SERVER_API_CATALOG_NEWS_VARIANT + 'option/';
  static SERVER_API_CATALOG_NEWS_PROMOTION = Defaults.SERVER_API_CATALOG_NEWS + 'promotion/';
  static SERVER_API_CATALOG_EMAIL_CONFIGURATION = Defaults.SERVER_API_CATALOG + 'email/configuration/';
  static SERVER_API_CATALOG_EMAIL_TEMPLATE = Defaults.SERVER_API_CATALOG + 'email/template/';
  static SERVER_API_CATALOG_EMAIL_SUBSCRIPTION = Defaults.SERVER_API_CATALOG_NEWS + 'email/subscription/';
  static SERVER_API_CATALOG_PROMOTION = Defaults.SERVER_API_CATALOG + 'promotion/';

  static SERVER_API_POS = Defaults.SERVER_API + 'pos/';
  static SERVER_API_POS_TABLET = Defaults.SERVER_API_POS + 'tablet/';
  static SERVER_API_POS_TABLET_PRINTER = Defaults.SERVER_API_POS_TABLET + 'printer/';
  static SERVER_API_POS_PRINT_PRINTER = Defaults.SERVER_API_POS + 'print/printer/';
  static SERVER_API_POS_PRINT_ZONE = Defaults.SERVER_API_POS + 'print/zone/';

  static SERVER_API_GALLERY = Defaults.SERVER_API + 'gallery/';
  static SERVER_API_GALLERY_SELECTION = Defaults.SERVER_API_GALLERY + 'selection/';
  static SERVER_API_GALLERY_ALBUM = Defaults.SERVER_API_GALLERY + 'album/';
  static SERVER_API_GALLERY_IMAGE = Defaults.SERVER_API_GALLERY + 'image/';

  static SESSION_ID = 'MENU_DASHBOARD_63521487_SESSION_ID';
  static SESSION_TOKEN = 'MENU_DASHBOARD_89525427_SESSION_TOKEN';

  public static setSessionId(sessionId: string): void {
    localStorage.setItem(Defaults.SESSION_ID, sessionId);
  }

  public static setSessionToken(sessionToken: string): void {
    localStorage.setItem(Defaults.SESSION_TOKEN, sessionToken);
  }

  public static getSessionId(): string {
    return localStorage.getItem(Defaults.SESSION_ID);
  }

  public static getSessionToken(): string {
    return localStorage.getItem(Defaults.SESSION_TOKEN);
  }
}
