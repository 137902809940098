import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MenuSubComponent} from '../menu/MenuSubComponent';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.css']
})
export class ShopsComponent implements OnInit {
  private componentNames: Array<string> = ['general', 'menu-closures', 'languages', 'employee', 'edit', 'variants', 'empty'];
  public component: string;

  private subComponent: MenuSubComponent;

  public shopId: string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.setComponents();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setComponents();
      }
    });
  }

  private setComponents(): void {
    this.component = this.route.snapshot.paramMap.get('component');

    if (this.componentNames.indexOf(this.component) === -1) {
      this.component = this.componentNames[this.componentNames.length - 1];
    }
  }

  public getComponent(): string {
    return this.component;
  }

  public setSubComponent(subComponent: MenuSubComponent) {
    this.subComponent = subComponent;
  }

  public selectionChanged(): void {
    if ( this.subComponent != null ) {
      this.subComponent.init();
    }
  }

  public openComponent(component: string): void {
    this.component = component;

    this.navigate();
  }

  private navigate(): void {
    let url = '/shop/';

    url += this.component;

    this.router.navigateByUrl(url);
  }
}
