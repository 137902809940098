import {IHaveMyHttpHandler} from '../../../../my-http-handler/IHaveMyHttpHandler';
import {HttpHandler} from '../../../../../handlers/httpHandler';
import {EditingSelectionCreateObject} from '../../EditingSelectionCreateObject';
import {HttpClient} from '@angular/common/http';
import {AbstractEditingSelectionComponent} from '../../AbstractEditingSelectionComponent';
import {User} from '../../../../../domain/user/user';
import {AbstractObject} from '../../../../../domain/common/AbstractObject';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';

export abstract class AbstractSelectComponent<CO, C extends AbstractObject> extends AbstractEditorComponent<EditingSelectionCreateObject, C> {
  public isOpen = false;

  protected constructor(http: HttpClient) {
    super(http);
  }

  public submit(): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<User>(this.http.get<User>(this.getApiRootEndpoint() + 'makeSelection/' + this.getSelectedShopId() + '/' + this.getSelectionId() + '/' + this.getSelectedLanguageCode(), {headers}), (result: User) => {
      this.getAbstractEditingSelectionComponent().selectionChanged();
      this.close();
    });
  }

  protected abstract getAbstractEditingSelectionComponent(): AbstractEditingSelectionComponent<CO, C>

  protected abstract getSelectedShopId(): string;

  protected abstract getSelectionId(): string;

  protected abstract getSelectedLanguageCode(): string;

}
