<app-normal-popup [editorComponent]="this" title="qr configuration">
  <ng-container *ngIf="object != null && object.qrConfiguration">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Zone details</th>
        <td></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td>Name</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.qrConfiguration.name"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Options</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Language</td>
        <td><select [(ngModel)]="object.qrConfiguration.languageSelection">
          <option value="USER_SELECT">User Select</option>
          <ng-container *ngIf="object != null && object.shopLanguages != null">
            <option *ngFor="let item of object.shopLanguages" [ngValue]="item.languageCode">{{item.language.name}}</option>
          </ng-container>
        </select></td>
      </tr>

      <tr>
        <td></td>
        <td>Menu</td>
        <td><select [(ngModel)]="object.qrConfiguration.menuSelection">
          <option value="USER_SELECT">User Select</option>
          <ng-container *ngIf="object != null && object.menus != null">
            <option *ngFor="let item of object.menus" [ngValue]="item.id">{{getMenuDetail(item.details, object.qrConfiguration.languageSelection).name}}</option>
          </ng-container>
        </select></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
