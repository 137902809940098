import {Component, Input} from '@angular/core';
import {MenuComponent} from '../menu.component';
import {Supplement} from '../../../domain/menu/supplement';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {MenuSupplementEditor} from './create-supplement/menu-supplement-editor.component';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {Menu} from '../../../domain/menu/menu';
import {MenuSupplementProductEditor} from './popups/create-supplement-product/menu-supplement-product-editor.component';
import {MenuSubComponent} from '../MenuSubComponent';

@Component({
  selector: 'app-dashboard-menu-supplements',
  templateUrl: './supplements.component.html',
  styleUrls: ['./supplements.component.css']
})
export class SupplementsComponent extends SortableObjectPanel<Menu, Supplement> implements MenuSubComponent {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  public supplementEditor: MenuSupplementEditor;
  public supplementProductEditor: MenuSupplementProductEditor;

  public selectedSupplement: Supplement;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);
    super.init();
  }

  protected getListDone() {
    if (this.selectedSupplement != null) {
      for (const supplement of this.listObject.supplements) {
        if (supplement.id === this.selectedSupplement.id) {
          this.selectedSupplement = supplement;
        }
      }
    }
  }

  public setSupplementEditor(supplementEditor: MenuSupplementEditor): void {
    this.supplementEditor = supplementEditor;
  }

  public setSupplementProductEditor(supplementProductEditor: MenuSupplementProductEditor): void {
    this.supplementProductEditor = supplementProductEditor;
  }

  public selectSupplement(supplement: Supplement): void {
    this.selectedSupplement = supplement;
  }

  public getSupplementBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_SUPPLEMENT;
  }

  public getSupplementProductBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_SUPPLEMENT_PRODUCT;
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_SUPPLEMENT;
  }


}
