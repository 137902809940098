<app-normal-popup [editorComponent]="this" title="Search user">
  <ng-container *ngIf="shopId != null">
    <div>
      <table>
        <ng-container>
          <tr>
            <td style="width: 64px"></td>
            <th align="left" class="table-sub-header">User</th>
            <td></td>
          </tr>

          <tr>
            <td></td>
            <td>Email</td>
            <td><input type="email" [disabled]="user != null" [(ngModel)]="email" placeholder="someone@domain.com"></td>
          </tr>
        </ng-container>

        <ng-container *ngIf="user != null">
          <tr>
            <td></td>
            <th style="padding-bottom: 10px" align="left" class="table-sub-header">User</th>
            <td></td>
          </tr>

          <tr>
            <td style="width: 64px"></td>
            <td style="padding-bottom: 7px">Lastname</td>
            <td>{{user.lastName}}</td>
          </tr>

          <tr>
            <td></td>
            <td style="padding-bottom: 7px">Firstname</td>
            <td>{{user.firstName}}</td>
          </tr>

          <tr>
            <td></td>
            <td style="padding-bottom: 12px">Email</td>
            <td>{{user.emails[0].email}}</td>
          </tr>
        </ng-container>

        <tr>
          <td></td>
          <td></td>
          <td align="right"><button class="button" [ngClass]="positiveButtonColor()" (click)="searchUser()" *ngIf="user == null">SEARCH USER</button><button class="button" [ngClass]="negativeButtonColor()" *ngIf="user != null" (click)="clear()">CLEAR</button></td>
        </tr>
      </table>
    </div>
  </ng-container>
</app-normal-popup>
