<app-normal-popup [editorComponent]="this" title="condiment">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Condiment details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td rowspan="2" valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
        <tr>
          <td valign="top" aria-autocomplete="none">Description</td>
          <td><textarea rows="3" style="margin-bottom: 4px; resize: vertical"></textarea></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Condiment price</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Euro</td>
        <td><label>
          <input type="number" [(ngModel)]="object.price">
        </label></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Similar option</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"></td>
        <td>Unique maker</td>
        <td><input type="text" [disabled]="mode === 2" [(ngModel)]="object.similarOptionUniqueMaker"></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
