<app-normal-popup [editorComponent]="this" title="news -> property">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Property</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Key</td>
        <td><input type="text" autocomplete="none" [disabled]="mode === 2" [(ngModel)]="object.name"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Property values</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td valign="top" style="width: 64px"><img width="40" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [disabled]="mode === 2" [(ngModel)]="item.value"></td>
        </tr>
      </ng-container>

    </table>
  </ng-container>
</app-normal-popup>
