<div class="popup-background" [ngClass]="{'popup-open': isOpen}" (click)="close()"></div>
<div class="popup-window" [ngClass]="{'popup-open': isOpen}">
  <div class="popup-window-header">
    <h3 class="popup-window-header-title">Select shop</h3>
    <img class="popup-window-header-button-close" src="assets/icons/close.png" (click)="close()" title="Close popup"/>
  </div>
  <div class="popup-window-main">
    <ng-container *ngIf="userShops != null">
      <table>
        <tr>
          <td width="64"></td>
          <th align="left" class="table-sub-header">Select shop</th>
          <td></td>
        </tr>

        <tr>
          <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
          <td>Shop</td>
          <td>
            <label>
              <select [(ngModel)]="shopId">
                <ng-container *ngFor="let userShop of userShops"><option  ngValue="{{userShop.shop.id}}">{{userShop.shop.name}}</option></ng-container>
              </select>
            </label>
          </td>
        </tr>

      </table>
    </ng-container>
  </div>
  <div class="popup-window-footer">
    <div class="button-box-right">
      <button class="button button-green" (click)="submit()">SELECT</button>
      <button class="button button-red" (click)="close()">CANCEL</button>
    </div>
  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
