import {Component, Input, OnInit} from '@angular/core';
import {EditComponent} from '../../../../../menu/edit/edit.component';
import {Defaults} from '../../../../../../constants/defaults';
import {Product} from '../../../../../../domain/menu/product';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpHandler} from '../../../../../../handlers/httpHandler';
import {ProductImage} from '../../../../../../domain/menu/ProductImage';
import {IHaveMyHttpHandler} from '../../../../../my-http-handler/IHaveMyHttpHandler';
import {CatalogEditComponent} from '../../../edit.component';
import {CatalogProductImage} from '../../../../../../domain/stock/CatalogProductImage';
import {AbstractEditorComponent} from '../../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../../abstract/IInit';
import {UploadResult} from './UploadResult';

@Component({
  selector: 'app-dashboard-catalog-product-image-editor',
  templateUrl: './product-image-editor.component.html',
  styleUrls: ['./product-image-editor.component.css']
})
export class CatalogProductImageEditorComponent extends AbstractEditorComponent<CatalogProductImage, CatalogProductImage> {
  @Input() editComponent: CatalogEditComponent;
  @Input() menuCategoryId: number;

  public imageRoot = Defaults.SERVER_STOCK_CONTENT;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setProductImageEditorComponent(this);
  }

  // TODO make also a images super type

  /* public onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      let headers = HttpHandler.getSessionHeaders();

      this.myHttpHandlerComponent.get<CatalogProductImage>(this.http.get<CatalogProductImage>(Defaults.SERVER_API_CATALOG_PRODUCT_IMAGE + 'image/createNew/' + this.productId, { headers }), (productImage: CatalogProductImage) => {
        const formDate = new FormData();
        formDate.append('file', file);

        headers = new HttpHeaders({
          sessionId: Defaults.getSessionId(),
          sessionToken: Defaults.getSessionToken(),
          productImageId: productImage.id,
          productId: this.productImage.id,
        });

        this.myHttpHandlerComponent.get<CatalogProductImage>(this.http.post<CatalogProductImage>(Defaults.SERVER_API_CATALOG_PRODUCT_IMAGE + 'image/upload', formDate, { headers }), (result: CatalogProductImage) => {
          this.productImage = result;
          // this.productImageId = result.id;
        });
      });
    }
  } */

  public onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      let headers = HttpHandler.getSessionHeaders();

      const formData = new FormData();
      formData.append('file', file);

      this.myHttpHandlerComponent.get<UploadResult>(this.http.post<UploadResult>(Defaults.SERVER_STOCK_CONTENT + 'upload', formData, { headers }), (result: UploadResult) => {
        this.object.imageId = result.fileName;

        this.submit();
      });
    }
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_PRODUCT_IMAGE;
  }

  protected getTheObject(): CatalogProductImage {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }
}
