import {Component, Input} from '@angular/core';
import {MenuComponent} from '../menu.component';
import {HttpClient} from '@angular/common/http';
import {MenuVariant} from '../../../domain/menu/MenuVariant';
import {MenuVariantEditor} from './subpanels/create-variant/menu-variant-editor.component';
import {Defaults} from '../../../constants/defaults';
import {VariantOptionEditorComponent} from './subpanels/create-option/variant-option-editor.component';
import {Menu} from '../../../domain/menu/menu';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {MenuSubComponent} from '../MenuSubComponent';

@Component({
  selector: 'app-dashboard-menu-variants',
  templateUrl: './variants.component.html',
  styleUrls: ['./variants.component.css']
})
export class VariantsComponent extends SortableObjectPanel<Menu, MenuVariant> implements MenuSubComponent {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  public variantEditor: MenuVariantEditor;
  public variantOptionEditorComponent: VariantOptionEditorComponent;

  public selectedVariant: MenuVariant;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);
    super.init();
  }

  protected getListDone() {
    if ( this.selectedVariant != null ) {
      let replaced = false;
      for ( const variant of this.listObject.variants ) {
        if ( variant.id === this.selectedVariant.id ) {
          this.selectedVariant = variant;
          replaced = true;
          break;
        }
      }

      if ( !replaced ) {
        this.selectedVariant = null;
      }
    }
  }

  public setCreateVariantComponent(variantEditor: MenuVariantEditor): void {
    this.variantEditor = variantEditor;
  }

  public setCreateVariantOptionEditor(variantOptionEditorComponent: VariantOptionEditorComponent): void {
    this.variantOptionEditorComponent = variantOptionEditorComponent;
  }

  public selectVariant(productVariant: MenuVariant): void {
    this.selectedVariant = productVariant;
  }

  getObjectBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_VARIANT + '/';
  }

  public getVariantOptionBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_VARIANT_OPTION + '/';
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_VARIANT;
  }
}
