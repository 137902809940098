import {Component, Input} from '@angular/core';
import {AbstractRootEditorComponent} from '../../../abstract/AbstractRootEditorComponent';
import {AbstractEditingSelectionComponent} from '../../../menu/editing-menu/AbstractEditingSelectionComponent';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {IInit} from '../../../abstract/IInit';
import {Gallery} from '../../../../domain/gallery/Gallery';

@Component({
  selector: 'app-dashboard-edit-gallery',
  templateUrl: './gallery-editor.component.html',
  styleUrls: ['./gallery-editor.component.css']
})
export class GalleryEditorComponent extends AbstractRootEditorComponent<Gallery> {
  @Input() editingGalleryComponent: AbstractEditingSelectionComponent<Gallery, Gallery>;

  constructor(private router: Router, http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editingGalleryComponent.setAbstractEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_GALLERY;
  }

  protected getApiEditingEndpoint(): string {
    return Defaults.SERVER_API_GALLERY_SELECTION;
  }

  protected getTheObject(): Gallery {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.editingGalleryComponent;
  }

}
