<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Allergens</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">
  </div>

  <div>
    <!-- TODO add popup components here -->
  </div>
</div>
