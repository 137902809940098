import {IHaveMyHttpHandler} from '../my-http-handler/IHaveMyHttpHandler';
import {HttpClient} from '@angular/common/http';
import {HttpHandler} from '../../handlers/httpHandler';

export abstract class AbstractListComponent<ListT, T> extends IHaveMyHttpHandler {

  public listObject: ListT;

  protected constructor(protected http: HttpClient) {
    super();
  }

  public abstract getApiRootEndpoint(): string;

  public init(): void {
    this.getList();
  }

  protected getList(): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<ListT>(this.http.get<ListT>(this.getApiRootEndpoint() + 'all', { headers }), (result: ListT) => {
      this.listObject = result;

      this.getListDone();
    });
  }

  protected getListDone(): void {

  }

}
