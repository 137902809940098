<app-dashboard-header mainComponent="shop"></app-dashboard-header>
<app-dashboard-shops-navigation [component]="component" [shopId]="shopId" [shopsComponent]="this"></app-dashboard-shops-navigation>
<main>
  <div class="menu-full-tools" style="z-index: 2">
    <app-dashboard-shop-editing-shop [shopsComponent]="this"></app-dashboard-shop-editing-shop>

    <app-dashboard-shop-general *ngIf="getComponent() === 'general'" [shopsComponent]="this"></app-dashboard-shop-general>
    <app-dashboard-shop-menu-closure *ngIf="getComponent() === 'menu-closures'" [shopsComponent]="this"></app-dashboard-shop-menu-closure>
    <app-dashboard-shop-languages *ngIf="getComponent() === 'languages'" [shopsComponent]="this"></app-dashboard-shop-languages>
    <app-dashboard-shop-employee *ngIf="getComponent() === 'employee'" [shopsComponent]="this"></app-dashboard-shop-employee>
  </div>
</main>
