import {Component, Input} from '@angular/core';
import {AbstractEditingSelectionComponent} from '../../menu/editing-menu/AbstractEditingSelectionComponent';
import {Shop} from '../../../domain/shop/shop';
import {Defaults} from '../../../constants/defaults';
import {HttpClient} from '@angular/common/http';
import {ShopsComponent} from '../shops.component';
import {ShopCreateObject} from '../shop/update-shop-component/ShopCreateObject';

@Component({
  selector: 'app-dashboard-shop-editing-shop',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css']
})
export class SelectionComponent extends AbstractEditingSelectionComponent<ShopCreateObject, Shop>{
  @Input() shopsComponent: ShopsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public selectionChanged(): void {
    this.init();
    this.shopsComponent.selectionChanged();
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_SHOP_SELECTION;
  }
}
