import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IHaveMyHttpHandler} from '../../my-http-handler/IHaveMyHttpHandler';
import {IHaveSelectShopComponent} from './IHaveSelectShopComponent';
import {HttpHandler} from '../../../handlers/httpHandler';
import {UserShop} from '../../../domain/shop/userShop';

@Component({
  selector: 'app-dashboard-select-shop1',
  templateUrl: './select-shop.component.html',
  styleUrls: ['./select-shop.component.css']
})
export class SelectShopComponent1 extends IHaveMyHttpHandler {
  @Input() iHaveSelectShopComponent: IHaveSelectShopComponent;

  public isOpen = false;
  private then: (shopId: string) => void;

  public shopId: string;
  public userShops: UserShop[];

  constructor(private http: HttpClient) {
    super();
  }

  init(): void {
    this.iHaveSelectShopComponent.setSelectShopComponent(this);
  }

  public open(apiRootEndpoint: string, then: (shopId: string) => void): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<UserShop[]>(this.http.get<UserShop[]>(apiRootEndpoint + 'allForUser', { headers }), (result: UserShop[]) => {
      this.then = then;
      this.shopId = null;
      this.userShops = result;
      this.isOpen = true;
    });
  }

  public close(): void {
    this.then = null;
    this.shopId = null;
    this.userShops = null;
    this.isOpen = false;
  }

  public submit(): void {
    this.then(this.shopId);
    this.close();
  }


}
