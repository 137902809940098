<app-normal-popup [editorComponent]="this" title="tablet">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Tablet details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{item.languageCode}}-s.jpg" alt="Language illustration flag"></td>
          <td style="border-bottom: 1px solid lightgray">Name</td>
          <td style="border-bottom: 1px solid lightgray"><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
      </ng-container>

    </table>
  </ng-container>
</app-normal-popup>

<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
