import {Component, Input, OnInit} from '@angular/core';
import {AbstractEditingSelectionComponent} from '../../menu/editing-menu/AbstractEditingSelectionComponent';
import {Menu} from '../../../domain/menu/menu';
import {MenuComponent} from '../../menu/menu.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {GalleryComponent} from '../gallery.component';
import {Gallery} from '../../../domain/gallery/Gallery';

@Component({
  selector: 'app-dashboard-gallery-editing-gallery',
  templateUrl: './editing-gallery.component.html',
  styleUrls: ['./editing-gallery.component.css']
})
export class EditingGalleryComponent extends AbstractEditingSelectionComponent<Gallery, Gallery> {
  @Input() galleryComponent: GalleryComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public selectionChanged(): void {
    this.init();
    this.galleryComponent.selectionChanged();
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_GALLERY_SELECTION;
  }

}
