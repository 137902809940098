<app-normal-popup [editorComponent]="this" title="news -> product">
  <ng-container *ngIf="object != null && object.categories != null">
    <table>
      <tr>
        <td width="64"></td>
        <th align="left" class="table-sub-header">Select product</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Category</td>
        <td>
          <label>
            <select [disabled]="mode === 2" [(ngModel)]="categoryId">
              <ng-container *ngFor="let category of object.categories"><option  ngValue="{{category.id}}">{{category.details[0].name}}</option></ng-container>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Product</td>
        <td>
          <label>
            <select [disabled]="mode === 2" [(ngModel)]="object.newsProduct.productId">
              <ng-container *ngFor="let category of object.categories">
                <ng-container *ngFor="let product of category.products">
                  <ng-container *ngIf="(categoryId == null || categoryId === product.categoryId) || object.newsProduct.productId === product.id"><option ngValue="{{product.id}}">{{product.details[0].name}} ({{product.price}})</option></ng-container>
                </ng-container>
              </ng-container>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td width="64"></td>
        <th align="left" class="table-sub-header">Format</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Size (width)</td>
        <td>
          <label>
            <select [disabled]="mode === 2" [(ngModel)]="object.newsProduct.size">
              <option value="64">64px</option>
              <option value="96">96px</option>
              <option value="128">128px</option>
              <option value="256">256px</option>
              <option value="512">512px</option>
              <option value="1024">1024PX</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Aspect ratio</td>
        <td>
          <label>
            <select [disabled]="mode === 2" [(ngModel)]="object.newsProduct.aspectRatio">
              <optgroup label="Square">
                <option value="4-4">4/4 (Square)</option>
              </optgroup>
              <optgroup label="Landscape">
                <option value="4-3">4/3 (Rectangular)</option>
                <option value="4-2">4/2 (Wide)</option>
                <option value="4-1">4/1 (Ultra wide)</option>
              </optgroup>
              <optgroup label="Portrait">
                <option value="4-3">3/4 (Rectangular)</option>
              </optgroup>
            </select>
          </label>
        </td>
      </tr>
    </table>
  </ng-container>
</app-normal-popup>
