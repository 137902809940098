import {Component, Input} from '@angular/core';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {Menu} from '../../../domain/menu/menu';
import {MenuDiscountCoupon} from '../../../domain/menu/MenuDiscountCoupon';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {MenuComponent} from '../menu.component';
import {MenuDiscountCouponEditorComponent} from './discount-coupon-editor/menu-discount-coupon-editor.component';

@Component({
  selector: 'app-dashboard-menu-discount-coupon',
  templateUrl: './menu-discount-coupon.component.html',
  styleUrls: ['./menu-discount-coupon.component.css']
})
export class MenuDiscountCouponComponent extends SortableObjectPanel<Menu, MenuDiscountCoupon>{
  @Input() menuComponent: MenuComponent;

  public menuDiscountCouponEditorComponent: MenuDiscountCouponEditorComponent;

  public selectedDiscountCoupon;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);

    super.init();
  }

  public setSelectedDiscountCoupon(discountCoupon: MenuDiscountCoupon) {
    this.selectedDiscountCoupon = discountCoupon;
  }

  public setMenuDiscountCouponEditorComponent(menuDiscountCouponEditorComponent: MenuDiscountCouponEditorComponent): void {
    this.menuDiscountCouponEditorComponent = menuDiscountCouponEditorComponent;
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_DISCOUNT_COUPON;
  }

}
