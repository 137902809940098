import {Component, Input} from '@angular/core';
import {Defaults} from '../../../../../../constants/defaults';
import {HttpClient} from '@angular/common/http';
import {MenuProductVariant} from '../../../../../../domain/menu/ProductVariant';
import {MenuProductVariantCreateObject} from './MenuProductVariantCreateObject';
import {AbstractEditorComponent} from '../../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../../abstract/IInit';
import {EditComponent} from '../../../edit.component';

@Component({
  selector: 'app-dashboard-menu-product-variant-editor',
  templateUrl: './menu-product-variant-editor.component.html',
  styleUrls: ['./menu-product-variant-editor.component.css']
})
export class MenuProductVariantEditor extends AbstractEditorComponent<MenuProductVariantCreateObject, MenuProductVariant> {
  @Input() editComponent: EditComponent;
  @Input() menuCategoryId: number;

  public imageRoot = Defaults.SERVER_API_MENU_PRODUCT;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setMenuProductVariantEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT_VARIANT;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }

  protected getTheObject(): MenuProductVariant {
    return this.object.productVariant;
  }
}
