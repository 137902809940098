import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {AbstractSelectComponent} from '../../../../menu/editing-menu/popups/select-menu/AbstractSelectComponent';
import {AbstractEditingSelectionComponent} from '../../../../menu/editing-menu/AbstractEditingSelectionComponent';
import {CatalogDetail} from '../../../../../domain/stock/CatalogDetail';
import {Catalog} from '../../../../../domain/stock/Catalog';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-stock-select-catalog',
  templateUrl: './select-catalog.component.html',
  styleUrls: ['./select-catalog.component.css']
})
export class SelectCatalogComponent extends AbstractSelectComponent<Catalog, Catalog> {
  @Input() public editingSelectionComponent: AbstractEditingSelectionComponent<Catalog, Catalog>;

  constructor(http: HttpClient) {
    super(http);
  }

  protected getAbstractEditingSelectionComponent(): AbstractEditingSelectionComponent<Catalog, Catalog> {
    return this.editingSelectionComponent;
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_SELECTION;
  }

  protected getSelectedShopId(): string {
    return this.object.user.selectedCatalogShopId;
  }

  protected getSelectionId(): string {
    return this.object.user.selectedCatalogId;
  }

  protected getSelectedLanguageCode(): string {
    return this.object.user.selectedCatalogLanguageCode;
  }

  public init(): void {
    this.editingSelectionComponent.setSelectComponent(this);
  }

  public getCatalogDetail(catalogDetails: CatalogDetail[], languageCode: String): CatalogDetail {
    for ( const catalogDetail of catalogDetails ) {
      if ( catalogDetail.languageCode === languageCode ) {
        return catalogDetail;
      }
    }

    return catalogDetails[0];
  }

  protected getInitableComponent(): IInit {
    return null;
  }

  protected getTheObject(): Catalog {
    return null;
  }
}
