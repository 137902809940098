import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {AbstractEditingSelectionComponent} from './AbstractEditingSelectionComponent';
import {MenuComponent} from '../menu.component';
import {Menu} from '../../../domain/menu/menu';

@Component({
  selector: 'app-dashboard-menu-editing-menu',
  templateUrl: './editing-menu.component.html',
  styleUrls: ['./editing-menu.component.css']
})
export class EditingMenuComponent extends AbstractEditingSelectionComponent<Menu, Menu> {
  @Input() menuComponent: MenuComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public selectionChanged(): void {
    this.init();
    this.menuComponent.selectionChanged();
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_SELECTION;
  }
}
