<app-normal-popup [editorComponent]="this" title="discount coupon">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Coupon status</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Status</td>
        <td>
          <label>
            <select [(ngModel)]="object.active">
              <option value="A">Active</option>
              <option value="I">Inactive</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Coupon details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td rowspan="2" valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
        <tr>
          <td valign="top" aria-autocomplete="none">Description</td>
          <td><textarea rows="3" style="margin-bottom: 4px; resize: vertical" [(ngModel)]="item.description"></textarea></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Discount code</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Code</td>
        <td><input type="text" [(ngModel)]="object.discountCode"></td>
      </tr>

      <tr>
        <td></td>
        <td>Max usages</td>
        <td><input type="number" [(ngModel)]="object.maxAvailable"></td>
      </tr>

      <tr>
        <td></td>
        <td>Valid from</td>
        <td><input class="time-from" type="datetime-local" id="from" [(ngModel)]="from"></td>
      </tr>

      <tr>
        <td></td>
        <td>Valid till</td>
        <td><input class="time-from" type="datetime-local" id="till" [(ngModel)]="till"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Discount value</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Value mode</td>
        <td>
          <label>
            <select [(ngModel)]="object.valueMode">
              <option value="P">Percentage</option>
              <option value="A">Amount</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <td><ng-container *ngIf="object.valueMode === 'A'">Euro (cents)</ng-container><ng-container *ngIf="object.valueMode === 'P'">Percentage</ng-container></td>
        <td><input [(ngModel)]="object.value"></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
