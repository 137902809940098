<app-left-side-container [iHaveMyHttpHandler]="this">
  <ng-container *ngIf="listObject != null && listObject.images != null">

    <div class="dashboard-item">
      <div>
        <h4 class="left">Catalog images</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="catalogImageEditorComponent.open(0, listObject.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="listObject.images != null">
        <ng-container *ngFor="let image of listObject.images">
          <div class="bordered-item hoverable" (dblclick)="catalogImageEditorComponent.open(1, null, image.id)">
            <div class="dashboard-bordered-item-sub-left">
              <div class="table-menu-image-container"><img class="table-menu-image" src="{{imageRoot}}get/96/4-4/{{image.imageId}}.png"></div>
            </div>
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <p><strong><ng-container>{{image.title}}</ng-container></strong></p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img *ngIf="image.sequence > 0" (click)="moveUp(image, getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="image.sequence < listObject.images.length - 1" (click)="moveDown(image, getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img (click)="catalogImageEditorComponent.open(1, null, image.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>

  </ng-container>
</app-left-side-container>

<app-dashboard-catalog-image-editor [catalogImageComponent]="this"></app-dashboard-catalog-image-editor>
