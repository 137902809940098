import {Component, Input} from '@angular/core';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {CatalogNews} from '../../../../domain/stock/CatalogNews';
import {HttpClient} from '@angular/common/http';
import {NewsComponent} from '../news.component';
import {IInit} from '../../../abstract/IInit';
import {Defaults} from '../../../../constants/defaults';
import {CatalogNewsCreateObject} from './CatalogNewsCreateObject';
import {HttpHandler} from '../../../../handlers/httpHandler';
import {CatalogNewsNotifyResult} from '../../../../domain/stock/CatalogNewsNotifyResult';
import {EditorMode} from '../../../abstract/EditorMode';
import {CatalogNewsChannel} from '../CatalogNewsChannel';

@Component({
  selector: 'app-dashboard-catalog-news-editor',
  templateUrl: './catalog-news-editor.component.html',
  styleUrls: ['./catalog-news-editor.component.css']
})
export class CatalogNewsEditorComponent extends AbstractEditorComponent<CatalogNewsCreateObject, CatalogNews>{
  @Input() newsComponent: NewsComponent;

  private next: (newsChannel: CatalogNewsChannel) => void;
  public simulation: string;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.newsComponent.setNewsEditorComponent(this);
  }

  public openForChannel(news: CatalogNews, next: (newsChannel: CatalogNewsChannel) => void): void {
    this.next = next;
    this.simulation = 'Y';
    this.open(EditorMode.OTHERS, null, news.id);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_NEWS;
  }

  protected getInitableComponent(): IInit {
    return this.newsComponent;
  }

  protected getTheObject(): CatalogNews {
    return this.object.news;
  }

  public titleText(text: string): string {
    if ( this.mode === EditorMode.OTHERS ) {
      return 'Send ' + text + ' emails';
    }

    return super.titleText(text);
  }

  public positiveButtonColor(): string {
    if ( this.mode === EditorMode.OTHERS ) {
      return 'button-blue';
    }

    return super.positiveButtonColor();
  }

  public buttonText(): string {
    if ( this.mode === EditorMode.OTHERS ) {
      return 'SEND EMAILS';
    }

    return super.buttonText();
  }

  public submit() {
    if ( this.mode === EditorMode.OTHERS ) {
      const headers = HttpHandler.getSessionHeaders();

      this.myHttpHandlerComponent.get<CatalogNewsChannel>(this.http.get<CatalogNewsChannel>(this.getApiRootEndpoint() + 'notificationChannel/' + this.object.news.id + '/' + (this.simulation === 'Y'? 'true': 'false'), { headers }), (result: CatalogNewsChannel) => {
        this.next(result);
        this.close();
      });
    }
    else {
      super.submit();
    }
  }
}
