import {Component, Input} from '@angular/core';
import {MenuComponent} from '../menu.component';
import {HttpClient} from '@angular/common/http';
import {IInit} from '../../abstract/IInit';
import {MenuPromotionEditorComponent} from './promotion-editor/menu-promotion-editor.component';
import {Menu} from '../../../domain/menu/menu';
import {MenuPromotion} from '../../../domain/menu/MenuPromotion';
import {Defaults} from '../../../constants/defaults';
import {SortableObjectPanel} from '../../SortableObjectPanel';

@Component({
  selector: 'app-dashboard-menu-promotion',
  templateUrl: './menu-promotion.component.html',
  styleUrls: ['./menu-promotion.component.css']
})
export class MenuPromotionComponent extends SortableObjectPanel<Menu, MenuPromotion> implements IInit {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  public menuPromotionEditorComponent: MenuPromotionEditorComponent;

  public selectedPromotion: MenuPromotion;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);

    super.init();
  }

  public setMenuPromotionEditorComponent(menuPromotionEditorComponent: MenuPromotionEditorComponent): void {
    this.menuPromotionEditorComponent = menuPromotionEditorComponent;
  }

  public selectPromotion(promotion: MenuPromotion): void {
    this.selectedPromotion = promotion;
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_PROMOTION;
  }

}
