<app-normal-popup [editorComponent]="this" title="language">
  <ng-container *ngIf="object != null">
    <table>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Language</th>
        <td></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0px 0px 8px" src="assets/flags/{{object.shopLanguage.languageCode}}-s.jpg"></td>
        <td>Language: </td>
        <td>
          <select [disabled]="mode === 1 || mode === 2" [(ngModel)]="object.shopLanguage.languageCode">
            <option ngValue="{{language.code}}" *ngFor="let language of object.languages">{{language.name}}</option>
          </select>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Options</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/menu.png"></td>
        <td>Active in Menu: </td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.shopLanguage.activeInMenu">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/pos.png"></td>
        <td>Active in Pos: </td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.shopLanguage.activeInPos">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/delivery.png"></td>
        <td>Active in Stock: </td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.shopLanguage.activeInStock">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/screen.png"></td>
        <td>Active in Screen: </td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.shopLanguage.activeInScreen">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/gallery.png"></td>
        <td>Active in Gallery: </td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.shopLanguage.activeInGallery">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
