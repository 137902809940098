import {Component, Input, OnInit} from '@angular/core';
import {StockComponent} from '../stock.component';
import {MenuComponent} from '../../menu/menu.component';

@Component({
  selector: 'app-dashboard-stock-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class StockNavigationComponent implements OnInit {
  @Input() component: string;
  @Input() stockComponent: StockComponent;

  constructor() {
  }

  ngOnInit(): void {
  }

  public getComponent(): string {
    return this.component;
  }

  public openComponent(component: string): void {
    this.stockComponent.openComponent(component);
  }
}
