<app-normal-popup [editorComponent]="this" title="printer">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Printer details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{item.languageCode}}-s.jpg" alt="Language illustration flag"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Properties</th>
        <td></td>
      </tr>

      <tr>
        <td valign="top"></td>
        <td>Connection type</td>
        <td>
          <select [(ngModel)]="object.connectionType">
            <option value="E">Ethernet</option>
            <option value="U">Usb</option>
            <option value="B">Bluetooth</option>
          </select>
        </td>
      </tr>

      <ng-container *ngIf="object.connectionType === 'E'">
        <tr>
          <td valign="top"></td>
          <td>Ip address</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="object.ip"></td>
        </tr>

        <tr>
          <td valign="top"></td>
          <td>Port</td>
          <td><input type="number" autocomplete="none" [(ngModel)]="object.port"></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Extra properties</th>
        <td></td>
      </tr>

      <tr>
        <td valign="top"></td>
        <td>Dots per inch</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.dotsPerInch"></td>
      </tr>

      <tr>
        <td valign="top"></td>
        <td>Chars. per line</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.charactersPerLine"></td>
      </tr>

      <tr>
        <td valign="top"></td>
        <td>Paper width</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.paperWidth"></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
