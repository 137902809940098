import {Component, Input} from '@angular/core';
import {StockComponent} from '../stock.component';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {CatalogNewsEmailSubscription} from '../../../domain/stock/CatalogNewsEmailSubscription';
import {HttpClient} from '@angular/common/http';
import {Catalog} from '../../../domain/stock/Catalog';
import {Defaults} from '../../../constants/defaults';
import {CatalogEmailSubscriptionEditorComponent} from './email-subscription-editor/catalog-email-subscription-editor.component';

@Component({
  selector: 'app-dashboard-stock-email-subscriptions',
  templateUrl: './catalog-email-subscriptions.component.html',
  styleUrls: ['./catalog-email-subscriptions.component.css']
})
export class CatalogEmailSubscriptionsComponent extends SortableObjectPanel<Catalog, CatalogNewsEmailSubscription>{
  @Input() stockComponent: StockComponent;

  public emailSubscriptionEditor: CatalogEmailSubscriptionEditorComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.stockComponent.setSubComponent(this);

    super.init();
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_EMAIL_SUBSCRIPTION;
  }

  public setCatalogEmailSubscriptionComponent(emailSubscriptionEditor: CatalogEmailSubscriptionEditorComponent): void {
    this.emailSubscriptionEditor = emailSubscriptionEditor;
  }

}
