import {Component, Input, OnInit} from '@angular/core';
import {MenuComponent} from '../menu.component';
import {IHaveMyHttpHandler} from '../../my-http-handler/IHaveMyHttpHandler';
import {MenuSubComponent} from '../MenuSubComponent';

@Component({
  selector: 'app-dashboard-menu-allergens',
  templateUrl: './allergens.component.html',
  styleUrls: ['./allergens.component.css']
})
export class AllergensComponent extends IHaveMyHttpHandler implements MenuSubComponent {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  constructor() {
    super();
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);
  }

}
