<app-left-side-container [iHaveMyHttpHandler]="this">
  <ng-container *ngIf="listObject != null">

    <div class="dashboard-item">
      <div>
        <h4 class="left">Discount coupons</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="menuDiscountCouponEditorComponent.open(0, listObject.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="listObject.discountCoupons != null">
        <ng-container *ngFor="let discountCoupon of listObject.discountCoupons">
          <div class="bordered-item hoverable" [ngClass]="{'selected': discountCoupon == selectedDiscountCoupon}" (click)="setSelectedDiscountCoupon(discountCoupon)" (dblclick)="menuDiscountCouponEditorComponent.open(1, null, discountCoupon.id)">
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <p><strong><ng-container *ngIf="discountCoupon.details != null && discountCoupon.details.length > 0">{{discountCoupon.details[0].name}}</ng-container></strong></p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img *ngIf="discountCoupon.sequence > 0" (click)="moveUp(discountCoupon, getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="discountCoupon.sequence < listObject.discountCoupons.length - 1" (click)="moveDown(discountCoupon, getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img (click)="menuDiscountCouponEditorComponent.open(1, null, discountCoupon.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </ng-container>
</app-left-side-container>

<app-dashboard-menu-discount-coupon-editor [menuDiscountCouponComponent]="this"></app-dashboard-menu-discount-coupon-editor>
