import {Component, Input, OnInit} from '@angular/core';
import {AbstractObjectComponent} from '../../abstract/AbstractObjectComponent';
import {Shop} from '../../../domain/shop/shop';
import {HttpClient} from '@angular/common/http';
import {ShopsComponent} from '../shops.component';
import {Defaults} from '../../../constants/defaults';
import {MenuCategoryEditorComponent} from '../../menu/edit/createcategory/menu-category-editor.component';
import {MenuClosureEditorComponent} from './menu-closure-editor/menu-closure-editor.component';

@Component({
  selector: 'app-dashboard-shop-menu-closure',
  templateUrl: './menu-closure.component.html',
  styleUrls: ['./menu-closure.component.css']
})
export class MenuClosureComponent extends AbstractObjectComponent<Shop> {
  @Input() shopsComponent: ShopsComponent;

  public menuClosureEditor: MenuClosureEditorComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.shopsComponent.setSubComponent(this);
    super.init();
  }

  public setMenuClosureEditorComponent(menuClosureEditor: MenuClosureEditorComponent): void {
    this.menuClosureEditor = menuClosureEditor;
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_SHOP;
  }

}
