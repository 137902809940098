<app-normal-popup [editorComponent]="this" title="Sending mails" backgroundLock="true">
  <ng-container *ngIf="startObject != null">
    <div style="padding: 25px 0 30px 16px" *ngIf="!done">
      <p>Sending emails please wait. (Do not start it again!)</p>
      <progress style="width: 100%; height: 20px" [value]="current" [max]="startObject.subscriberCount">progress</progress>
    </div>
    <div style="padding: 25px 0 30px 16px" *ngIf="done">
      <h4>Done</h4>
    </div>
  </ng-container>
</app-normal-popup>
