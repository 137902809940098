<div class="theleft">
  <div class="nav">
    <table>
      <tr id="menu" title="Menu" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'edit'}" (click)="openComponent('edit')">
        <td class="nav-item-img"><img src="assets/icons/dark/streamline-icon-e-commerce-touch-buy@24x24.png"></td>
        <td class="nav-item-title"><h4>Catalog</h4></td>
      </tr>

      <tr><td class="nav-separator"></td></tr>

      <tr id="image" title="Image" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'image'}" (click)="openComponent('image')">
        <td class="nav-item-img"><img src="assets/icons/dark/streamline-icon-e-commerce-touch-buy@24x24.png"></td>
        <td class="nav-item-title"><h4>Image</h4></td>
      </tr>

      <tr><td class="nav-separator"></td></tr>

      <tr id="supplements" title="Supplements" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'suppliers'}" (click)="openComponent('suppliers')">
        <td class="nav-item-img"><img src="assets/icons/dark/streamline-icon-delivery-truck@24x24.png"></td>
        <td class="nav-item-title"><h4>Suppliers</h4></td>
      </tr>

      <tr><td class="nav-separator"></td></tr>

      <tr id="condiments" title="Condiments" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'clients'}" (click)="openComponent('clients')">
        <td class="nav-item-img"><img src="assets/icons/dark/streamline-icon-workflow-teammate-circle@24x24.png"></td>
        <td class="nav-item-title"><h4>Clients</h4></td>
      </tr>

      <tr><td class="nav-separator"></td></tr>
      <tr><td class="nav-separator"></td></tr>
      <tr><td class="nav-separator"></td></tr>
      <tr><td class="nav-separator"></td></tr>

      <tr id="variants" title="Supplements" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'variants'}" (click)="openComponent('variants')">
        <td class="nav-item-img"><img src="assets/icons/variants.png" alt="VARIANTS"></td>
        <td class="nav-item-title"><h4>Variants</h4></td>
      </tr>

      <tr><td class="nav-separator"></td></tr>
      <tr><td class="nav-separator"></td></tr>
      <tr><td class="nav-separator"></td></tr>
      <tr><td class="nav-separator"></td></tr>

      <tr id="news" title="Promotion" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'news'}" (click)="openComponent('news')">
        <td class="nav-item-img"><img src="assets/icons/streamline-icon-tag-euro@24x24.png" alt="NEWS"></td>
        <td class="nav-item-title"><h4>News</h4></td>
      </tr>

      <tr><td class="nav-separator"></td></tr>

      <tr id="emailConfigurations" title="Promotion" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'emailConfigurations'}" (click)="openComponent('emailConfigurations')">
        <td class="nav-item-img"><img src="assets/icons/streamline-icon-tag-euro@24x24.png" alt="NEWS"></td>
        <td class="nav-item-title"><h4>Email configuration</h4></td>
      </tr>

      <tr><td class="nav-separator"></td></tr>

      <tr id="emailTemplates" title="Promotion" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'emailTemplates'}" (click)="openComponent('emailTemplates')">
        <td class="nav-item-img"><img src="assets/icons/streamline-icon-tag-euro@24x24.png" alt="NEWS"></td>
        <td class="nav-item-title"><h4>Email templates</h4></td>
      </tr>

      <tr><td class="nav-separator"></td></tr>

      <tr id="emailSubscriptions" title="Promotion" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'emailSubscriptions'}" (click)="openComponent('emailSubscriptions')">
        <td class="nav-item-img"><img src="assets/icons/streamline-icon-tag-euro@24x24.png" alt="NEWS"></td>
        <td class="nav-item-title"><h4>Email subscriptions</h4></td>
      </tr>

      <tr><td class="nav-separator"></td></tr>

      <tr id="promotion" title="Promotion" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'promotion'}" (click)="openComponent('promotion')">
        <td class="nav-item-img"><img src="assets/icons/streamline-icon-tag-euro@24x24.png" alt="PROMOTION"></td>
        <td class="nav-item-title"><h4>Promotions</h4></td>
      </tr>

    </table>
  </div>
</div>
