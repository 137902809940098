import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', './app.button.css', './app.input.css', './app.popup.css']
})
export class AppComponent {
  title = 'dashboard';
}
