<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Statistics</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">

    <ng-container *ngIf="multipleRevenueObject != null">
      <div class="dashboard-item">
        <h4 class="left">Period <strong>({{multipleRevenueObject.total / 100 | currency: 'EUR'}})</strong></h4>
        <div class="period-box">
          <input class="time-from" type="datetime-local" id="from" [(ngModel)]="from">
          <input class="time-to" type="datetime-local" id="to" [(ngModel)]="to">
        </div>
        <div>
          <button (click)="getRevenue()">Search</button>
        </div>
      </div>

      <ng-container *ngFor="let revenueObject of multipleRevenueObject.revenueObjects">
        <ng-container *ngIf="revenueObject != null">

          <div class="dashboard-item">
            <div>
              <h4 class="left">{{revenueObject.date}} <strong>({{revenueObject.total / 100 | currency: 'EUR'}})</strong></h4>
            </div>

            <ng-container *ngIf="revenueObject.orderTickets != null">
              <ng-container *ngFor="let orderTicket of revenueObject.orderTickets">
                <div class="bordered-item hoverable" [ngClass]="{'selected': false}">
                  <div class="dashboard-bordered-item-sub-left width-img-4buttons">
                    <p><strong>{{orderTicket.orderTime}}</strong></p>
                  </div>
                  <div class="dashboard-bordered-item-sub-right">
                    <div class="dashboard-bordered-item-sub-right-top">
                    </div>
                    <div class="dashboard-bordered-item-sub-right-bottom">
                      <strong>{{(orderTicket.price + orderTicket.deliveryPrice) / 100 | currency: 'EUR'}}</strong>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>

        </ng-container>
      </ng-container>
    </ng-container>

    <div>
      <app-trailer></app-trailer>
    </div>

  </div>

  <!-- <div class="menu-half-1-content">
    <google-chart
      [title]="chart.title"
      [type]="chart.type"
      [data]="chart.data"
      [columns]="chart.columnNames"
      [options]="chart.options"
    >
    </google-chart>

    <app-trailer></app-trailer>
  </div> -->

  <div>

  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
