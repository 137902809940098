import {Component, Input} from '@angular/core';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {ShopLanguage} from '../../../../domain/shop/shopLanguage';
import {HttpClient} from '@angular/common/http';
import {LanguagesComponent} from '../languages.component';
import {IInit} from '../../../abstract/IInit';
import {Defaults} from '../../../../constants/defaults';
import {ShopLanguageCreateObject} from './ShopLanguageCreateObject';

@Component({
  selector: 'app-shop-language-editor',
  templateUrl: './shop-language-editor.component.html',
  styleUrls: ['./shop-language-editor.component.css']
})
export class ShopLanguageEditorComponent extends AbstractEditorComponent<ShopLanguageCreateObject, ShopLanguage> {
  @Input() shopLanguageComponent: LanguagesComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.shopLanguageComponent.setShopLanguageEditor(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_SHOP_LANGUAGE;
  }

  protected getInitableComponent(): IInit {
    return this.shopLanguageComponent;
  }

  protected getTheObject(): ShopLanguage {
    return this.object.shopLanguage;
  }
}
