<div class="popup-background" [ngClass]="{'popup-open': editorComponent.isOpen  && !editorComponent.isForcedToClose}" (click)="close()"></div>
<div class="popup-window popup-window-message" [ngClass]="{'popup-open': editorComponent.isOpen && !editorComponent.isForcedToClose}">
  <div class="popup-window-header popup-window-header-message">
    <h3 class="popup-window-header-title">{{editorComponent.titleText(title)}}</h3>
    <img class="popup-window-header-button-close" src="assets/icons/close.png" (click)="editorComponent.close()" title="Close popup"/>
  </div>
  <div class="popup-window-main">
    <ng-content></ng-content>
  </div>
  <div class="popup-window-footer">
    <div class="button-box-right">
      <button class="button" [ngClass]="editorComponent.positiveButtonColor()" (click)="editorComponent.submit()">{{editorComponent.buttonText()}}</button>
      <button class="button" [ngClass]="editorComponent.negativeButtonColor()" (click)="editorComponent.close()">CANCEL</button>
    </div>
  </div>
</div>
<app-my-http-handler [myHttpHandlerReceiver]="this"></app-my-http-handler>
