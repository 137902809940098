import {Component, Input} from '@angular/core';
import {EditComponent} from '../../edit.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {ProductSupplementCreateObject} from './ProductSupplementCreateObject';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {ProductSupplement} from '../../../../../domain/menu/productSupplement';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-add-supplement',
  templateUrl: './menu-product-supplement-editor.component.html',
  styleUrls: ['./menu-product-supplement-editor.component.css']
})
export class MenuProductSupplementEditor extends AbstractEditorComponent<ProductSupplementCreateObject, ProductSupplement>{
  @Input() editComponent: EditComponent;
  @Input() menuCategoryId: number;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setMenuProductSupplementEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT_SUPPLEMENT;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }

  protected getTheObject(): ProductSupplement {
    return this.object.productSupplement;
  }
}
