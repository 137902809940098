<div class="theleft">
  <div class="nav">
    <table>
      <tr id="statistics" title="Menu" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'general'}" (click)="openComponent('general')">
        <td class="nav-item-img"><img src="assets/icons/statistics_dark.png" alt="EDIT"></td>
        <td class="nav-item-title"><h4>General</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="menuClosures" title="Menu" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'menu-closures'}" (click)="openComponent('menu-closures')">
        <td class="nav-item-img"><img src="assets/icons/statistics_dark.png" alt="EDIT"></td>
        <td class="nav-item-title"><h4>Menu closures</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="languages" title="Menu" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'languages'}" (click)="openComponent('languages')">
        <td class="nav-item-img"><img src="assets/icons/statistics_dark.png" alt="EDIT"></td>
        <td class="nav-item-title"><h4>Languages</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>

      <tr id="menu" title="Menu" class="nav-item" [ngClass]="{'nav-item-selected': getComponent() === 'employee'}" (click)="openComponent('employee')">
        <td class="nav-item-img"><img src="assets/icons/dark/streamline-icon-restaurant-menu@24x24.png" alt="EDIT"></td>
        <td class="nav-item-title"><h4>Employee</h4></td>
      </tr>
    </table>
  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
