<app-normal-popup [editorComponent]="this" title="employee">
  <ng-container *ngIf="object != null && object.user != null">
    <div>
      <table>
        <tr>
          <td style="width: 64px"></td>
          <th align="left" class="table-sub-header">User</th>
          <td></td>
        </tr>

        <tr>
          <td></td>
          <td style="padding-bottom: 7px">Lastname</td>
          <td>{{object.user.lastName}}</td>
        </tr>

        <tr>
          <td></td>
          <td style="padding-bottom: 7px">Firstname</td>
          <td>{{object.user.firstName}}</td>
        </tr>

        <tr>
          <td></td>
          <td style="padding-bottom: 7px">Email</td>
          <td>{{object.user.emails[0].email}}</td>
        </tr>

        <ng-container *ngIf="mode !== 2">
          <tr>
            <td></td>
            <th align="left" class="table-sub-header">Shop permissions</th>
            <td></td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Is owner</td>
            <td>
              <select [(ngModel)]="object.shop_owner">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage general shop details</td>
            <td>
              <select [(ngModel)]="object.shop_ManageShopDetails">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage closures</td>
            <td>
              <select [(ngModel)]="object.shop_ManageShopClosures">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage employee</td>
            <td>
              <select [(ngModel)]="object.shop_ManageEmployees">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td></td>
            <th align="left" class="table-sub-header">Menu permissions</th>
            <td></td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Edit menus</td>
            <td>
              <select [(ngModel)]="object.menu_EditMenus">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Use pos device</td>
            <td>
              <select [(ngModel)]="object.menu_UsePosDevices">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage pos printers</td>
            <td>
              <select [(ngModel)]="object.menu_ManagePrinters">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage pos devices</td>
            <td>
              <select [(ngModel)]="object.menu_ManagePosTablets">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Show day revenue</td>
            <td>
              <select [(ngModel)]="object.menu_ShowDayRevenue">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Show revenue</td>
            <td>
              <select [(ngModel)]="object.menu_OpenRevenue">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage menu tablets</td>
            <td>
              <select [(ngModel)]="object.menu_ManageMenuTablets">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage tables</td>
            <td>
              <select [(ngModel)]="object.menu_ManageMenuTables">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage delivery townships</td>
            <td>
              <select [(ngModel)]="object.menu_ManageMenuDelivery">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage qr configurations</td>
            <td>
              <select [(ngModel)]="object.menu_ManageQrConfigurations">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage shop closures (menu only)</td>
            <td>
              <select [(ngModel)]="object.menu_ManageShopClosures">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage external payment services</td>
            <td>
              <select [(ngModel)]="object.menu_ManageMollieSetup">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage tokens</td>
            <td>
              <select [(ngModel)]="object.menu_ManageTokens">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td></td>
            <th align="left" class="table-sub-header">Stock permissions</th>
            <td></td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Edit catalogs</td>
            <td>
              <select [(ngModel)]="object.stock_EditCatalogs">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage pos devices</td>
            <td>
              <select [(ngModel)]="object.stock_ManagePosDevices">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage pos printers</td>
            <td>
              <select [(ngModel)]="object.stock_ManagePrinters">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage order device</td>
            <td>
              <select [(ngModel)]="object.stock_ManageOrdersDevices">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage porter devices</td>
            <td>
              <select [(ngModel)]="object.stock_ManagePorterDevices">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage clients</td>
            <td>
              <select [(ngModel)]="object.stock_ManageClients">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage suppliers</td>
            <td>
              <select [(ngModel)]="object.stock_ManageSuppliers">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage employee</td>
            <td>
              <select [(ngModel)]="object.stock_ManageEmployees">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage stock</td>
            <td>
              <select [(ngModel)]="object.stock_ManageStock">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage news</td>
            <td>
              <select [(ngModel)]="object.stock_ManageNews">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Create orders</td>
            <td>
              <select [(ngModel)]="object.stock_CreateOrders">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage orders</td>
            <td>
              <select [(ngModel)]="object.stock_ManageOrders">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Cancel orders</td>
            <td>
              <select [(ngModel)]="object.stock_CancelOrders">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Notify clients</td>
            <td>
              <select [(ngModel)]="object.stock_NotifyClients">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Notify suppliers</td>
            <td>
              <select [(ngModel)]="object.stock_NotifySuppliers">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Notify employee</td>
            <td>
              <select [(ngModel)]="object.stock_NotifyEmployees">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage shop closures (stock only)</td>
            <td>
              <select [(ngModel)]="object.stock_ManageShopClosures">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage external payment services</td>
            <td>
              <select [(ngModel)]="object.stock_ManageMollieSetup">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage tokens</td>
            <td>
              <select [(ngModel)]="object.stock_ManageTokens">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td></td>
            <th align="left" class="table-sub-header">Screen permissions</th>
            <td></td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Edit playlists</td>
            <td>
              <select [(ngModel)]="object.screen_EditPlaylists">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage screens</td>
            <td>
              <select [(ngModel)]="object.screen_ManageScreens">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Remote control screens</td>
            <td>
              <select [(ngModel)]="object.screen_ControlScreens">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage shop closures (screen only)</td>
            <td>
              <select [(ngModel)]="object.screen_ManageShopClosures">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage external payment services</td>
            <td>
              <select [(ngModel)]="object.screen_ManageMollieSetup">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage tokens</td>
            <td>
              <select [(ngModel)]="object.screen_ManageTokens">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td></td>
            <th align="left" class="table-sub-header">Gallery permissions</th>
            <td></td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Edit galleries</td>
            <td>
              <select [(ngModel)]="object.gallery_EditGallery">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage shop closures (gallery only)</td>
            <td>
              <select [(ngModel)]="object.gallery_ManageShopClosures">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage external payment services</td>
            <td>
              <select [(ngModel)]="object.gallery_ManageMollieSetup">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>

          <tr>
            <td style="width: 64px"><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
            <td>Manage tokens</td>
            <td>
              <select [(ngModel)]="object.gallery_ManageTokens">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </ng-container>
</app-normal-popup>
