<app-left-side-container [iHaveMyHttpHandler]="this">
  <ng-container *ngIf="listObject != null">
    <div class="dashboard-item">
      <div>
        <h4 class="left">Employee</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="searchUserComponent.open(0, listObject.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="listObject.userShops != null">
        <ng-container *ngFor="let userShop of listObject.userShops">
          <div class="bordered-item hoverable" [ngClass]="{'selected': userShop == selectedUserShop}" (click)="selectUserShop(userShop)" (dblclick)="employeeEditor.open(1, null, userShop.id)">
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <p><strong><ng-container *ngIf="userShop.user != null">{{userShop.user.lastName}} {{userShop.user.firstName}}</ng-container></strong></p>
              <p>{{userShop.user.emails[0].email}}</p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img (click)="employeeEditor.open(1, null, userShop.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                <div class="image-button-box"><img (click)="employeeEditor.open(2, null, userShop.id)" class="image-button" src="assets/icons/delete_dark.png"></div>
              </div>
              <div class="dashboard-bordered-item-sub-right-bottom">
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>
  </ng-container>
</app-left-side-container>

<app-dashboard-search-user [employeeComponent]="this" [employeeEditorComponent]="employeeEditor"></app-dashboard-search-user>
<app-dashboard-shop-employee-editor [employeeComponent]="this"></app-dashboard-shop-employee-editor>
