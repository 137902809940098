<div>
  <div class="login-left">
    <div>
      <h3>BESTELMANDJE</h3>
      <p>www.bestelmandje.be</p>
    </div>
  </div>

  <div class="login-right">
    <div class="login-box">

      <div>
        <h3>Welcome</h3>
      </div>
      <div>
        <label>Email</label>
        <input type="email" placeholder="email" [(ngModel)]="email" autocomplete="email"/>
      </div>
      <div>
        <label>Password</label>
        <input type="password" [(ngModel)]="password" autocomplete="password"/>
      </div>

      <div>
        <button class="login-button" (click)="login()">LOGIN</button>
      </div>

      <div>
        <p>No account jet? <a href="/user/register">Click here to register!</a></p>
      </div>
    </div>
  </div>
</div>

<!-- <div class="login-box">
  <div class="full-width">
    <h3>LOGIN</h3>
  </div>
  <div class="full-width">
    <label>
      <input type="email" placeholder="email" [(ngModel)]="email" />
    </label>
    <label>
      <input type="password" placeholder="password" [(ngModel)]="password" />
    </label>
  </div>
  <div class="full-width">
    <button class="button button-blue" (click)="login()">LOGIN</button>
  </div>
  <div class="full-width">
    <p>No account jet? <a href="/user/register">Click here to register!</a></p>
  </div>
</div> -->
