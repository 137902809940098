<app-normal-popup [editorComponent]="this" title="variant">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Variant details</th>
        <td></td>
      </tr>

      <ng-container *ngIf="object.details != null">
        <ng-container *ngFor="let variantDetail of object.details">
          <tr>
            <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{variantDetail.languageCode}}-s.jpg" alt="Language illustration flag"></td>
            <td>Name</td>
            <td><input type="text" autocomplete="none" [(ngModel)]="variantDetail.name"></td>
          </tr>
        </ng-container>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Properties</th>
        <td></td>
      </tr>

      <tr>
        <td style="width: 64px"></td>
        <td>Zipcode</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.zipcode"></td>
      </tr>

      <tr>
        <td></td>
        <td>When below min.</td>
        <select [(ngModel)]="object.mode">
          <option value="C">Deliver with cost</option>
          <option value="D">Don't deliver</option>
        </select>
      </tr>

      <tr>
        <td></td>
        <td>Min. for free (cents)</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.minForFreeDelivery"></td>
      </tr>

      <tr>
        <td></td>
        <td>Deliver cost (cents)</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.deliveryCost"></td>
      </tr>

      <tr>
        <td style="width: 64px"></td>
        <td>Deliver time (minutes)</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.minMinutesToCountOn"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Week days</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Monday</td>
        <td><input type="checkbox" [(ngModel)]="object.monday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Tuesday</td>
        <td><input type="checkbox" [(ngModel)]="object.tuesday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Wednesday</td>
        <td><input type="checkbox" [(ngModel)]="object.wednesday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Thursday</td>
        <td><input type="checkbox" [(ngModel)]="object.thursday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Friday</td>
        <td><input type="checkbox" [(ngModel)]="object.friday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Saturday</td>
        <td><input type="checkbox" [(ngModel)]="object.saturday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Sunday</td>
        <td><input type="checkbox" [(ngModel)]="object.sunday"/></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
