import {Component, Input} from '@angular/core';
import {ZonesComponent} from '../../zones.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {Table} from '../../../../../domain/menu/Table';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';
import {TableCreateObject} from '../TableCreateObject';

@Component({
  selector: 'app-dashboard-menu-create-table',
  templateUrl: './menu-table-editor.component.html',
  styleUrls: ['./menu-table-editor.component.css']
})
export class MenuTableEditor extends AbstractEditorComponent<TableCreateObject, Table> {
  @Input() zonesComponent: ZonesComponent;

  public image: string;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.zonesComponent.setMenuTableEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_TABLE;
  }

  protected getInitableComponent(): IInit {
    return this.zonesComponent;
  }

  protected getTheObject(): Table {
    return this.object.table;
  }
}
