import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {MenuDetails} from '../../../../../domain/menu/menuDetails';
import {AbstractSelectComponent} from './AbstractSelectComponent';
import {AbstractEditingSelectionComponent} from '../../AbstractEditingSelectionComponent';
import {Menu} from '../../../../../domain/menu/menu';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-select-menu',
  templateUrl: './select-menu.component.html',
  styleUrls: ['./select-menu.component.css']
})
export class SelectMenuComponent extends AbstractSelectComponent<Menu, Menu> {
  @Input() public editingSelectionComponent: AbstractEditingSelectionComponent<Menu, Menu>;

  constructor(http: HttpClient) {
    super(http);
  }

  protected getAbstractEditingSelectionComponent(): AbstractEditingSelectionComponent<Menu, Menu> {
    return this.editingSelectionComponent;
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_SELECTION;
  }

  protected getSelectedShopId(): string {
    return this.object.user.selectedMenuShopId;
  }

  protected getSelectionId(): string {
    return this.object.user.selectedMenuId;
  }

  protected getSelectedLanguageCode(): string {
    return this.object.user.selectedMenuLanguageCode;
  }

  public init(): void {
    this.editingSelectionComponent.setSelectComponent(this);
  }

  public getMenuDetail(menuDetails: MenuDetails[], languageCode: String): MenuDetails {
    for ( const menuDetail of menuDetails ) {
      if ( menuDetail.languageCode === languageCode ) {
        return menuDetail;
      }
    }

    return menuDetails[0];
  }

  protected getInitableComponent(): IInit {
    return null;
  }

  protected getTheObject(): Menu {
    return null;
  }
}
