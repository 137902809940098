<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Printers</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">

    <ng-container *ngIf="listObject != null">

      <div class="dashboard-item">
        <div>
          <h4 class="left">Pos tablets</h4>
          <div class="right">
          </div>
        </div>

        <ng-container *ngIf="listObject.posTablets != null">
          <ng-container *ngFor="let tablet of listObject.posTablets">
            <div class="bordered-item hoverable" [ngClass]="{'selected': tablet == selectedTablet}" (click)="selectTablet(tablet)" (dblclick)="tabletPrinterEditorComponent.open(1, null, tablet.id)">
              <div class="dashboard-bordered-item-sub-left width-img-4buttons">
                <p><strong><ng-container *ngIf="tablet.details != null && tablet.details.length > 0">{{tablet.details[0].name}}</ng-container></strong></p>
              </div>
              <div class="dashboard-bordered-item-sub-right">
                <div class="dashboard-bordered-item-sub-right-top">
                  <div class="image-button-box"><img *ngIf="tablet.sequence > 0" (click)="moveUp(tablet, getTabletBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                  <div class="image-button-box"><img *ngIf="tablet.sequence < listObject.posTablets.length - 1" (click)="moveDown(tablet, getTabletBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                  <div class="image-button-box"><img (click)="downloadAndCopySetup(tablet.id)" class="image-button" src="assets/icons/dark/streamline-icon-interface-download-laptop@32x32.png"></div>
                  <div class="image-button-box"><img (click)="tabletEditorComponent.open(1, null, tablet.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

      </div>
    </ng-container>

    <div>
      <app-trailer></app-trailer>
    </div>

  </div>

  <div class="menu-half-1-content">
    <div>
      <ng-container *ngIf="selectedTablet != null">

        <div class="dashboard-title-item">
          <div class="dashboard-bordered-item-sub-left">
            <h4>Printers</h4>
          </div>
          <div class="dashboard-bordered-item-sub-right">
            <img (click)="tabletPrinterEditorComponent.open(0, selectedTablet.id, null)" class="image-button" src="assets/icons/add.png">
          </div>
        </div>

        <ng-container *ngIf="selectedTablet.tabletPrinters != null">
          <ng-container *ngFor="let tabletPrinter of selectedTablet.tabletPrinters">
            <div class="bordered-item hoverable" (dblclick)="tabletPrinterEditorComponent.open(1, null, tabletPrinter.id)">
              <div class="dashboard-bordered-item-sub-left">
                <p><strong>{{tabletPrinter.printer.details[0].name}}</strong></p>
                <p>{{tabletPrinter.printer.ip}}:{{tabletPrinter.printer.port}} ({{tabletPrinter.printer.format}})</p>
              </div>
              <div class="dashboard-bordered-item-sub-right">
                <div class="dashboard-bordered-item-sub-right-top">
                  <div class="image-button-box"><img *ngIf="tabletPrinter.sequence > 0" (click)="moveUp(tabletPrinter, tabletPrinterEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                  <div class="image-button-box"><img *ngIf="tabletPrinter.sequence < selectedTablet.tabletPrinters.length - 1" (click)="moveDown(tabletPrinter, tabletPrinterEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                  <div class="image-button-box"><img (click)="tabletPrinterEditorComponent.open(1, null, tabletPrinter.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                  <div class="image-button-box"><img class="image-button" (click)="tabletPrinterEditorComponent.open(2, null, tabletPrinter.id)" src="assets/icons/delete_dark.png"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

      </ng-container>
    </div>
    <app-trailer></app-trailer>
  </div>

  <div>
    <app-dashboard-pos-update-tablet [tabletsComponent]="this"></app-dashboard-pos-update-tablet>
    <app-dashboard-pos-create-tablet-printer [tabletsComponent]="this"></app-dashboard-pos-create-tablet-printer>
  </div>
</div>

<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
