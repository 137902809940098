import {Component, Input, OnInit} from '@angular/core';
import {CatalogPromotionComponent} from '../catalog-promotion.component';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {HttpClient} from '@angular/common/http';
import {IInit} from '../../../abstract/IInit';
import {Defaults} from '../../../../constants/defaults';
import {DateTimeUtil} from '../../../DateTimeUtil/DateTimeUtil';
import {CatalogPromotion} from '../../../../domain/stock/CatalogPromotion';

@Component({
  selector: 'app-dashboard-catalog-promotion-editor',
  templateUrl: './catalog-promotion-editor.component.html',
  styleUrls: ['./catalog-promotion-editor.component.css']
})
export class CatalogPromotionEditorComponent extends AbstractEditorComponent<CatalogPromotion, CatalogPromotion>{
  @Input() promotionComponent: CatalogPromotionComponent;

  public from: string;
  public till: string;

  constructor(http: HttpClient) {
    super(http)
  }

  public init(): void {
    this.promotionComponent.setCatalogPromotionEditorComponent(this);
  }

  protected getObjectDone() {
    this.from = DateTimeUtil.customToIso(this.object.validFrom);
    this.till = DateTimeUtil.customToIso(this.object.validTill);
  }

  protected preSubmit() {
    this.object.validFrom = DateTimeUtil.isoToCustom(this.from);
    this.object.validTill = DateTimeUtil.isoToCustom(this.till);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_PROMOTION;
  }

  protected getTheObject(): CatalogPromotion {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.promotionComponent;
  }

}
