<div class="loader-background" *ngIf="isOpen"></div>
<div class="loader" *ngIf="isOpen">
  <div class="loader-1"></div>
  <div class="loader-2"></div>
</div>

<div class="popup-background" [ngClass]="{'popup-open': errorIsOpen}"></div>
<div class="popup-window" [ngClass]="{'popup-open': errorIsOpen, 'popup-window-message': isMessage(), 'popup-window-warning': isWarning(), 'popup-window-error': isError()}">
  <div class="popup-window-header" [ngClass]="{'popup-open': errorIsOpen, 'popup-window-header-message': isMessage(), 'popup-window-header-warning': isWarning(), 'popup-window-header-error': isError()}">
    <h3 class="popup-window-header-title"><ng-container *ngIf="isMessage()">Message</ng-container><ng-container *ngIf="isWarning()">Oeps</ng-container><ng-container *ngIf="isError()">Error</ng-container></h3>
    <img class="popup-window-header-button-close" src="assets/icons/close.png" (click)="close()" title="Close popup"/>
  </div>
  <div class="popup-window-main">
    <p>{{errorMessage}}</p>
  </div>
  <div class="popup-window-footer">
    <div class="button-box-right">
      <button class="button" (click)="close()">CLOSE</button>
    </div>
  </div>
</div>
