import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Defaults} from '../constants/defaults';

export class HttpHandler {

  public static getSessionHeaders(): HttpHeaders {
    return new HttpHeaders({
      sessionId: Defaults.getSessionId(),
      sessionToken: Defaults.getSessionToken()
    });
  }

  public HttpHandler() {

  }

}
