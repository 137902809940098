import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GoogleChartsModule} from 'angular-google-charts';

import {MenuComponent} from './dashboard/menu/menu.component';
import {LoginComponent} from './dashboard/user/login/login.component';
import {RegisterComponent} from './dashboard/user/register/register.component';
import {EditComponent} from './dashboard/menu/edit/edit.component';
import {HeaderComponent} from './dashboard/header/header.component';
import {MenuNavigationComponent} from './dashboard/menu/navigation/navigation.component';
import {EmptyComponent} from './dashboard/menu/empty/empty.component';
import {ShopsComponent} from './dashboard/shops/shops.component';
import {StockComponent} from './dashboard/stock/stock.component';
import {ScreenComponent} from './dashboard/screen/screen.component';
import {ProfileComponent} from './dashboard/profile/profile.component';
import {SupplementsComponent} from './dashboard/menu/supplements/supplements.component';
import {CondimentsComponent} from './dashboard/menu/condiments/condiments.component';
import {AllergensComponent} from './dashboard/menu/allergens/allergens.component';
import {AvailabilityComponent} from './dashboard/menu/availability/availability.component';
import {ZonesComponent} from './dashboard/menu/zones/zones.component';
import {ImagesComponent} from './dashboard/menu/images/images.component';
import {MenuCategoryEditorComponent} from './dashboard/menu/edit/createcategory/menu-category-editor.component';
import {ShopsNavigationComponent} from './dashboard/shops/navigation/navigation.component';
import {MenuProductEditorComponent} from './dashboard/menu/edit/createproduct/menu-product-editor.component';
import {TrailerComponent} from './dashboard/trailer/trailer.component';
import {NewComponent} from './dashboard/shops/new/new.component';
import {MenuEditorComponent} from './dashboard/menu/editing-menu/popups/create-menu/menu-editor.component';
import {MenuSupplementEditor} from './dashboard/menu/supplements/create-supplement/menu-supplement-editor.component';
import {MymoneyComponent} from './dashboard/mymoney/mymoney.component';
import {MyMoneyNavigationComponent} from './dashboard/mymoney/navigation/navigation.component';
import {QrComponent} from './dashboard/menu/qr/qr.component';
import {MenuTabletsComponent} from './dashboard/menu/tablets/tablets.component';
import {MenuZoneEditor} from './dashboard/menu/zones/create-zone/menu-zone-editor.component';
import {MenuTableEditor} from './dashboard/menu/zones/tables/create-table/menu-table-editor.component';
import {MenuQrConfigEditor} from './dashboard/menu/qr/create-qr/menu-qr-config-editor.component';
import {GalleryComponent} from './dashboard/gallery/gallery.component';
import {GalleryNavigationComponent} from './dashboard/gallery/navigation/navigation.component';
import {TokensComponent} from './dashboard/gallery/tokens/tokens.component';
import {GalleryEditComponent} from './dashboard/gallery/gallery/edit.component';
import {CreateAlbumComponent} from './dashboard/gallery/gallery/subpanels/create-album/create-album.component';
import {PopupWindowComponent} from './ng-components/popup-window/popup-window.component';
import {CreateImageComponent} from './dashboard/gallery/gallery/subpanels/create-image/create-image.component';
import {StockNavigationComponent} from './dashboard/stock/navigation/navigation.component';
import {SuppliersComponent} from './dashboard/stock/suppliers/suppliers.component';
import {ClientsComponent} from './dashboard/stock/clients/clients.component';
import {DeleteCatalogComponent} from './dashboard/stock/select/popups/delete-catalog/delete-catalog.component';
import {CatalogEditComponent} from './dashboard/stock/edit/edit.component';
import {CatalogCategoryEditorComponent} from './dashboard/stock/edit/popups/category/create-catalog-category/catalog-category-editor.component';
import {CatalogProductEditorComponent} from './dashboard/stock/edit/popups/product/create-catalog-product/catalog-product-editor.component';
import {MenuProductSupplementEditor} from './dashboard/menu/edit/popups/add-supplement/menu-product-supplement-editor.component';
import {menuCondimentEditor} from './dashboard/menu/condiments/subpanels/create-condiment/menu-condiment-editor.component';
import {MenuProductCondimentEditor} from './dashboard/menu/edit/popups/add-condiment/menu-product-condiment-editor.component';
import {MenuProductExtraEditor} from './dashboard/menu/edit/popups/add-extra/menu-product-extra-editor.component';
import {MenuProductPromotionEditor} from './dashboard/menu/edit/popups/add-promotion/add-promotion.component';
import {VariantsComponent} from './dashboard/menu/variants/variants.component';
import {MenuVariantEditor} from './dashboard/menu/variants/subpanels/create-variant/menu-variant-editor.component';
import {VariantOptionEditorComponent} from './dashboard/menu/variants/subpanels/create-option/variant-option-editor.component';
import {MenuProductVariantEditor} from './dashboard/menu/edit/subpanels/options/add-option/menu-product-variant-editor.component';
import {PosStatisticsComponent} from './dashboard/menu/statistics/statistics.component';
import {PosTabletsComponent} from './dashboard/menu/pos-tablets/tablets.component';
import {PosPrintersComponent} from './dashboard/menu/pos-printers/printers.component';
import {DeliveryComponent} from './dashboard/menu/delivery/delivery.component';
import {MenuDeliveryEditor} from './dashboard/menu/delivery/create-delivery/menu-delivery-editor.component';
import {MyHttpHandlerComponent} from './dashboard/my-http-handler/my-http-handler.component';
import {MenuPosPrintZoneEditor} from './dashboard/menu/pos-printers/popups/create-print-zone/menu-pos-print-zone-editor.component';
import {MenuPosPrinterEditor} from './dashboard/menu/pos-printers/popups/create-printer/menu-pos-printer-editor.component';
import {MenuPosTabletEditorComponent} from './dashboard/menu/pos-tablets/popups/update-tablet/menu-pos-tablet-editor.component';
import {MenuTabletPrinterEditorComponent} from './dashboard/menu/pos-tablets/popups/create-tablet-printer/menu-tablet-printer-editor.component';
import {ShopEditorComponent} from './dashboard/shops/shop/update-shop-component/shop-editor.component';
import {MenuSupplementProductEditor} from './dashboard/menu/supplements/popups/create-supplement-product/menu-supplement-product-editor.component';
import {CopyRelationsComponent} from './dashboard/menu/edit/popups/copy-relations/copy-relations.component';
import {EditingMenuComponent} from './dashboard/menu/editing-menu/editing-menu.component';
import {SelectMenuComponent} from './dashboard/menu/editing-menu/popups/select-menu/select-menu.component';
import {CatalogProductImageEditorComponent} from './dashboard/stock/edit/popups/productImage/create-product-image/product-image-editor.component';
import {EditingCatalogComponent} from './dashboard/stock/editing-catalog/editing-catalog.component';
import {SelectCatalogComponent} from './dashboard/stock/editing-catalog/popups/select-catalog/select-catalog.component';
import {CatalogEditorComponent} from './dashboard/stock/editing-catalog/popups/create-catalog/catalog-editor.component';
import {MenuPromotionComponent} from './dashboard/menu/promotion/menu-promotion.component';
import {MenuPromotionEditorComponent} from './dashboard/menu/promotion/promotion-editor/menu-promotion-editor.component';
import {MenuDiscountCouponComponent} from './dashboard/menu/discount-coupon/menu-discount-coupon.component';
import {MenuDiscountCouponEditorComponent} from './dashboard/menu/discount-coupon/discount-coupon-editor/menu-discount-coupon-editor.component';
import {CatalogPromotionComponent} from './dashboard/stock/promotion/catalog-promotion.component';
import {CatalogPromotionEditorComponent} from './dashboard/stock/promotion/promotion-editor/catalog-promotion-editor.component';
import {StockVariantComponent} from './dashboard/stock/variant/variant.component';
import {StockVariantEditorComponent} from './dashboard/stock/variant/variant-editor/variant-editor.component';
import {StockVariantOptionEditorComponent} from './dashboard/stock/variant/variant-option-editor/variant-option-editor.component';
import {CatalogProductVariantEditorComponent} from './dashboard/stock/edit/popups/productVariant/product-variant-editor/product-variant-editor.component';
import { CatalogProductPromotionEditorComponent } from './dashboard/stock/edit/popups/productPromotion/product-promotion-editor/product-promotion-editor.component';
import { NormalPopupComponent } from './dashboard/components/normal-popup/normal-popup.component';
import { PopupContentTestComponent } from './dashboard/components/popup-content-test/popup-content-test.component';
import { NewsComponent } from './dashboard/stock/news/news.component';
import { LeftSideContainerComponent } from './dashboard/components/left-side-container/left-side-container.component';
import { RightSideContainerComponent } from './dashboard/components/right-side-container/right-side-container.component';
import { CatalogNewsEditorComponent } from './dashboard/stock/news/news-editor/catalog-news-editor.component';
import { EmailTemplatesComponent } from './dashboard/stock/email-templates/email-templates.component';
import { EmailTemplateEditorComponent } from './dashboard/stock/email-templates/email-template-editor/email-template-editor.component';
import { CatalogNewsPropertyEditorComponent } from './dashboard/stock/news/news-property-editor/catalog-news-property-editor.component';
import { CatalogNewsProductEditorComponent } from './dashboard/stock/news/news-product-editor/catalog-news-product-editor.component';
import { CatalogNewsVariantEditorComponent } from './dashboard/stock/news/news-variant-editor/catalog-news-variant-editor.component';
import { CatalogNewsVariantOptionEditorComponent } from './dashboard/stock/news/news-variant-option-editor/catalog-news-variant-option-editor.component';
import { SelectionComponent } from './dashboard/shops/selection/selection.component';
import { SelectShopComponent } from './dashboard/shops/selection/popups/select-shop/select-shop.component';
import { SelectShopComponent1} from './dashboard/shop/select/select-shop.component';
import { EmployeeComponent } from './dashboard/shops/employee/employee.component';
import { EmployeeEditorComponent } from './dashboard/shops/employee/employee-editor/employee-editor.component';
import { SearchUserComponent } from './dashboard/shops/employee/search-user/search-user.component';
import { LanguagesComponent } from './dashboard/shops/languages/languages.component';
import { ShopLanguageEditorComponent } from './dashboard/shops/languages/shop-language-editor/shop-language-editor.component';
import { GeneralComponent } from './dashboard/shops/general/general.component';
import { MenuClosureEditorComponent } from './dashboard/shops/menuClosure/menu-closure-editor/menu-closure-editor.component';
import { MenuClosureComponent } from './dashboard/shops/menuClosure/menu-closure.component';
import { CatalogEmailSubscriptionsComponent } from './dashboard/stock/email-subscriptions/catalog-email-subscriptions.component';
import { CatalogEmailSubscriptionEditorComponent } from './dashboard/stock/email-subscriptions/email-subscription-editor/catalog-email-subscription-editor.component';
import { CatalogEmailConfigurationsComponent } from './dashboard/stock/email-configurations/catalog-email-configurations.component';
import { CatalogEmailConfigurationEditorComponent } from './dashboard/stock/email-configurations/catalog-email-configuration-editor/catalog-email-configuration-editor.component';
import { CatalogImageComponent } from './dashboard/stock/image/catalog-image.component';
import { CatalogImageEditorComponent } from './dashboard/stock/image/catalog-image-editor/catalog-image-editor.component';
import { CatalogNewsImageEditorComponent } from './dashboard/stock/news/news-image-editor/catalog-news-image-editor.component';
import { EditingGalleryComponent } from './dashboard/gallery/editing-gallery/editing-gallery.component';
import { GalleryEditorComponent } from './dashboard/gallery/editing-gallery/gallery-editor/gallery-editor.component';
import { SelectGalleryComponent } from './dashboard/gallery/editing-gallery/select-gallery/select-gallery.component';
import { NewsChannelComponent } from './dashboard/stock/news/news-channel/news-channel.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LoginComponent,
    RegisterComponent,

    // Dashboard --- > Shops
    ShopsNavigationComponent,

    // Dashboard --- > Menu
    EditComponent,
    SupplementsComponent,
    CondimentsComponent,
    AllergensComponent,
    AvailabilityComponent,
    ZonesComponent,
    ImagesComponent,

    // Other
    HeaderComponent,
    MenuNavigationComponent,
    EmptyComponent,
    ShopsComponent,
    StockComponent,
    StockNavigationComponent,
    ScreenComponent,
    ProfileComponent,
    MenuCategoryEditorComponent,
    MenuProductEditorComponent,
    TrailerComponent,
    NewComponent,
    MenuEditorComponent,
    MenuSupplementEditor,
    MymoneyComponent,
    MyMoneyNavigationComponent,
    QrComponent,
    MenuTabletsComponent,
    MenuZoneEditor,
    MenuTableEditor,
    MenuQrConfigEditor,

    // Dashboard -> Menu
    GalleryComponent,
    GalleryNavigationComponent,
    TokensComponent,
    GalleryEditComponent,
    CreateAlbumComponent,
    PopupWindowComponent,
    CreateImageComponent,
    CatalogEditComponent,
    SuppliersComponent,
    ClientsComponent,
    DeleteCatalogComponent,
    CatalogCategoryEditorComponent,
    CatalogProductEditorComponent,
    MenuProductSupplementEditor,
    menuCondimentEditor,
    MenuProductCondimentEditor,
    MenuProductExtraEditor,
    MenuProductPromotionEditor,
    VariantsComponent,
    MenuVariantEditor,
    VariantOptionEditorComponent,
    MenuProductVariantEditor,

    // Dashboard -> Stock
    CatalogEditorComponent,

    // Dashboard -> Pos
    PosStatisticsComponent,
    PosTabletsComponent,
    PosPrintersComponent,
    DeliveryComponent,
    MenuDeliveryEditor,
    MyHttpHandlerComponent,
    MenuPosPrintZoneEditor,
    MenuPosPrinterEditor,
    MenuPosTabletEditorComponent,
    MenuTabletPrinterEditorComponent,
    ShopEditorComponent,
    MenuSupplementProductEditor,
    CopyRelationsComponent,
    EditingMenuComponent,
    SelectMenuComponent,
    SelectCatalogComponent,
    CatalogProductImageEditorComponent,
    EditingCatalogComponent,
    SelectShopComponent,
    SelectShopComponent1,
    MenuPromotionComponent,
    MenuPromotionEditorComponent,
    MenuDiscountCouponComponent,
    MenuDiscountCouponEditorComponent,
    CatalogPromotionComponent,
    CatalogPromotionEditorComponent,
    StockVariantComponent,
    StockVariantEditorComponent,
    StockVariantOptionEditorComponent,
    CatalogProductVariantEditorComponent,
    CatalogProductPromotionEditorComponent,
    NormalPopupComponent,
    PopupContentTestComponent,
    NewsComponent,
    LeftSideContainerComponent,
    RightSideContainerComponent,
    CatalogNewsEditorComponent,
    EmailTemplatesComponent,
    EmailTemplateEditorComponent,
    CatalogNewsPropertyEditorComponent,
    CatalogNewsProductEditorComponent,
    CatalogNewsVariantEditorComponent,
    CatalogNewsVariantOptionEditorComponent,
    SelectionComponent,
    EmployeeComponent,
    EmployeeEditorComponent,
    SearchUserComponent,
    LanguagesComponent,
    ShopLanguageEditorComponent,
    GeneralComponent,
    MenuClosureEditorComponent,
    MenuClosureComponent,
    CatalogEmailSubscriptionsComponent,
    CatalogEmailSubscriptionEditorComponent,
    CatalogEmailConfigurationsComponent,
    CatalogEmailConfigurationEditorComponent,
    CatalogImageComponent,
    CatalogImageEditorComponent,
    CatalogNewsImageEditorComponent,
    EditingGalleryComponent,
    GalleryEditorComponent,
    SelectGalleryComponent,
    NewsChannelComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleChartsModule
  ],
  exports: [
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
