import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Defaults} from 'src/app/constants/defaults';
import {Product} from 'src/app/domain/menu/Product';
import {Category} from 'src/app/domain/menu/category';
import {User} from 'src/app/domain/user/user';
import {Menu} from 'src/app/domain/menu/menu';
import {MenuComponent} from 'src/app/dashboard/menu/menu.component';
import {MenuCategoryEditorComponent} from 'src/app/dashboard/menu/edit/createcategory/menu-category-editor.component';
import {MenuProductEditorComponent} from 'src/app/dashboard/menu/edit/createproduct/menu-product-editor.component';
import {MenuProductSupplementEditor} from './popups/add-supplement/menu-product-supplement-editor.component';
import {HttpHandler} from '../../../handlers/httpHandler';
import {MenuProductCondimentEditor} from './popups/add-condiment/menu-product-condiment-editor.component';
import {MenuProductExtraEditor} from './popups/add-extra/menu-product-extra-editor.component';
import {MenuProductVariantEditor} from './subpanels/options/add-option/menu-product-variant-editor.component';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {CopyRelationsComponent} from './popups/copy-relations/copy-relations.component';
import {MenuSubComponent} from '../MenuSubComponent';
import {IInit} from '../../abstract/IInit';
import {MenuProductPromotionEditor} from './popups/add-promotion/add-promotion.component';
import {DoubleSortableObjectPanel} from '../../DoubleSortableObjectPanel';

@Component({
  selector: 'app-dashboard-menu-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent extends DoubleSortableObjectPanel<Menu, Category> implements MenuSubComponent, OnChanges, IInit {
  @Input() menuComponent: MenuComponent;
  @Input() public parameter: string;

  public SERVER_CONTENT = Defaults.SERVER_CONTENT;
  public imageRoot = Defaults.SERVER_API_MENU_PRODUCT;

  public categoryEditorComponent: MenuCategoryEditorComponent;
  public productEditorComponent: MenuProductEditorComponent;

  public productVariantEditor: MenuProductVariantEditor;
  public productSupplementEditor: MenuProductSupplementEditor;
  public productCondimentEditor: MenuProductCondimentEditor;
  public productExtraEditor: MenuProductExtraEditor;
  public productPromotionEditor: MenuProductPromotionEditor;

  public copyRelationsComponent: CopyRelationsComponent;

  public menu: Menu;
  public category: Category;
  public categoryRoot: Category[];

  public categories: Category[];
  public selectedCategory: Category;

  public products: Product[];
  public selectedProduct: Product;

  public orderBy: string = 'M';

  public posts: User[];

  constructor(private route: ActivatedRoute, private router: Router, http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);

    if (this.parameter === 'root'){
      this.getMenu();
    }
    else{
      this.getCategory();
      this.getCategoryRoot();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parameter) {
      this.init();
    }
  }

  public categoryOrderChanged(): void {
    this.init();
  }

  private reset(): void {
    this.menu = null;
    this.category = null;
    this.categories = null;
    this.products = null;
    this.selectedCategory = null;
    this.selectedProduct = null;
  }

  public getMenu(): void {
    if ( this.myHttpHandlerComponent == null ) {
      return;
    }

    const headers = new HttpHeaders({
      sessionId: Defaults.getSessionId(),
      sessionToken: Defaults.getSessionToken()
    });

    const observable = this.http.get<Menu>(Defaults.SERVER_API_MENU + 'editingRootObject/' + this.orderBy, {headers});

    this.myHttpHandlerComponent.get<Menu>(observable, (result: Menu) => {
      this.menu = result;
      this.category = null;
      this.categories = result.categories;
      this.products = null;
    });
  }

  public getCategory(): void {
    if ( this.myHttpHandlerComponent == null ) {
      return;
    }

    const headers = HttpHandler.getSessionHeaders();

    const observable = this.http.get<Category>(Defaults.SERVER_API_MENU_CATEGORY + 'inEditingLanguage/' + this.parameter + '/' + this.orderBy, {headers});

    this.myHttpHandlerComponent.get<Category>(observable, (result: Category) => {
      this.menu = null;
      this.category = result;
      this.categories = result.categories;
      this.products = result.products;

      if (this.selectedProduct != null) {
        for (const p of this.products) {
          if (p.id === this.selectedProduct.id) {
            this.selectedProduct = p;
          }
        }
      }
    });
  }

  public getCategoryRoot(): void {
    const headers = new HttpHeaders({
      sessionId: Defaults.getSessionId(),
      sessionToken: Defaults.getSessionToken()
    });

    this.http.get<Category[]>(Defaults.SERVER_API_MENU_CATEGORY + 'findRoot/' + this.parameter, {headers})
      .subscribe(
        (result: Category[]) => {
          this.categoryRoot = result;
        },
        error => {
        },
        () => {
        });
  }

  public selectCategory(category: Category): void {
    this.selectedCategory = category;
  }

  public openCategory(categoryId: string): void {
    this.reset();
    this.menuComponent.setParameter(categoryId);
  }

  public selectProduct(product: Product): void {
    this.selectedProduct = product;
  }

  public dragEnterImage(event): void {
    event.preventDefault();
    event.target.classList.add('table-menu-image-dropzone-active');
  }

  public dragOverImage(event): void {
    event.preventDefault();
  }

  public dragLeaveImage(event): void {
    event.preventDefault();
    event.target.classList.remove('table-menu-image-dropzone-active');
  }

  public dropImage(event): void {
    event.preventDefault();
    event.target.classList.remove('table-menu-image-dropzone-active');

    if (event.dataTransfer == null || event.dataTransfer.files == null || event.dataTransfer.files.length !== 1){
      alert('Expected exacly one file to be dropped!');
      return;
    }

    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const file = event.dataTransfer.files[0];

    if (file == null || allowedTypes.indexOf(file.type) < 0){
      alert('File type not supported!');
      return;
    }

    console.log(file);
  }

  public setCategoryEditorComponent(categoryEditorComponent: MenuCategoryEditorComponent): void {
    this.categoryEditorComponent = categoryEditorComponent;
  }

  public setProductEditorComponent(productEditorComponent: MenuProductEditorComponent): void {
    this.productEditorComponent = productEditorComponent;
  }


  public setMenuProductVariantEditor(productVariantEditor: MenuProductVariantEditor): void {
    this.productVariantEditor = productVariantEditor;
  }

  public setMenuProductSupplementEditor(productSupplementEditor: MenuProductSupplementEditor): void {
    this.productSupplementEditor = productSupplementEditor;
  }

  public setMenuProductCondimentEditor(productCondimentEditor: MenuProductCondimentEditor): void {
    this.productCondimentEditor = productCondimentEditor;
  }

  public setMenuProductExtraEditor(productExtraEditor: MenuProductExtraEditor): void {
    this.productExtraEditor = productExtraEditor;
  }

  public setMenuProductPromotionEditor(productPromotionEditor: MenuProductPromotionEditor): void {
    this.productPromotionEditor = productPromotionEditor;
  }


  public setCopyRelationsComponent(copyRelationsComponent: CopyRelationsComponent): void {
    this.copyRelationsComponent = copyRelationsComponent;
  }

  public getCategoryBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_CATEGORY;
  }

  public getProductBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT;
  }

  public getProductVariantBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT_VARIANT;
  }

  public getProductSupplementBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT_SUPPLEMENT;
  }

  public getProductCondimentBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT_CONDIMENT;
  }

  public getProductExtraBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT_EXTRA;
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_CATEGORY;
  }
}
