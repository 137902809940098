import {Component, Input, OnInit} from '@angular/core';
import {StockComponent} from '../stock.component';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {CatalogEmailConfiguration} from '../../../domain/stock/CatalogEmailConfiguration';
import {Catalog} from '../../../domain/stock/Catalog';
import {CatalogEmailConfigurationEditorComponent} from './catalog-email-configuration-editor/catalog-email-configuration-editor.component';

@Component({
  selector: 'app-dashboard-stock-email-configurations',
  templateUrl: './catalog-email-configurations.component.html',
  styleUrls: ['./catalog-email-configurations.component.css']
})
export class CatalogEmailConfigurationsComponent extends SortableObjectPanel<Catalog, CatalogEmailConfiguration>{
  @Input() stockComponent: StockComponent;

  public emailConfigurationEditorComponent: CatalogEmailConfigurationEditorComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.stockComponent.setSubComponent(this);

    super.init();
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_EMAIL_CONFIGURATION;
  }

  public setCatalogEmailConfigurationEditorComponent(emailConfigurationEditorComponent: CatalogEmailConfigurationEditorComponent): void {
    this.emailConfigurationEditorComponent = emailConfigurationEditorComponent;
  }

}
