import {Component, Input, OnInit} from '@angular/core';
import {EditComponent} from 'src/app/dashboard/menu/edit/edit.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Defaults} from 'src/app/constants/defaults';
import {Product} from 'src/app/domain/menu/Product';
import {HttpHandler} from '../../../../handlers/httpHandler';
import {ProductImage} from '../../../../domain/menu/ProductImage';
import {IHaveMyHttpHandler} from '../../../my-http-handler/IHaveMyHttpHandler';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-product-editor',
  templateUrl: './menu-product-editor.component.html',
  styleUrls: ['./menu-product-editor.component.css']
})
export class MenuProductEditorComponent extends AbstractEditorComponent<Product, Product> {
  @Input() editComponent: EditComponent;
  @Input() menuCategoryId: number;

  public imageRoot = Defaults.SERVER_API_MENU_PRODUCT;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setProductEditorComponent(this);
  }

  // TODO image editor super class

  public onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      let headers = HttpHandler.getSessionHeaders();

      this.myHttpHandlerComponent.get<ProductImage>(this.http.get<ProductImage>(Defaults.SERVER_API_MENU_PRODUCT + 'image/createNew/' + this.object.id, { headers }), (productImage: ProductImage) => {
        const formDate = new FormData();
        formDate.append('file', file);

        headers = new HttpHeaders({
          sessionId: Defaults.getSessionId(),
          sessionToken: Defaults.getSessionToken(),
          productImageId: productImage.id,
          productId: this.object.id,
        });

        this.myHttpHandlerComponent.get<ProductImage>(this.http.post<ProductImage>(Defaults.SERVER_API_MENU_PRODUCT + 'image/upload', formDate, { headers }), (result: ProductImage) => {
          this.object.productImage = result;
          this.object.productImageId = result.id;
        });
      });
    }
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT;
  }

  protected getTheObject(): Product {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }
}
