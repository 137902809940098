import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../../constants/defaults';
import {CatalogEditComponent} from '../../../edit.component';
import {CatalogCategory} from '../../../../../../domain/stock/CatalogCategory';
import {AbstractEditorComponent} from '../../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-catalog-category-editor',
  templateUrl: './catalog-category-editor.component.html',
  styleUrls: ['./catalog-category-editor.component.css']
})
export class CatalogCategoryEditorComponent extends AbstractEditorComponent<CatalogCategory, CatalogCategory> {
  @Input() public editComponent: CatalogEditComponent;
  @Input() public categoryId: string;

  constructor(private router: Router, http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setCatalogCategoryEditorComponent(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_CATEGORY;
  }

  protected getTheObject(): CatalogCategory {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }
}
