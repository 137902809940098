<app-left-side-container [iHaveMyHttpHandler]="this">
  <ng-container *ngIf="listObject != null && listObject.news != null">

    <div class="dashboard-item">
      <div>
        <h4 class="left">News</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="newsEditorComponent.open(0, listObject.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="listObject.news != null">
        <ng-container *ngFor="let news of listObject.news">
          <div class="bordered-item hoverable" [ngClass]="{'selected': news == selectedNews}" (click)="selectNews(news)" (dblclick)="newsEditorComponent.open(1, null, news.id)">
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <p><strong><ng-container *ngIf="news.details != null && news.details.length > 0">{{news.details[0].name}}</ng-container></strong></p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img *ngIf="news.sequence > 0" (click)="moveUp(news, getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="news.sequence < listObject.news.length - 1" (click)="moveDown(news, getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img (click)="newsEditorComponent.open(1, null, news.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                <div class="image-button-box"><img (click)="notifyClients(news)" class="image-button" src="assets/icons/dark/streamline-icon-send-email-envelope@32x32.png"></div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>

  </ng-container>
</app-left-side-container>

<app-right-side-container>
  <ng-container *ngIf="selectedNews != null">
    <div class="dashboard-item">
      <div>
        <h4 class="left">Properties</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="newsPropertyEditorComponent.open(0, selectedNews.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="selectedNews.newsProperties != null">
        <ng-container *ngFor="let newsProperty of selectedNews.newsProperties">
          <div class="bordered-item hoverable" (dblclick)="newsPropertyEditorComponent.open(1, null, newsProperty.id)">
            <div class="left">
              <strong>{{newsProperty.name}}: {{newsProperty.details[0].value}}</strong>
            </div>
            <div class="right">
              <div class="image-button-box"><img *ngIf="newsProperty.sequence > 0" (click)="moveUp(newsProperty, newsPropertyEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
              <div class="image-button-box"><img *ngIf="newsProperty.sequence < selectedNews.newsProperties.length - 1" (click)="moveDown(newsProperty, newsPropertyEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
              <div class="image-button-box"><img class="image-button" (click)="newsPropertyEditorComponent.open(1, null, newsProperty.id)" src="assets/icons/edit_dark.png"></div>
              <div class="image-button-box"><img class="image-button" (click)="newsPropertyEditorComponent.open(2, null, newsProperty.id)" src="assets/icons/delete_dark.png"></div>
            </div>
          </div>

        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedNews != null">
    <div class="dashboard-item">
      <div>
        <h4 class="left">Images</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="newsImageEditorComponent.open(0, selectedNews.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="selectedNews.newsImages != null">
        <ng-container *ngFor="let newsImage of selectedNews.newsImages">
          <div class="bordered-item hoverable" (dblclick)="newsImageEditorComponent.open(1, null, newsImage.id)">
            <div class="dashboard-bordered-item-sub-left">
              <div class="table-menu-image-container"><img class="table-menu-image" src="{{imageRoot}}get/96/4-4/{{newsImage.image.imageId}}.png"></div>
            </div>
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <strong>{{newsImage.image.title}} ({{newsImage.size}}px {{newsImage.aspectRatio}})</strong>
            </div>
            <div class="right">
              <div class="image-button-box"><img *ngIf="newsImage.sequence > 0" (click)="moveUp(newsImage, newsImageEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
              <div class="image-button-box"><img *ngIf="newsImage.sequence < selectedNews.newsImages.length - 1" (click)="moveDown(newsImage, newsImageEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
              <div class="image-button-box"><img class="image-button" (click)="newsImageEditorComponent.open(1, null, newsImage.id)" src="assets/icons/edit_dark.png"></div>
              <div class="image-button-box"><img class="image-button" (click)="newsImageEditorComponent.open(2, null, newsImage.id)" src="assets/icons/delete_dark.png"></div>
            </div>
          </div>

        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedNews != null">
    <div class="dashboard-item">
      <div>
        <h4 class="left">Products</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="newsProductEditorComponent.open(0, selectedNews.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="selectedNews.newsProducts != null">
        <ng-container *ngFor="let newsProduct of selectedNews.newsProducts">
          <div class="bordered-item hoverable" (dblclick)="newsProductEditorComponent.open(1, null, newsProduct.id)">
            <div class="left">
              <strong>{{newsProduct.product.details[0].name}}</strong>
            </div>
            <div class="right">
              <div class="image-button-box"><img *ngIf="newsProduct.sequence > 0" (click)="moveUp(newsProduct, newsProductEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
              <div class="image-button-box"><img *ngIf="newsProduct.sequence < selectedNews.newsProducts.length - 1" (click)="moveDown(newsProduct, newsProductEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
              <div class="image-button-box"><img class="image-button" (click)="newsProductEditorComponent.open(1, null, newsProduct.id)" src="assets/icons/edit_dark.png"></div>
              <div class="image-button-box"><img class="image-button" (click)="newsProductEditorComponent.open(2, null, newsProduct.id)" src="assets/icons/delete_dark.png"></div>
            </div>
          </div>

        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedNews != null">
    <div class="dashboard-item">
      <div>
        <h4 class="left">Variants</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="newsVariantEditorComponent.open(0, selectedNews.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="selectedNews.newsVariants != null">
        <ng-container *ngFor="let newsVariant of selectedNews.newsVariants">
          <div class="bordered-item hoverable" (dblclick)="newsVariantEditorComponent.open(1, null, newsVariant.id)">
            <div class="left">
              <strong>{{newsVariant.variant.details[0].name}}</strong>
            </div>
            <div class="right">
              <div class="image-button-box"><img *ngIf="newsVariant.sequence > 0" (click)="moveUp(newsVariant, newsVariantEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
              <div class="image-button-box"><img *ngIf="newsVariant.sequence < selectedNews.newsProducts.length - 1" (click)="moveDown(newsVariant, newsVariantEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
              <div class="image-button-box"><img class="image-button" (click)="newsVariantEditorComponent.open(1, null, newsVariant.id)" src="assets/icons/edit_dark.png"></div>
              <div class="image-button-box"><img class="image-button" (click)="newsVariantEditorComponent.open(2, null, newsVariant.id)" src="assets/icons/delete_dark.png"></div>
            </div>
          </div>

        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedNews != null">
    <div class="dashboard-item">
      <div>
        <h4 class="left">Variant options</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="newsVariantOptionEditorComponent.open(0, selectedNews.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="selectedNews.newsVariantOptions != null">
        <ng-container *ngFor="let newsVariantOption of selectedNews.newsVariantOptions">
          <div class="bordered-item hoverable" (dblclick)="newsVariantOptionEditorComponent.open(1, null, newsVariantOption.id)">
            <div class="left">
              <strong>{{newsVariantOption.variantOption.details[0].name}}</strong>
            </div>
            <div class="right">
              <div class="image-button-box"><img *ngIf="newsVariantOption.sequence > 0" (click)="moveUp(newsVariantOption, newsVariantOptionEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
              <div class="image-button-box"><img *ngIf="newsVariantOption.sequence < selectedNews.newsProducts.length - 1" (click)="moveDown(newsVariantOption, newsVariantOptionEditorComponent.getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
              <div class="image-button-box"><img class="image-button" (click)="newsVariantOptionEditorComponent.open(1, null, newsVariantOption.id)" src="assets/icons/edit_dark.png"></div>
              <div class="image-button-box"><img class="image-button" (click)="newsVariantOptionEditorComponent.open(2, null, newsVariantOption.id)" src="assets/icons/delete_dark.png"></div>
            </div>
          </div>

        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="selectedNews != null">
    <div class="dashboard-item">
      <div>
        <h4 class="left">Promotions</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="newsVariantOptionEditorComponent.open(0, selectedNews.id, null)" alt="Add Icon">
        </div>
      </div>
    </div>
  </ng-container>

</app-right-side-container>

<app-dashboard-catalog-news-editor [newsComponent]="this"></app-dashboard-catalog-news-editor>
<app-dashboard-catalog-news-channel [newsComponent]="this"></app-dashboard-catalog-news-channel>

<app-dashboard-catalog-news-property-editor [newsComponent]="this"></app-dashboard-catalog-news-property-editor>
<app-dashboard-catalog-news-image-editor [newsComponent]="this"></app-dashboard-catalog-news-image-editor>
<app-dashboard-catalog-news-product-editor [newsComponent]="this"></app-dashboard-catalog-news-product-editor>
<app-dashboard-catalog-news-variant-editor [newsComponent]="this"></app-dashboard-catalog-news-variant-editor>
<app-dashboard-catalog-news-variant-option-editor [newsComponent]="this"></app-dashboard-catalog-news-variant-option-editor>
