import {Component, Input, OnInit} from '@angular/core';
import {IHaveMyHttpHandler} from './IHaveMyHttpHandler';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {NormalPopupComponent} from '../components/normal-popup/normal-popup.component';
import {IMyHttpHandlerReceiver} from '../components/IMyHttpHandlerReceiver';

@Component({
  selector: 'app-my-http-handler',
  templateUrl: './my-http-handler.component.html',
  styleUrls: ['./my-http-handler.component.css']
})
export class MyHttpHandlerComponent implements OnInit {
  @Input() myHttpHandlerReceiver: IMyHttpHandlerReceiver;
  @Input() iHaveMyHttpHandler: IHaveMyHttpHandler;

  public isOpen = false;

  public errorMessage: string;
  public errorIsOpen = false;
  private status: number;

  constructor(private router: Router) { }

  ngOnInit(): void {
    if ( this.myHttpHandlerReceiver != null ) {
      this.myHttpHandlerReceiver.setMyHttpHandler(this);
    }
    if ( this.iHaveMyHttpHandler != null ) {
      this.iHaveMyHttpHandler.setMyHttpHandlerComponent(this);
    }
  }

  public get<T>(observable: Observable<T>, next: (value: T) => void): void {
    this.isOpen = true;

    observable.subscribe((result: T) => {
      this.isOpen = false;
      next(result);
    }, (error: HttpErrorResponse) => {
      if ( error.status === 401 ) {
        this.router.navigateByUrl('user/login'); //
      }
      else {
        console.error(error);
        this.showError('server', 'Error: ' + error.error.message, error.status, []);
      }

      this.isOpen = false;
    }, () => {

    });
  }

  public showError(source: string, errorMessage: string, status: number, errorDetails: string[]): void {
    this.errorMessage = errorMessage;
    this.errorIsOpen = true;
    this.status = status;

    if ( this.myHttpHandlerReceiver != null ) {
      this.myHttpHandlerReceiver.forceClose(true);
    }
    if ( this.iHaveMyHttpHandler != null ) {
      this.iHaveMyHttpHandler.forceClose(true);
    }
  }

  public close(): void {
    this.errorMessage = null;
    this.errorIsOpen = false;
    this.status = null;

    if ( this.myHttpHandlerReceiver != null ) {
      this.myHttpHandlerReceiver.forceClose(false);
    }
    if ( this.iHaveMyHttpHandler != null ) {
      this.iHaveMyHttpHandler.forceClose(false);
    }
  }

  public isMessage(): boolean {
    return this.status >= 100 && this.status < 200;
  }

  public isWarning(): boolean {
    return this.status >= 400 && this.status < 500;
  }

  public isError(): boolean {
    return this.status >= 500 && this.status < 600;
  }
}
