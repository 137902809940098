<app-normal-popup [editorComponent]="this" title="product -> promotion">
  <ng-container *ngIf="object != null && object.productPromotion != null && object.promotions != null && object.variants != null">
    <table>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Extra</th>
        <td></td>
      </tr>

      <tr>
        <td style="width: 64px"><img class="image-icon" src="assets/icons/condiment_light.png" alt="CONDIMENTS"></td>
        <td>Supplement</td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.productPromotion.promotionId">
            <option *ngFor="let promotion of object.promotions" ngValue="{{promotion.id}}">{{promotion.details[0].name}}</option>
          </select>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Condition</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/variants.png"></td>
        <td>Enabled by condition</td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.productPromotion.conditionEnabled">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

      <tr>
        <td align="right"></td>
        <td>Condition</td>
        <td><input type="text" [disabled]="mode === 2" [(ngModel)]="object.productPromotion.enablingCondition"></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
