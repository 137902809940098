import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Defaults} from '../../../constants/defaults';
import {HttpClient} from '@angular/common/http';
import {StockComponent} from '../stock.component';
import {Catalog} from '../../../domain/stock/Catalog';
import {CatalogCategory} from '../../../domain/stock/CatalogCategory';
import {CatalogCategoryEditorComponent} from './popups/category/create-catalog-category/catalog-category-editor.component';
import {CatalogProductEditorComponent} from './popups/product/create-catalog-product/catalog-product-editor.component';
import {HttpHandler} from '../../../handlers/httpHandler';
import {CatalogProduct} from '../../../domain/stock/CatalogProduct';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {CatalogProductImageEditorComponent} from './popups/productImage/create-product-image/product-image-editor.component';
import {IInit} from '../../abstract/IInit';
import {StockSubComponent} from '../suppliers/StockSubComponent';
import {CatalogProductVariantEditorComponent} from './popups/productVariant/product-variant-editor/product-variant-editor.component';
import {CatalogProductPromotionEditorComponent} from './popups/productPromotion/product-promotion-editor/product-promotion-editor.component';

@Component({
  selector: 'app-dashboard-catalog-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class CatalogEditComponent extends SortableObjectPanel<Catalog, CatalogCategory> implements StockSubComponent, OnChanges, IInit {
  @Input() stockComponent: StockComponent;
  @Input() pageIndex: number;
  @Input() parameter: string;

  public SERVER_CONTENT = Defaults.SERVER_STOCK_CONTENT;

  public catalogCategoryEditorComponent: CatalogCategoryEditorComponent;
  public catalogProductEditorComponent: CatalogProductEditorComponent;

  public productVariantEditor: CatalogProductVariantEditorComponent;
  public catalogProductImageEditorComponent: CatalogProductImageEditorComponent;
  public productPromotionEditor: CatalogProductPromotionEditorComponent;

  public catalog: Catalog;
  public category: CatalogCategory;

  public categories: CatalogCategory[];
  public products: CatalogProduct[];

  public selectedCategory: CatalogCategory;
  public selectedProduct: CatalogProduct;

  public categoryRoot: CatalogCategory[];

  constructor(http: HttpClient) {
    super(http);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parameter) {
      this.clear();
      this.init();
    }
  }

  public init(): void {
    this.stockComponent.setSubComponent(this);
    super.init();
  }

  private clear(): void {
    this.catalog = null;
    this.category = null;
    this.categories = null;
    this.products = null;
  }

  public setCatalogCategoryEditorComponent(catalogCategoryEditorComponent: CatalogCategoryEditorComponent): void {
    this.catalogCategoryEditorComponent = catalogCategoryEditorComponent;
  }

  public setCatalogProductEditorComponent(catalogProductEditorComponent: CatalogProductEditorComponent): void {
    this.catalogProductEditorComponent = catalogProductEditorComponent;
  }

  public setProductVariantEditor(productVariantEditor: CatalogProductVariantEditorComponent): void {
    this.productVariantEditor = productVariantEditor;
  }

  public setProductImageEditorComponent(catalogProductImageEditorComponent: CatalogProductImageEditorComponent): void {
    this.catalogProductImageEditorComponent = catalogProductImageEditorComponent;
  }

  public setProductPromotionEditor(productPromotionEditor: CatalogProductPromotionEditorComponent) {
    this.productPromotionEditor = productPromotionEditor;
  }


  protected getList() {
    if (this.parameter === 'root') {
      this.getEditingCatalog();
    } else {
      this.getCategory();
    }
  }

  private getEditingCatalog(): void {
    if ( this.myHttpHandlerComponent == null ) {
      return;
    }

    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<Catalog>(this.http.get<Catalog>(Defaults.SERVER_API_CATALOG + 'editingCatalog', { headers }), (result: Catalog) => {
      this.catalog = result;
      this.category = null;
      this.categories = result.categories;
      this.products = null;

      this.checkSelection();
    });
  }

  private getCategory(): void {
    if ( this.myHttpHandlerComponent == null ) {
      return;
    }

    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<CatalogCategory>(this.http.get<CatalogCategory>(Defaults.SERVER_API_CATALOG_CATEGORY + 'inEditingLanguage/' + this.parameter, { headers }), (result: CatalogCategory) => {
      this.catalog = null;
      this.category = result;
      this.categories = result.categories;
      this.products = result.products;

      this.checkSelection();
    });
  }

  private checkSelection() {
    if ( this.selectedCategory != null ){
      for ( const category of this.categories ) {
        if ( category.id === this.selectedCategory.id ) {
          this.selectCategory(category);
        }
      }
    }

    if ( this.selectedProduct != null ) {
      for ( const product of this.products ) {
        if ( product.id === this.selectedProduct.id ) {
          this.selectProduct(product);
        }
      }
    }
  }

  public openCategory(categoryId: string): void {
    this.stockComponent.setParameter(categoryId);
  }

  public selectCategory(category: CatalogCategory): void {
    this.selectedCategory = category;
  }

  public selectProduct(product: CatalogProduct): void {
    this.selectedProduct = product;
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_CATEGORY;
  }

  public getCatalogCategoryBaseEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_CATEGORY;
  }

  public getCatalogProductBaseEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_PRODUCT;
  }

  public getProductVariantBaseEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_PRODUCT_VARIANT;
  }

  public getProductPromotionBaseEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_PRODUCT_PROMOTION;
  }
}
