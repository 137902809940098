import {Component, Input, OnInit} from '@angular/core';
import {MenuComponent} from '../menu.component';

@Component({
  selector: 'app-dashboard-menu-tablets',
  templateUrl: './tablets.component.html',
  styleUrls: ['./tablets.component.css']
})
export class MenuTabletsComponent implements OnInit {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  constructor() { }

  ngOnInit(): void {
  }

}
