import {Component, Input, OnInit} from '@angular/core';
import {GalleryEditComponent} from '../../edit.component';
import {GalleryAlbum} from '../../../../../domain/gallery/GalleryAlbum';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpHandler} from '../../../../../handlers/httpHandler';
import {Defaults} from '../../../../../constants/defaults';
import {GalleryImage} from '../../../../../domain/gallery/GalleryImage';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';
import {UploadResult} from '../../../../stock/edit/popups/productImage/create-product-image/UploadResult';

@Component({
  selector: 'app-dashboard-gallery-create-image',
  templateUrl: './create-image.component.html',
  styleUrls: ['./create-image.component.css']
})
export class CreateImageComponent extends AbstractEditorComponent<GalleryImage, GalleryImage>{
  @Input() public editComponent: GalleryEditComponent;

  public uploadForm: FormGroup;

  constructor(private router: Router, http: HttpClient, private formBuilder: FormBuilder) {
    super(http);
  }

  public init(): void {
    this.editComponent.setCreateImageComponent(this);

    this.uploadForm = this.formBuilder.group({image: ['']});
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_GALLERY_IMAGE;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }

  protected getTheObject(): GalleryImage {
    return this.object;
  }

  public onFileSelectGallery(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      let headers = HttpHandler.getSessionHeaders();

      const formData = new FormData();
      formData.append('file', file);

      this.myHttpHandlerComponent.get<UploadResult>(this.http.post<UploadResult>(Defaults.SERVER_GALLERY_CONTENT + 'upload', formData, { headers }), (result: UploadResult) => {
        this.object.imageId = result.fileName;

        this.submit();
      });
    }
  }

}
