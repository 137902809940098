<app-normal-popup>
  <ng-container>
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Coupon status</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Status</td>
        <td>
          <label>
            <select>
              <option value="A">Active</option>
              <option value="I">Inactive</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Coupon details</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Discount code</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Code</td>
        <td><input type="text"></td>
      </tr>

      <tr>
        <td></td>
        <td>Max usages</td>
        <td><input type="number"></td>
      </tr>

      <tr>
        <td></td>
        <td>Valid from</td>
        <td><input class="time-from" type="datetime-local" id="from"></td>
      </tr>

      <tr>
        <td></td>
        <td>Valid till</td>
        <td><input class="time-from" type="datetime-local" id="till"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Discount value</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Value mode</td>
        <td>
          <label>
            <select>
              <option value="P">Percentage</option>
              <option value="A">Amount</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <td><ng-container>Euro (cents)</ng-container><ng-container>Percentage</ng-container></td>
        <td><input></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
