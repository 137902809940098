<app-normal-popup [editorComponent]="this" title="news -> variant option">
  <ng-container *ngIf="object != null && object.variants != null">
    <table>
      <tr>
        <td width="64"></td>
        <th align="left" class="table-sub-header">Select product</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Variant</td>
        <td>
          <label>
            <select [disabled]="mode === 2" [(ngModel)]="variantId">
              <ng-container *ngFor="let variant of object.variants"><option  ngValue="{{variant.id}}">{{variant.details[0].name}}</option></ng-container>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Option</td>
        <td>
          <label>
            <select [disabled]="mode === 2" [(ngModel)]="object.newsVariantOption.variantOptionId">
              <ng-container *ngFor="let variant of object.variants">
                <ng-container *ngFor="let variantOption of variant.variantOptions">
                  <ng-container *ngIf="(variantId == null || variantId === variantOption.variantId) || object.newsVariantOption.variantOptionId === variantOption.id"><option ngValue="{{variantOption.id}}">{{variantOption.details[0].name}} ({{variantOption.price}})</option></ng-container>
                </ng-container>
              </ng-container>
            </select>
          </label>
        </td>
      </tr>
    </table>
  </ng-container>
</app-normal-popup>
