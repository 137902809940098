import { Component, OnInit } from '@angular/core';
import * as Sha512 from 'js-sha512';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Defaults } from '../../../constants/defaults';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public firstName: string;
  public lastName: string;

  public email: string;
  public password: string;
  public password2: string;

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
  }

  public register(): void {
    if ( !this.check() ) {
      return;
    }

    const salt = Sha512.sha512(new Date().toString());
    const saltedPassword = Sha512.sha512(this.password + salt);

    const registerObject = new RegisterObject(this.firstName, this.lastName, this.email, salt, saltedPassword);

    this.http.post<boolean>(Defaults.SERVER_API + 'user/register', registerObject).subscribe(
      (result: boolean) => {
        if ( result === true ) {
          this.router.navigateByUrl('/user/login');
        }
      }, error => {

      }, () => {

      }
    );
  }

  private check(): boolean {
    if ( this.firstName === undefined || this.firstName === null ) {
      alert('Please enter your firstname!');
      return false;
    }

    if ( this.lastName === undefined || this.lastName === null ) {
      alert('Please enter your lastname!');
      return false;
    }

    if ( this.email === undefined || this.email === null ) {
      alert('Please enter your email address!');
      return false;
    }

    if ( this.password === undefined || this.password === null ) {
      alert('Please enter a password!');
      return false;
    }
    if ( this.password2 === undefined || this.password2 === null ) {
      alert('Please repeat your password!');
      return false;
    }
    if ( this.password !== this.password2 ) {
      alert('Passwords are not the same, please retype!');
      return false;
    }

    return true;
  }

}

class RegisterObject {
  public firstName: string;
  public lastName: string;

  public email: string;

  public salt: string;
  public hash: string;

  constructor(firstName: string, lastName: string, email: string, salt: string, hash: string) {
    this.firstName = firstName;
    this.lastName = lastName;

    this.email = email;

    this.salt = salt;
    this.hash = hash;
  }
}
