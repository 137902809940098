import {Component, Input} from '@angular/core';
import {PosTabletsComponent} from '../../tablets.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {PosTabletPrinterCreateObject} from './PosTabletPrinterCreateObject';
import {PosTabletPrinter} from '../../../../../domain/pos/PosTabletPrinter';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-pos-create-tablet-printer',
  templateUrl: './menu-tablet-printer-editor.component.html',
  styleUrls: ['./menu-tablet-printer-editor.component.css']
})
export class MenuTabletPrinterEditorComponent extends AbstractEditorComponent<PosTabletPrinterCreateObject, PosTabletPrinter>{
  @Input() tabletsComponent: PosTabletsComponent;

  public isOpen = false;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.tabletsComponent.setMenuTabletPrinterEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_POS_TABLET_PRINTER;
  }

  protected getInitableComponent(): IInit {
    return this.tabletsComponent;
  }

  protected getTheObject(): PosTabletPrinter {
    return this.object.tabletPrinter;
  }

}
