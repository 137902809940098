import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHandler} from '../../../handlers/httpHandler';
import {Shop} from '../../../domain/shop/shop';
import {Defaults} from '../../../constants/defaults';
import {PosTablet} from '../../../domain/pos/PosTablet';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {MenuPosTabletEditorComponent} from './popups/update-tablet/menu-pos-tablet-editor.component';
import {MenuTabletPrinterEditorComponent} from './popups/create-tablet-printer/menu-tablet-printer-editor.component';
import {MenuComponent} from '../../menu/menu.component';
import {MenuSubComponent} from '../MenuSubComponent';
import {MenuPosTabletSetupObject} from './popups/update-tablet/MenuPosTabletSetupObject';

@Component({
  selector: 'app-dashboard-menu-pos-tablets',
  templateUrl: './tablets.component.html',
  styleUrls: ['./tablets.component.css']
})
export class PosTabletsComponent extends SortableObjectPanel<Shop, PosTablet> implements MenuSubComponent {
  @Input() menuComponent: MenuComponent;

  public tabletEditorComponent: MenuPosTabletEditorComponent;
  public tabletPrinterEditorComponent: MenuTabletPrinterEditorComponent;

  public selectedTablet: PosTablet;

  public data: string;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);

    super.init();
  }

  protected getListDone() {
    super.getListDone();

    if ( this.selectedTablet != null ) {
      for ( const tablet of this.listObject.posTablets ) {
        if ( tablet.id === this.selectedTablet.id ) {
          this.selectTablet(tablet);
        }
      }
    }
  }

  public selectTablet(tablet: PosTablet): void {
    this.selectedTablet = tablet;
  }

  public getTabletBaseEndpoint(): string {
    return Defaults.SERVER_API_POS_TABLET;
  }

  public setMenuPosTabletEditorComponent(tabletEditorComponent: MenuPosTabletEditorComponent): void {
    this.tabletEditorComponent = tabletEditorComponent;
  }

  public setMenuTabletPrinterEditorComponent(tabletPrinterEditorComponent: MenuTabletPrinterEditorComponent): void {
    this.tabletPrinterEditorComponent = tabletPrinterEditorComponent;
  }

  public downloadAndCopySetup(tabletId: string): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<MenuPosTabletSetupObject>(this.http.get<MenuPosTabletSetupObject>(Defaults.SERVER_API_POS_TABLET + 'setupDevice/' + tabletId, { headers }), (result: MenuPosTabletSetupObject) => {
      window.open('https://bestelmandje.com/setupOrdersApp?tabletId=' + result.tabletId + '&hash=' + result.tabletToken);
    });
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_POS_TABLET;
  }

}
