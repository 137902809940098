<app-normal-popup [editorComponent]="this" title="table">
  <ng-container *ngIf="object != null && object.table != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Table details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.table.details">
        <tr>
          <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
      </ng-container>

      <tr>
        <td><img width="30" height="30" src="{{image}}"></td>
        <th align="left" class="table-sub-header">QR configuration</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Select configuration</td>
        <td><select [(ngModel)]="object.table.qrConfigurationId">
          <option value="NO_QR_CONFIG">No QR Config</option>
          <ng-container *ngIf="object.qrConfigurations != null">
            <option *ngFor="let item of object.qrConfigurations" [ngValue]="item.id">{{item.name}}</option>
          </ng-container>
        </select></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Chair count</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Preferred</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.table.preferredChairCount"></td>
      </tr>

      <tr>
        <td></td>
        <td>Max</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.table.maxChairCount"></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
