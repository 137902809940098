import {Component, Input, OnInit} from '@angular/core';
import {DeliveryComponent} from '../delivery.component';
import {VariantsComponent} from '../../variants/variants.component';
import {MenuVariant} from '../../../../domain/menu/MenuVariant';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {MenuDelivery} from '../../../../domain/menu/MenuDelivery';
import {Shop} from '../../../../domain/shop/shop';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-create-delivery',
  templateUrl: './menu-delivery-editor.component.html',
  styleUrls: ['./menu-delivery-editor.component.css']
})
export class MenuDeliveryEditor extends AbstractEditorComponent<MenuDelivery, MenuDelivery>{
  @Input() private deliveryComponent: DeliveryComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  init(): void {
    this.deliveryComponent.setMenuDeliveryEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_DELIVERY;
  }

  protected getInitableComponent(): IInit {
    return this.deliveryComponent;
  }

  protected getTheObject(): MenuDelivery {
    return this.object;
  }
}
