<app-normal-popup [editorComponent]="this" title="product">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Product status</th>
        <td></td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Status</td>
        <td>
          <label>
            <select [(ngModel)]="object.active">
              <option value="A">Active</option>
              <option value="I">Inactive</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Product details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td rowspan="2" valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
        <tr>
          <td valign="top" aria-autocomplete="none">Description</td>
          <td><textarea rows="3" style="margin-bottom: 4px; resize: vertical" [(ngModel)]="item.description"></textarea></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Product price</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Euro</td>
        <td><input [(ngModel)]="object.price"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Product Image</th>
        <td></td>
      </tr>

      <tr>
        <img style="width: 48px; height: 48px" src="{{imageRoot}}image/small_1_1/{{object.productImageId}}.png">
        <td>File</td>
        <td><div><input class="left" type="file" (change)="onFileSelect($event)"><button class="right"></button></div></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Product options</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/menu.png"></td>
        <td>Show in Menu</td>
        <td>
          <select [(ngModel)]="object.inMenu">
            <option value="E">Everywhere</option>
            <option value="S">Only in supplements</option>
          </select>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/pos.png"></td>
        <td>Show in POS</td>
        <td>
          <select [(ngModel)]="object.inPos">
            <option value="E">Everywhere</option>
            <option value="S">Only in supplements</option>
          </select>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/web.png"></td>
        <td>Show on Site</td>
        <td>
          <select [(ngModel)]="object.inApi">
            <option value="E">Everywhere</option>
            <option value="S">Only in supplements</option>
          </select>
        </td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/delivery.png"></td>
        <td>Show for Delivery</td>
        <td>
          <select [(ngModel)]="object.inDelivery">
            <option value="D">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
