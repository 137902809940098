<app-normal-popup [editorComponent]="this" title="tablet -> printer">
  <ng-container *ngIf="object != null && object.tabletPrinter != null && object.printers != null">
    <table>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Printer</th>
        <td></td>
      </tr>

      <tr>
        <td style="width: 64px"><img class="image-icon" src="assets/icons/condiment_light.png" alt="CONDIMENTS"></td>
        <td>Printer</td>
        <td>
          <select [(ngModel)]="object.tabletPrinter.printerId" [disabled]="mode === 2">
            <option *ngFor="let printer of object.printers" ngValue="{{printer.id}}">{{printer.details[0].name}}</option>
          </select>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Options</th>
        <td></td>
      </tr>

      <tr>
        <td style="width: 64px"><img class="image-icon" src="assets/icons/condiment_light.png" alt="CONDIMENTS"></td>
        <td>Auto print</td>
        <td>
          <select [(ngModel)]="object.tabletPrinter.autoPrint" [disabled]="mode === 2">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

      <ng-container *ngIf="object.tabletPrinter.autoPrint === 'Y'">
        <tr>
          <td style="width: 64px"><img class="image-icon" src="assets/icons/condiment_light.png" alt="CONDIMENTS"></td>
          <td>Auto print mode</td>
          <td>
            <select [(ngModel)]="object.tabletPrinter.autoPrintMode" [disabled]="mode === 2">
              <option value="C">When confirmed</option>
              <option value="K">When in kitchen</option>
              <option value="O">When on delivery</option>
              <option value="D">When done</option>
            </select>
          </td>
        </tr>
      </ng-container>

      <tr>
        <td style="width: 64px"><img class="image-icon" src="assets/icons/condiment_light.png" alt="CONDIMENTS"></td>
        <td>Use when manually printed</td>
        <td>
          <select [(ngModel)]="object.tabletPrinter.useAsManualPrinter" [disabled]="mode === 2">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

      <tr>
        <td style="width: 64px"><img class="image-icon" src="assets/icons/condiment_light.png" alt="CONDIMENTS"></td>
        <td>Print count</td>
        <td>
          <input type="number" [(ngModel)]="object.tabletPrinter.printCount" [disabled]="mode === 2">
        </td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>

<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
