<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Printers</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">

    <ng-container *ngIf="listObject != null">

      <div class="dashboard-item">
        <div>
          <h4 class="left">Print zones</h4>
          <div class="right">
            <img src="assets/icons/add.png" class="image-button right" (click)="posPrintZoneEditor.open(0, listObject.id, null)" alt="Add Icon">
          </div>
        </div>

        <ng-container *ngIf="listObject.posPrintZones != null">
          <ng-container *ngFor="let printZone of listObject.posPrintZones">
            <div class="bordered-item hoverable" [ngClass]="{'selected': printZone == selectedPrintZone}" (click)="selectPrintZone(printZone)" (dblclick)="posPrintZoneEditor.open(1, null, printZone.id)">
              <div class="dashboard-bordered-item-sub-left width-img-4buttons">
                <p><strong><ng-container *ngIf="printZone.details != null && printZone.details.length > 0">{{printZone.details[0].name}}</ng-container></strong></p>
              </div>
              <div class="dashboard-bordered-item-sub-right">
                <div class="dashboard-bordered-item-sub-right-top">
                  <div class="image-button-box"><img *ngIf="printZone.sequence > 0" (click)="moveUp(printZone, getPrintZoneBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                  <div class="image-button-box"><img *ngIf="printZone.sequence < listObject.posPrintZones.length - 1" (click)="moveDown(printZone, getPrintZoneBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                  <div class="image-button-box"><img (click)="posPrintZoneEditor.open(1, null, printZone.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                  <div class="image-button-box"><img class="image-button" (click)="posPrintZoneEditor.open(2, null, printZone.id)" src="assets/icons/delete_dark.png"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

      </div>
    </ng-container>

    <div>
      <app-trailer></app-trailer>
    </div>

  </div>

  <div class="menu-half-1-content">
    <div>
      <ng-container *ngIf="selectedPrintZone != null">

        <div class="dashboard-title-item">
          <div class="dashboard-bordered-item-sub-left">
            <h4>Printers</h4>
          </div>
          <div class="dashboard-bordered-item-sub-right">
            <img (click)="posPrinterEditor.open(0, selectedPrintZone.id, null)" class="image-button" src="assets/icons/add.png">
          </div>
        </div>

        <ng-container *ngIf="selectedPrintZone.printers != null">
          <ng-container *ngFor="let printer of selectedPrintZone.printers">
            <div class="bordered-item hoverable" (dblclick)="posPrinterEditor.open(1, null, printer.id)">
              <div class="dashboard-bordered-item-sub-left">
                <p><strong>{{printer.details[0].name}}</strong></p>
                <p>{{printer.ip}}:{{printer.port}} ({{printer.format}})</p>
              </div>
              <div class="dashboard-bordered-item-sub-right">
                <div class="dashboard-bordered-item-sub-right-top">
                  <div class="image-button-box"><img *ngIf="printer.sequence > 0" (click)="moveUp(printer, getPrinterBaseEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                  <div class="image-button-box"><img *ngIf="printer.sequence < selectedPrintZone.printers.length - 1" (click)="moveDown(printer, getPrinterBaseEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                  <div class="image-button-box"><img (click)="posPrinterEditor.open(1, null, printer.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
                  <div class="image-button-box"><img class="image-button" (click)="posPrinterEditor.open(2, null, printer.id)" src="assets/icons/delete_dark.png"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

      </ng-container>
    </div>
    <app-trailer></app-trailer>
  </div>

  <div>
    <app-dashboard-pos-create-print-zone [printersComponent]="this"></app-dashboard-pos-create-print-zone>
    <app-dashboard-pos-create-printer [printersComponent]="this"></app-dashboard-pos-create-printer>
  </div>
</div>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
