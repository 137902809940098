import {Component, Input, OnInit} from '@angular/core';
import {ShopsComponent} from '../shops.component';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {Shop} from '../../../domain/shop/shop';
import {ShopLanguage} from '../../../domain/shop/shopLanguage';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {ShopLanguageEditorComponent} from './shop-language-editor/shop-language-editor.component';

@Component({
  selector: 'app-dashboard-shop-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.css']
})
export class LanguagesComponent extends SortableObjectPanel<Shop, ShopLanguage>{
  @Input() shopsComponent: ShopsComponent;

  public shopLanguageEditor: ShopLanguageEditorComponent;

  public selectedShopLanguage: ShopLanguage;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.shopsComponent.setSubComponent(this);

    super.init();
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_SHOP_LANGUAGE;
  }

  public setShopLanguageEditor(shopLanguageEditor: ShopLanguageEditorComponent): void {
    this.shopLanguageEditor = shopLanguageEditor;
  }

  public selectShopLanguage(shopLanguage: ShopLanguage): void {
    this.selectedShopLanguage = shopLanguage;
  }
}
