<app-dashboard-header mainComponent="screen"></app-dashboard-header>
<ng-container *ngIf="user != null && user.screenAppEnabled">

</ng-container>
<main>
  <ng-container *ngIf="user != null && user.screenAppEnabled">

  </ng-container>
  <ng-container *ngIf="user != null && !user.screenAppEnabled">
    <div>
      <div style="display: flex; align-content: center; justify-content: center; padding-bottom: 15px">
        <img width="128" height="128" src="assets/illustrations/streamline-icon-program-error-2@128x128.png">
      </div>
      <h3 style="clear: both">Sorry, screen application is not activated for this user.</h3>
    </div>
  </ng-container>
</main>

<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
