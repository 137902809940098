import { Component, OnInit } from '@angular/core';
import {User} from '../../domain/user/user';
import {IHaveMyHttpHandler} from '../my-http-handler/IHaveMyHttpHandler';
import {HttpClient} from '@angular/common/http';
import {HttpHandler} from '../../handlers/httpHandler';
import {Defaults} from '../../constants/defaults';

@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.css']
})
export class ScreenComponent extends IHaveMyHttpHandler{

  public user: User;

  constructor(private http: HttpClient) {
    super();
  }

  public init(): void {
    this.getUser();
  }

  private getUser(): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<User>(this.http.get<User>(Defaults.SERVER_API_USER, { headers }), (result: User) => {
      this.user = result;
    })
  }

}
