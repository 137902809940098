import {Component, Input} from '@angular/core';
import {StockVariantComponent} from '../variant.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {IInit} from '../../../abstract/IInit';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {CatalogVariantOption} from '../../../../domain/stock/CatalogVariantOption';

@Component({
  selector: 'app-dashboard-stock-variant-option-editor',
  templateUrl: './variant-option-editor.component.html',
  styleUrls: ['./variant-option-editor.component.css']
})
export class StockVariantOptionEditorComponent extends AbstractEditorComponent<CatalogVariantOption, CatalogVariantOption>{
  @Input() variantsComponent: StockVariantComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  init(): void {
    this.variantsComponent.setCreateVariantOptionEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_VARIANT_OPTION;
  }

  protected getTheObject(): CatalogVariantOption {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.variantsComponent;
  }
}
