<header>
  <div class="header-box-right">
    <div class="header-profile-box"  [ngClass]="{'header-nav-item-selected': getMainComponent() == 'profile'}" (click)="open('profile')"title="PROFILE">
      <img src="assets/icons/profile.png"/>
      <h3>{{getLastName()}} {{getFistName()}}</h3>
    </div>
  </div>
  <div class="header-box-left">
    <div>
      <div class="header-nav-item" [ngClass]="{'header-nav-item-selected': getMainComponent() == 'shop'}" (click)="open('shop/general')" title="SHOPS">
        <img src="assets/icons/dark/streamline-icon-shop@32x32.png">
      </div>
      <div class="header-nav-item" [ngClass]="{'header-nav-item-selected': getMainComponent() == 'menu'}" (click)="open('menu/edit/root')" title="MENU">
        <img src="assets/icons/dark/streamline-icon-restaurant-menu@32x32.png">
      </div>
      <div class="header-nav-item" [ngClass]="{'header-nav-item-selected': getMainComponent() == 'stock'}" (click)="open('stock/edit/root')" title="STOCK">
        <img src="assets/icons/dark/streamline-icon-shipment-box@32x32.png">
      </div>
      <div class="header-nav-item" [ngClass]="{'header-nav-item-selected': getMainComponent() == 'screen'}" (click)="open('screen')" title="SCREEN">
        <img src="assets/icons/dark/streamline-icon-screen-curved@32x32.png">
      </div>
      <div class="header-nav-item" [ngClass]="{'header-nav-item-selected': getMainComponent() == 'gallery'}" (click)="open('gallery/edit/root')" title="GALLERY">
        <img src="assets/icons/gallery_icon.png">
      </div>
    </div>
  </div>
</header>
