import {Component, Input, OnInit} from '@angular/core';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {CatalogNewsEmailSubscription} from '../../../../domain/stock/CatalogNewsEmailSubscription';
import {HttpClient} from '@angular/common/http';
import {IInit} from '../../../abstract/IInit';
import {CatalogEmailSubscriptionsComponent} from '../catalog-email-subscriptions.component';
import {Defaults} from '../../../../constants/defaults';
import {CatalogNewsEmailSubscriptionCreateObject} from '../../../../domain/stock/CatalogNewsEmailSubscriptionCreateObject';

@Component({
  selector: 'app-dashboard-catalog-email-subscription-editor',
  templateUrl: './catalog-email-subscription-editor.component.html',
  styleUrls: ['./catalog-email-subscription-editor.component.css']
})
export class CatalogEmailSubscriptionEditorComponent extends AbstractEditorComponent<CatalogNewsEmailSubscriptionCreateObject, CatalogNewsEmailSubscription>{
  @Input() emailSubscriptionsComponent: CatalogEmailSubscriptionsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.emailSubscriptionsComponent.setCatalogEmailSubscriptionComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_EMAIL_SUBSCRIPTION;
  }

  protected getInitableComponent(): IInit {
    return this.emailSubscriptionsComponent;
  }

  protected getTheObject(): CatalogNewsEmailSubscription {
    return this.object.newsEmailSubscription;
  }

}
