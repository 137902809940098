import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {AbstractRootEditorComponent} from '../../../../abstract/AbstractRootEditorComponent';
import {Catalog} from '../../../../../domain/stock/Catalog';
import {EditingCatalogComponent} from '../../editing-catalog.component';
import {IInit} from '../../../../abstract/IInit';
import {AbstractEditingSelectionComponent} from '../../../../menu/editing-menu/AbstractEditingSelectionComponent';

@Component({
  selector: 'app-dashboard-catalog-editor',
  templateUrl: './catalog-editor.component.html',
  styleUrls: ['./catalog-editor.component.css']
})
export class CatalogEditorComponent extends AbstractRootEditorComponent<Catalog> {
  @Input() public editingCatalogComponent: AbstractEditingSelectionComponent<Catalog, Catalog>;

  constructor(private router: Router, http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editingCatalogComponent.setAbstractEditorComponent(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG;
  }

  protected getApiEditingEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_SELECTION;
  }

  protected getTheObject(): Catalog {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.editingCatalogComponent;
  }
}
