import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {HttpHandler} from '../../../handlers/httpHandler';
import {UserWorkgroup} from '../../../domain/shop/userWorkgroup';
import {Defaults} from '../../../constants/defaults';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-mymoney-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class MyMoneyNavigationComponent implements OnInit, OnChanges {
  @Input() leftComponent: string;

  public userWorkgroups: UserWorkgroup[];

  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.getWorkgroups();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  public getLeftComponent(): string {
    return this.leftComponent;
  }

  public openLeftComponent(component: string): void {

  }

  private getWorkgroups(): void {
    const headers = HttpHandler.getSessionHeaders();

    this.http.get<UserWorkgroup[]>(Defaults.SERVER_API + 'userWorkgroup/all', { headers }).subscribe(
      (result: UserWorkgroup[]) => {
        this.userWorkgroups = result;
      }, (error) => {

      }, () => {

      }
    );
  }

}
