<app-left-side-container [iHaveMyHttpHandler]="this">
  <ng-container *ngIf="listObject != null && listObject.newsEmailSubscriptions != null">

    <div class="dashboard-item">
      <div>
        <h4 class="left">Email templates</h4>
        <div class="right">
          <img src="assets/icons/add.png" class="image-button right" (click)="emailSubscriptionEditor.open(0, listObject.id, null)" alt="Add Icon">
        </div>
      </div>

      <ng-container *ngIf="listObject.newsEmailSubscriptions != null">
        <ng-container *ngFor="let emailSubscription of listObject.newsEmailSubscriptions">
          <div class="bordered-item hoverable" (dblclick)="emailSubscriptionEditor.open(1, null, emailSubscription.id)">
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <p><strong>{{emailSubscription.lastName}} {{emailSubscription.firstName}}</strong></p>
              <p>{{emailSubscription.email}} ({{emailSubscription.languageCode}})</p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img *ngIf="emailSubscription.sequence > 0" (click)="moveUp(emailSubscription, getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="emailSubscription.sequence < listObject.newsEmailSubscriptions.length - 1" (click)="moveDown(emailSubscription, getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img (click)="emailSubscriptionEditor.open(1, null, emailSubscription.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>

  </ng-container>
</app-left-side-container>

<app-dashboard-catalog-email-subscription-editor [emailSubscriptionsComponent]="this"></app-dashboard-catalog-email-subscription-editor>
