import {Component, Input, OnInit} from '@angular/core';
import {MenuDiscountCouponComponent} from '../menu-discount-coupon.component';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {MenuDiscountCoupon} from '../../../../domain/menu/MenuDiscountCoupon';
import {HttpClient} from '@angular/common/http';
import {IInit} from '../../../abstract/IInit';
import {Defaults} from '../../../../constants/defaults';
import {DateTimeUtil} from '../../../DateTimeUtil/DateTimeUtil';

@Component({
  selector: 'app-dashboard-menu-discount-coupon-editor',
  templateUrl: './menu-discount-coupon-editor.component.html',
  styleUrls: ['./menu-discount-coupon-editor.component.css']
})
export class MenuDiscountCouponEditorComponent extends AbstractEditorComponent<MenuDiscountCoupon, MenuDiscountCoupon> {
  @Input() menuDiscountCouponComponent: MenuDiscountCouponComponent;

  public from: string;
  public till: string;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuDiscountCouponComponent.setMenuDiscountCouponEditorComponent(this);
  }

  protected getObjectDone() {
    this.from = DateTimeUtil.customToIso(this.object.validFrom);
    this.till = DateTimeUtil.customToIso(this.object.validTill);
  }

  protected preSubmit() {
    this.object.validFrom = DateTimeUtil.isoToCustom(this.from);
    this.object.validTill = DateTimeUtil.isoToCustom(this.till);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_DISCOUNT_COUPON;
  }

  protected getTheObject(): MenuDiscountCoupon {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.menuDiscountCouponComponent;
  }

}
