import {Component, Input} from '@angular/core';
import {StockComponent} from '../stock.component';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {Catalog} from '../../../domain/stock/Catalog';
import {CatalogEmailTemplate} from '../../../domain/stock/CatalogEmailTemplate';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {EmailTemplateEditorComponent} from './email-template-editor/email-template-editor.component';

@Component({
  selector: 'app-dashboard-stock-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.css']
})
export class EmailTemplatesComponent extends SortableObjectPanel<Catalog, CatalogEmailTemplate>{
  @Input() stockComponent: StockComponent;

  public emailTemplateEditor: EmailTemplateEditorComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.stockComponent.setSubComponent(this);

    super.init();
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_EMAIL_TEMPLATE;
  }

  public setEmailTemplateEditorComponent(emailTemplateEditor: EmailTemplateEditorComponent): void {
    this.emailTemplateEditor = emailTemplateEditor;
  }

}
