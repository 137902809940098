<div ng-controller="menu">
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3></h3>
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">
    <div>

    </div>
  </div>

  <div>
    <!-- TODO add popup components here -->
  </div>
</div>
