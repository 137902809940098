import {Component, Input, OnInit} from '@angular/core';
import {IHaveMyHttpHandler} from '../../my-http-handler/IHaveMyHttpHandler';
import {IMyHttpHandlerReceiver} from '../IMyHttpHandlerReceiver';
import {MyHttpHandlerComponent} from '../../my-http-handler/my-http-handler.component';

@Component({
  selector: 'app-left-side-container',
  templateUrl: './left-side-container.component.html',
  styleUrls: ['./left-side-container.component.css']
})
export class LeftSideContainerComponent implements IMyHttpHandlerReceiver, OnInit {
  @Input() iHaveMyHttpHandler: IHaveMyHttpHandler;

  constructor() { }

  ngOnInit(): void {
  }

  setMyHttpHandler(myHttpHandlerComponent: MyHttpHandlerComponent): void {
    this.iHaveMyHttpHandler.setMyHttpHandlerComponent(myHttpHandlerComponent);
  }

  forceClose(forceClose: boolean): void {
    this.iHaveMyHttpHandler.forceClose(forceClose);
  }

}
