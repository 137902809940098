import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHandler} from '../../../handlers/httpHandler';
import {Defaults} from '../../../constants/defaults';
import {AbstractEditingSelectionComponent} from '../../menu/editing-menu/AbstractEditingSelectionComponent';
import {StockComponent} from '../stock.component';
import {Catalog} from '../../../domain/stock/Catalog';
import {IInit} from '../../abstract/IInit';
import {EditingSelectionDisplayNames} from '../../menu/editing-menu/EditingSelectionDisplayNames';

@Component({
  selector: 'app-dashboard-stock-editing-catalog',
  templateUrl: './editing-catalog.component.html',
  styleUrls: ['./editing-catalog.component.css']
})
export class EditingCatalogComponent extends AbstractEditingSelectionComponent<Catalog, Catalog> implements IInit {
  @Input() stockComponent: StockComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public selectionChanged(): void {
    this.init();
    this.stockComponent.selectionChanged();
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_SELECTION;
  }
}
