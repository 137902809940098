import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {Supplement} from '../../../../domain/menu/supplement';
import {SupplementsComponent} from '../supplements.component';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../abstract/IInit';
import {SupplementProduct} from '../../../../domain/menu/SupplementProduct';

@Component({
  selector: 'app-dashboard-menu-supplement-editor',
  templateUrl: './menu-supplement-editor.component.html',
  styleUrls: ['./menu-supplement-editor.component.css']
})
export class MenuSupplementEditor extends AbstractEditorComponent<Supplement, Supplement>{
  @Input() supplementsComponent: SupplementsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.supplementsComponent.setSupplementEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_SUPPLEMENT;
  }

  protected getTheObject(): Supplement {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.supplementsComponent;
  }
}
