<div class="login-box">
  <div class="full-width">
    <h3>REGISTER</h3>
  </div>
  <div class="full-width">
    <label>
      <input type="text" placeholder="firstname" [(ngModel)]="firstName" />
    </label>
    <label>
      <input type="text" placeholder="lastname" [(ngModel)]="lastName" />
    </label>
    <label>
      <input type="email" placeholder="email" [(ngModel)]="email" />
    </label>
    <label>
      <input type="password" placeholder="password" [(ngModel)]="password" />
    </label>
    <label>
      <input type="password" placeholder="repeat password" [(ngModel)]="password2" />
    </label>
  </div>
  <div class="full-width">
    <button class="button button-blue" (click)="register()">REGISTER</button>
  </div>
  <div class="full-width">
    <p>Already an account? <a href="/user/login">Click here to login!</a></p>
  </div>
</div>
