import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GalleryComponent} from '../gallery.component';

@Component({
  selector: 'app-dashboard-gallery-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class GalleryNavigationComponent implements OnInit {
  @Input() component: string;
  @Input() galleryComponent: GalleryComponent;

  constructor() {
  }

  ngOnInit(): void {
  }

  public getComponent(): string {
    return this.component;
  }

  public openComponent(component: string): void {
    this.galleryComponent.openComponent(component);
  }
}
