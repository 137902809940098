<app-normal-popup [editorComponent]="this" title="promotion">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Promotion status</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Status</td>
        <td>
          <label>
            <select [(ngModel)]="object.active">
              <option value="A">Active</option>
              <option value="I">Inactive</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Promotion details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td rowspan="2" valign="top" style="width: 64px"><img width="40" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
        <tr>
          <td valign="top" aria-autocomplete="none">Description</td>
          <td><textarea rows="3" [(ngModel)]="item.description"></textarea></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Options</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Valid from</td>
        <td><input class="time-from" type="datetime-local" id="from" [(ngModel)]="from"></td>
      </tr>

      <tr>
        <td></td>
        <td>Valid till</td>
        <td><input class="time-from" type="datetime-local" id="till" [(ngModel)]="till"></td>
      </tr>

      <tr>
        <td></td>
        <td>Available for local consumption</td>
        <td>
          <label>
            <select [(ngModel)]="object.allowedForLocalConsumption">
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <td>Available for pickup</td>
        <td>
          <label>
            <select [(ngModel)]="object.allowedForPickup">
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <td>Available for delivery</td>
        <td>
          <label>
            <select [(ngModel)]="object.allowedForDelivery">
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Promotion value</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Value mode</td>
        <td>
          <label>
            <select [(ngModel)]="object.valueMode">
              <option value="P">Percentage</option>
              <option value="A">Amount</option>
              <option value="N">New price</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <td><ng-container *ngIf="object.valueMode === 'A'">Euro (cents)</ng-container><ng-container *ngIf="object.valueMode === 'P'">Percentage</ng-container><ng-container *ngIf="object.valueMode === 'N'">New price</ng-container></td>
        <td><input [(ngModel)]="object.value"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Week days</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Use day of week filter</td>
        <td><input type="checkbox" [(ngModel)]="object.useDayOfWeekFilter"></td>
      </tr>

      <tr>
        <td></td>
        <td>Monday</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfWeekFilter" [(ngModel)]="object.filterDowMonday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Tuesday</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfWeekFilter" [(ngModel)]="object.filterDowTuesday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Wednesday</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfWeekFilter" [(ngModel)]="object.filterDowWednesday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Thursday</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfWeekFilter" [(ngModel)]="object.filterDowThursday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Friday</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfWeekFilter" [(ngModel)]="object.filterDowFriday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Saturday</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfWeekFilter" [(ngModel)]="object.filterDowSaturday"/></td>
      </tr>

      <tr>
        <td></td>
        <td>Sunday</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfWeekFilter" [(ngModel)]="object.filterDowSunday"/></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Month days</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Use day of month filter</td>
        <td><input type="checkbox" [(ngModel)]="object.useDayOfMonthFilter"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 1</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom01"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 2</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom02"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 3</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom03"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 4</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom04"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 5</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom05"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 6</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom06"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 7</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom07"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 8</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom08"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 9</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom09"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 10</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom10"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 11</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom11"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 12</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom12"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 13</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom13"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 14</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom14"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 15</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom15"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 16</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom16"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 17</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom17"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 18</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom18"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 19</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom19"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 20</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom20"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 21</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom21"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 22</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom22"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 23</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom23"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 24</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom24"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 25</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom25"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 26</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom26"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 27</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom27"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 28</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom28"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 29</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom29"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 30</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom30"></td>
      </tr>

      <tr>
        <td></td>
        <td>Day 31</td>
        <td><input type="checkbox" [disabled]="!object.useDayOfMonthFilter" [(ngModel)]="object.filterDom31"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Months</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Use month filter</td>
        <td><input type="checkbox" [(ngModel)]="object.useMonthOfYearFilter"></td>
      </tr>

      <tr>
        <td></td>
        <td>January</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyJanuary"></td>
      </tr>

      <tr>
        <td></td>
        <td>February</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyFebruary"></td>
      </tr>

      <tr>
        <td></td>
        <td>March</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyMarch"></td>
      </tr>

      <tr>
        <td></td>
        <td>April</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyApril"></td>
      </tr>

      <tr>
        <td></td>
        <td>May</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyMay"></td>
      </tr>

      <tr>
        <td></td>
        <td>June</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyJune"></td>
      </tr>

      <tr>
        <td></td>
        <td>Juli</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyJuli"></td>
      </tr>

      <tr>
        <td></td>
        <td>August</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyAugust"></td>
      </tr>

      <tr>
        <td></td>
        <td>September</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoySeptember"></td>
      </tr>

      <tr>
        <td></td>
        <td>October</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyOctober"></td>
      </tr>

      <tr>
        <td></td>
        <td>November</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyNovember"></td>
      </tr>

      <tr>
        <td></td>
        <td>December</td>
        <td><input type="checkbox" [disabled]="!object.useMonthOfYearFilter" [(ngModel)]="object.filterMoyDecember"></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
