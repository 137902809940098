import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private firstName: string;
  private lastName: string;

  private router: Router;

  @Input() mainComponent: string;

  constructor(router: Router) {
    this.router = router;

    this.firstName = localStorage.getItem("firstname");
    this.lastName = localStorage.getItem("lastname");
  }

  ngOnInit(): void {
  }

  public getMainComponent(): string {
    return this.mainComponent;
  }

  public getFistName(): string {
    return this.firstName;
  }

  public getLastName(): string {
    return this.lastName;
  }

  public open(component: string): void {
    this.router.navigateByUrl('/' + component);
  }
}
