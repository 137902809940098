import {Component, Input} from '@angular/core';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {Catalog} from '../../../domain/stock/Catalog';
import {CatalogImage} from '../../../domain/stock/CatalogImage';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {StockComponent} from '../stock.component';
import {CatalogImageEditorComponent} from './catalog-image-editor/catalog-image-editor.component';

@Component({
  selector: 'app-dashboard-catalog-image',
  templateUrl: './catalog-image.component.html',
  styleUrls: ['./catalog-image.component.css']
})
export class CatalogImageComponent extends SortableObjectPanel<Catalog, CatalogImage>{
  @Input() stockComponent: StockComponent;

  public imageRoot = Defaults.SERVER_STOCK_CONTENT;

  public catalogImageEditorComponent: CatalogImageEditorComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.stockComponent.setSubComponent(this);

    super.init();
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_IMAGE;
  }

  public setCatalogImageEditorComponent(catalogImageEditorComponent: CatalogImageEditorComponent): void {
    this.catalogImageEditorComponent = catalogImageEditorComponent;
  }

}
