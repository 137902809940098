import {Component, Input} from '@angular/core';
import {EditComponent} from '../../edit.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {MenuProductPromotionCreateObject} from './MenuProductPromotionCreateObject';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';
import {MenuProductPromotion} from '../../../../../domain/menu/MenuProductPromotion';

@Component({
  selector: 'app-dashboard-menu-add-product-promotion',
  templateUrl: './add-promotion.component.html',
  styleUrls: ['./add-promotion.component.css']
})
export class MenuProductPromotionEditor extends AbstractEditorComponent<MenuProductPromotionCreateObject, MenuProductPromotion>{
  @Input() editComponent: EditComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setMenuProductPromotionEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT_PROMOTION;
  }

  protected getTheObject(): MenuProductPromotion {
    return this.object.productPromotion;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }
}
