import {Component, Input, OnInit} from '@angular/core';
import {MenuComponent} from '../menu.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {Product} from '../../../domain/menu/product';
import {IHaveMyHttpHandler} from '../../my-http-handler/IHaveMyHttpHandler';
import {MenuSubComponent} from '../MenuSubComponent';

@Component({
  selector: 'app-dashboard-menu-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.css']
})
export class AvailabilityComponent extends IHaveMyHttpHandler implements MenuSubComponent {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  public products: Product[];

  constructor(private http: HttpClient) {
    super();
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);
    this.getProducts();
  }

  ngOnInit(): void {
    this.init();
  }

  public getProducts(): void {
    const headers = new HttpHeaders({
      sessionId: Defaults.getSessionId(),
      sessionToken: Defaults.getSessionToken()
    });

    this.http.get<Product[]>(Defaults.SERVER_API + 'product/allWithTimeslots', {headers})
      .subscribe(
        (result: Product[]) => {
          this.products = result;
        },
        error => {
        },
        () => {
        });
  }

}
