import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {CatalogImage} from '../../../../domain/stock/CatalogImage';
import {CatalogImageComponent} from '../catalog-image.component';
import {Defaults} from '../../../../constants/defaults';
import {IInit} from '../../../abstract/IInit';
import {HttpHandler} from '../../../../handlers/httpHandler';
import {UploadResult} from '../../edit/popups/productImage/create-product-image/UploadResult';

@Component({
  selector: 'app-dashboard-catalog-image-editor',
  templateUrl: './catalog-image-editor.component.html',
  styleUrls: ['./catalog-image-editor.component.css']
})
export class CatalogImageEditorComponent extends AbstractEditorComponent<CatalogImage, CatalogImage>{
  @Input() catalogImageComponent: CatalogImageComponent;

  public imageRoot = Defaults.SERVER_STOCK_CONTENT;

  constructor(http: HttpClient) {
    super(http);
  }

  init(): void {
    this.catalogImageComponent.setCatalogImageEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_IMAGE;
  }

  protected getInitableComponent(): IInit {
    return this.catalogImageComponent;
  }

  protected getTheObject(): CatalogImage {
    return this.object;
  }

  public onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      let headers = HttpHandler.getSessionHeaders();

      const formData = new FormData();
      formData.append('file', file);

      this.myHttpHandlerComponent.get<UploadResult>(this.http.post<UploadResult>(Defaults.SERVER_STOCK_CONTENT + 'upload', formData, { headers }), (result: UploadResult) => {
        this.object.imageId = result.fileName;

        this.submit();
      });
    }
  }

}
