<script src="hrc-menu/edit/menu.js"></script>
<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Tokens</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-full-content">
  </div>

  <div>
  </div>
</div>
