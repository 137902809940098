import {Component, Input} from '@angular/core';
import {Menu} from '../../../../../domain/menu/menu';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AbstractRootEditorComponent} from '../../../../abstract/AbstractRootEditorComponent';
import {Defaults} from '../../../../../constants/defaults';
import {IInit} from '../../../../abstract/IInit';
import {AbstractEditingSelectionComponent} from '../../AbstractEditingSelectionComponent';

@Component({
  selector: 'app-dashboard-edit-menu',
  templateUrl: './menu-editor.component.html',
  styleUrls: ['./menu-editor.component.css']
})
export class MenuEditorComponent extends AbstractRootEditorComponent<Menu> {
  @Input() public editingMenuComponent: AbstractEditingSelectionComponent<Menu, Menu>;

  constructor(private router: Router, http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editingMenuComponent.setAbstractEditorComponent(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU;
  }

  protected getApiEditingEndpoint(): string {
    return Defaults.SERVER_API_MENU_SELECTION;
  }

  protected getTheObject(): Menu {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return undefined;
  }
}
