import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {VariantsComponent} from '../../variants.component';
import {MenuVariant} from '../../../../../domain/menu/MenuVariant';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-variant-editor',
  templateUrl: './menu-variant-editor.component.html',
  styleUrls: ['./menu-variant-editor.component.css']
})
export class MenuVariantEditor extends AbstractEditorComponent<MenuVariant, MenuVariant> {
  @Input() variantsComponent: VariantsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.variantsComponent.setCreateVariantComponent(this);
  }

  protected submitDone() {
    this.variantsComponent.selectVariant(this.object);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_VARIANT;
  }

  protected getTheObject(): MenuVariant {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.variantsComponent;
  }
}
