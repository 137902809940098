import {Component, Input, OnInit} from '@angular/core';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {User} from '../../../../domain/user/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {IInit} from '../../../abstract/IInit';
import {UserShop} from '../../../../domain/shop/userShop';
import {EmployeeComponent} from '../employee.component';
import {EditorMode} from '../../../abstract/EditorMode';
import {EmployeeEditorComponent} from '../employee-editor/employee-editor.component';
import {HttpHandler} from '../../../../handlers/httpHandler';

@Component({
  selector: 'app-dashboard-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.css']
})
export class SearchUserComponent extends AbstractEditorComponent<User, User>{
  @Input() employeeComponent: EmployeeComponent;
  @Input() employeeEditorComponent: EmployeeEditorComponent;

  public shopId: string;
  public user: User;
  public email: string;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.employeeComponent.setSearchUserComponent(this);
  }

  open(mode: EditorMode, parentId: string, objectId: string) {
    this.shopId = parentId;
    this.isOpen = true;
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_USER;
  }

  protected getInitableComponent(): IInit {
    return undefined;
  }

  protected getTheObject(): User {
    return undefined;
  }

  public searchUser(): void {
    const headers = new HttpHeaders({
      sessionId: Defaults.getSessionId(),
      sessionToken: Defaults.getSessionToken(),
      email: this.email
    });

    this.myHttpHandlerComponent.get<User>(this.http.get<User>(Defaults.SERVER_API_USER + 'findUserByEmail', { headers }), (result: User) => {
      this.user = result;
    });
  }

  public clear(): void {
    this.email = null;
    this.user = null;
  }

  submit() {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<UserShop>(this.http.get<UserShop>(this.employeeEditorComponent.getApiRootEndpoint() + 'createNew/' + this.shopId, { headers }), (result: UserShop) => {
      result.user = this.user;
      result.userId = this.user.id;
      this.employeeEditorComponent.openCreate(result);
      this.close();
      this.shopId = null;
      this.user = null;
      this.email = null;
    });
  }

  titleText(text: string): string {
    return text;
  }

  buttonText(): string {
    return 'SELECT USER';
  }

  positiveButtonColor(): string {
    return 'button-blue';
  }
}
