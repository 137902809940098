import {IHaveMyHttpHandler} from './my-http-handler/IHaveMyHttpHandler';
import {SortableObject} from './SortableObject';
import {HttpHandler} from '../handlers/httpHandler';
import {HttpClient} from '@angular/common/http';
import {AbstractListComponent} from './abstract/AbstractListComponent';

export abstract class SortableObjectPanel<ListT, T extends SortableObject> extends AbstractListComponent<ListT, T> {

  protected constructor(http: HttpClient) {
    super(http);
  }

  public moveUp(sortableObject: T, objectBaseEndpoint: string): void {
    this.move(sortableObject, objectBaseEndpoint, 'moveUp/');
  }

  public moveDown(sortableObject: T, objectBaseEndpoint: string): void {
    this.move(sortableObject, objectBaseEndpoint, 'moveDown/');
  }

  protected move(sortableObject: T, objectBaseEndpoint: string, direction: string): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<boolean>(this.http.put<boolean>(objectBaseEndpoint + direction, sortableObject, { headers }), (result: boolean) => {
      this.init();
    });
  }

}
