import {Component, Input} from '@angular/core';
import {MenuComponent} from '../menu.component';
import {QrConfiguration} from '../../../domain/menu/qrConfiguration';
import {MenuQrConfigEditor} from './create-qr/menu-qr-config-editor.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {Shop} from '../../../domain/shop/shop';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {MenuSubComponent} from '../MenuSubComponent';

@Component({
  selector: 'app-dashboard-menu-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.css']
})
export class QrComponent extends SortableObjectPanel<Shop, QrConfiguration> implements MenuSubComponent {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  public qrConfigEditor: MenuQrConfigEditor;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);
    super.init();
  }

  public setMenuQrConfigEditor(qrConfigEditor: MenuQrConfigEditor): void {
    this.qrConfigEditor = qrConfigEditor;
  }

  public getQrConfigurationBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_QR_CONFIGURATION;
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_QR_CONFIGURATION;
  }

}
