<app-normal-popup [editorComponent]="this" title="shop">
  <ng-container *ngIf="object != null && object.shops != null">
    <table>
      <tr>
        <td width="64"></td>
        <th align="left" class="table-sub-header">Select shop</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Shop</td>
        <td>
          <label>
            <select [(ngModel)]="object.user.selectedShopId">
              <ng-container *ngFor="let shop of object.shops"><option  ngValue="{{shop.id}}">{{shop.name}}</option></ng-container>
            </select>
          </label>
        </td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
