import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {StockSubComponent} from '../suppliers/StockSubComponent';
import {StockComponent} from '../stock.component';
import {StockVariantEditorComponent} from './variant-editor/variant-editor.component';
import {StockVariantOptionEditorComponent} from './variant-option-editor/variant-option-editor.component';
import {CatalogVariant} from '../../../domain/stock/CatalogVariant';
import {Catalog} from '../../../domain/stock/Catalog';

@Component({
  selector: 'app-dashboard-stock-variant',
  templateUrl: './variant.component.html',
  styleUrls: ['./variant.component.css']
})
export class StockVariantComponent extends SortableObjectPanel<Catalog, CatalogVariant> implements StockSubComponent {
  @Input() stockComponent: StockComponent;
  @Input() parameter: string;

  public variantEditor: StockVariantEditorComponent;
  public variantOptionEditorComponent: StockVariantOptionEditorComponent;

  public selectedVariant: CatalogVariant;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.stockComponent.setSubComponent(this);
    super.init();
  }

  protected getListDone() {
    if ( this.selectedVariant != null ) {
      let replaced = false;
      for ( const variant of this.listObject.variants ) {
        if ( variant.id === this.selectedVariant.id ) {
          this.selectedVariant = variant;
          replaced = true;
          break;
        }
      }

      if ( !replaced ) {
        this.selectedVariant = null;
      }
    }
  }

  public setCreateVariantComponent(variantEditor: StockVariantEditorComponent): void {
    this.variantEditor = variantEditor;
  }

  public setCreateVariantOptionEditor(variantOptionEditorComponent: StockVariantOptionEditorComponent): void {
    this.variantOptionEditorComponent = variantOptionEditorComponent;
  }

  public selectVariant(productVariant: CatalogVariant): void {
    this.selectedVariant = productVariant;
  }

  getObjectBaseEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_VARIANT + '/';
  }

  public getVariantOptionBaseEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_VARIANT_OPTION + '/';
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_VARIANT;
  }
}
