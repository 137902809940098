<app-normal-popup [editorComponent]="this" title="menu closures">
  <ng-container *ngIf=" object != null && object.shop != null">
    <table>

      <tr>
        <td style="width: 64px"></td>
        <th align="left" class="table-sub-header">Monday</th>
        <td style="width: 50px"></td>
        <td></td>
      </tr>

      <tr>
        <td><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
        <td>Closure board</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnMonday"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnMonday" [(ngModel)]="object.shop.menuClosureMonday"></td>
      </tr>

      <tr>
        <td></td>
        <td>Allow orders</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnMondayOrders"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnMondayOrders" [(ngModel)]="object.shop.menuClosureMondayOrders"></td>
      </tr>

      <tr>
        <td></td>
        <td>Local</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnMondayLocal"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnMondayLocal" [(ngModel)]="object.shop.menuClosureMondayLocal"></td>
      </tr>

      <tr>
        <td></td>
        <td>Pickup</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnMondayPickup"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnMondayPickup" [(ngModel)]="object.shop.menuClosureMondayPickup"></td>
      </tr>

      <tr>
        <td></td>
        <td>Delivery</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnMondayDelivery"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnMondayDelivery" [(ngModel)]="object.shop.menuClosureMondayDelivery"></td>
      </tr>

      <tr>
        <td></td>
        <td>Revenue</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnMondayRevenue"/></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnMondayRevenue" [(ngModel)]="object.shop.menuClosureMondayRevenue"/></td>
      </tr>


      <tr>
        <td style="width: 64px"></td>
        <th align="left" class="table-sub-header">Tuesday</th>
        <td style="width: 50px"></td>
        <td></td>
      </tr>

      <tr>
        <td><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
        <td>Closure board</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnTuesday"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnTuesday" [(ngModel)]="object.shop.menuClosureTuesday"></td>
      </tr>

      <tr>
        <td></td>
        <td>Allow orders</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnTuesdayOrders"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnTuesdayOrders" [(ngModel)]="object.shop.menuClosureTuesdayOrders"></td>
      </tr>

      <tr>
        <td></td>
        <td>Local</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnTuesdayLocal"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnTuesdayLocal" [(ngModel)]="object.shop.menuClosureTuesdayLocal"></td>
      </tr>

      <tr>
        <td></td>
        <td>Pickup</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnTuesdayPickup"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnTuesdayPickup" [(ngModel)]="object.shop.menuClosureTuesdayPickup"></td>
      </tr>

      <tr>
        <td></td>
        <td>Delivery</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnTuesdayDelivery"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnTuesdayDelivery" [(ngModel)]="object.shop.menuClosureTuesdayDelivery"></td>
      </tr>

      <tr>
        <td></td>
        <td>Revenue</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnTuesdayRevenue"/></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnTuesdayRevenue" [(ngModel)]="object.shop.menuClosureTuesdayRevenue"/></td>
      </tr>


      <tr>
        <td style="width: 64px"></td>
        <th align="left" class="table-sub-header">Wednesday</th>
        <td style="width: 50px"></td>
        <td></td>
      </tr>

      <tr>
        <td><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
        <td>Closure board</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnWednesday"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnWednesday" [(ngModel)]="object.shop.menuClosureWednesday"></td>
      </tr>

      <tr>
        <td></td>
        <td>Allow orders</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnWednesdayOrders"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnWednesdayOrders" [(ngModel)]="object.shop.menuClosureWednesdayOrders"></td>
      </tr>

      <tr>
        <td></td>
        <td>Local</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnWednesdayLocal"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnWednesdayLocal" [(ngModel)]="object.shop.menuClosureWednesdayLocal"></td>
      </tr>

      <tr>
        <td></td>
        <td>Pickup</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnWednesdayPickup"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnWednesdayPickup" [(ngModel)]="object.shop.menuClosureWednesdayPickup"></td>
      </tr>

      <tr>
        <td></td>
        <td>Delivery</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnWednesdayDelivery"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnWednesdayDelivery" [(ngModel)]="object.shop.menuClosureWednesdayDelivery"></td>
      </tr>

      <tr>
        <td></td>
        <td>Revenue</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnWednesdayRevenue"/></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnWednesdayRevenue" [(ngModel)]="object.shop.menuClosureWednesdayRevenue"/></td>
      </tr>


      <tr>
        <td style="width: 64px"></td>
        <th align="left" class="table-sub-header">Thursday</th>
        <td style="width: 50px"></td>
        <td></td>
      </tr>

      <tr>
        <td><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
        <td>Closure board</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnThursday"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnThursday" [(ngModel)]="object.shop.menuClosureThursday"></td>
      </tr>

      <tr>
        <td></td>
        <td>Allow orders</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnThursdayOrders"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnThursdayOrders" [(ngModel)]="object.shop.menuClosureThursdayOrders"></td>
      </tr>

      <tr>
        <td></td>
        <td>Local</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnThursdayLocal"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnThursdayLocal" [(ngModel)]="object.shop.menuClosureThursdayLocal"></td>
      </tr>

      <tr>
        <td></td>
        <td>Pickup</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnThursdayPickup"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnThursdayPickup" [(ngModel)]="object.shop.menuClosureThursdayPickup"></td>
      </tr>

      <tr>
        <td></td>
        <td>Delivery</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnThursdayDelivery"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnThursdayDelivery" [(ngModel)]="object.shop.menuClosureThursdayDelivery"></td>
      </tr>

      <tr>
        <td></td>
        <td>Revenue</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnThursdayRevenue"/></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnThursdayRevenue" [(ngModel)]="object.shop.menuClosureThursdayRevenue"/></td>
      </tr>


      <tr>
        <td style="width: 64px"></td>
        <th align="left" class="table-sub-header">Friday</th>
        <td style="width: 50px"></td>
        <td></td>
      </tr>

      <tr>
        <td><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
        <td>Closure board</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnFriday"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnFriday" [(ngModel)]="object.shop.menuClosureFriday"></td>
      </tr>

      <tr>
        <td></td>
        <td>Allow orders</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnFridayOrders"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnFridayOrders" [(ngModel)]="object.shop.menuClosureFridayOrders"></td>
      </tr>

      <tr>
        <td></td>
        <td>Local</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnFridayLocal"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnFridayLocal" [(ngModel)]="object.shop.menuClosureFridayLocal"></td>
      </tr>

      <tr>
        <td></td>
        <td>Pickup</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnFridayPickup"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnFridayPickup" [(ngModel)]="object.shop.menuClosureFridayPickup"></td>
      </tr>

      <tr>
        <td></td>
        <td>Delivery</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnFridayDelivery"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnFridayDelivery" [(ngModel)]="object.shop.menuClosureFridayDelivery"></td>
      </tr>

      <tr>
        <td></td>
        <td>Revenue</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnFridayRevenue"/></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnFridayRevenue" [(ngModel)]="object.shop.menuClosureFridayRevenue"/></td>
      </tr>


      <tr>
        <td style="width: 64px"></td>
        <th align="left" class="table-sub-header">Saturday</th>
        <td style="width: 50px"></td>
        <td></td>
      </tr>

      <tr>
        <td><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
        <td>Closure board</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSaturday"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSaturday" [(ngModel)]="object.shop.menuClosureSaturday"></td>
      </tr>

      <tr>
        <td></td>
        <td>Allow orders</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSaturdayOrders"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSaturdayOrders" [(ngModel)]="object.shop.menuClosureSaturdayOrders"></td>
      </tr>

      <tr>
        <td></td>
        <td>Local</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSaturdayLocal"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSaturdayLocal" [(ngModel)]="object.shop.menuClosureSaturdayLocal"></td>
      </tr>

      <tr>
        <td></td>
        <td>Pickup</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSaturdayPickup"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSaturdayPickup" [(ngModel)]="object.shop.menuClosureSaturdayPickup"></td>
      </tr>

      <tr>
        <td></td>
        <td>Delivery</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSaturdayDelivery"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSaturdayDelivery" [(ngModel)]="object.shop.menuClosureSaturdayDelivery"></td>
      </tr>

      <tr>
        <td></td>
        <td>Revenue</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSaturdayRevenue"/></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSaturdayRevenue" [(ngModel)]="object.shop.menuClosureSaturdayRevenue"/></td>
      </tr>

      <tr>
        <td style="width: 64px"></td>
        <th align="left" class="table-sub-header">Sunday</th>
        <td style="width: 50px"></td>
        <td></td>
      </tr>

      <tr>
        <td><img class="image-icon" src="assets/icons/shop_light.png" alt="ACTIVE"></td>
        <td>Closure board</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSunday"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSunday" [(ngModel)]="object.shop.menuClosureSunday"></td>
      </tr>

      <tr>
        <td></td>
        <td>Allow orders</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSundayOrders"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSundayOrders" [(ngModel)]="object.shop.menuClosureSundayOrders"></td>
      </tr>

      <tr>
        <td></td>
        <td>Local</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSundayLocal"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSundayLocal" [(ngModel)]="object.shop.menuClosureSundayLocal"></td>
      </tr>

      <tr>
        <td></td>
        <td>Pickup</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSundayPickup"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSundayPickup" [(ngModel)]="object.shop.menuClosureSundayPickup"></td>
      </tr>

      <tr>
        <td></td>
        <td>Delivery</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSundayDelivery"></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSundayDelivery" [(ngModel)]="object.shop.menuClosureSundayDelivery"></td>
      </tr>

      <tr>
        <td></td>
        <td>Revenue</td>
        <td><input class="shop-time" type="checkbox" [(ngModel)]="object.shop.menuClosureOpenOnSundayRevenue"/></td>
        <td><input type="text" [disabled]="!object.shop.menuClosureOpenOnSundayRevenue" [(ngModel)]="object.shop.menuClosureSundayRevenue"/></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
