import {Component, Input} from '@angular/core';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {CatalogNewsVariantCreateObject} from './CatalogNewsVariantCreateObject';
import {CatalogNewsVariant} from '../../../../domain/stock/CatalogNewsVariant';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {IInit} from '../../../abstract/IInit';
import {CatalogNewsProduct} from '../../../../domain/stock/CatalogNewsProduct';
import {NewsComponent} from '../news.component';

@Component({
  selector: 'app-dashboard-catalog-news-variant-editor',
  templateUrl: './catalog-news-variant-editor.component.html',
  styleUrls: ['./catalog-news-variant-editor.component.css']
})
export class CatalogNewsVariantEditorComponent extends AbstractEditorComponent<CatalogNewsVariantCreateObject, CatalogNewsVariant> {
  @Input() newsComponent: NewsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.newsComponent.setNewsVariantEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_NEWS_VARIANT;
  }

  protected getInitableComponent(): IInit {
    return this.newsComponent;
  }

  protected getTheObject(): CatalogNewsVariant {
    return this.object.newsVariant;
  }
}
