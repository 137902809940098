import {HttpClient} from '@angular/common/http';
import {HttpHandler} from '../../handlers/httpHandler';
import {IHaveMyHttpHandler} from '../my-http-handler/IHaveMyHttpHandler';

export abstract class AbstractObjectComponent<O> extends IHaveMyHttpHandler {
  public object: O;

  protected constructor(protected http: HttpClient) {
    super();
  }

  public abstract getApiRootEndpoint(): string;

  public init(): void {
    this.getList();
  }

  protected getList(): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<O>(this.http.get<O>(this.getApiRootEndpoint() + 'editingRootObject', { headers }), (result: O) => {
      this.object = result;

      this.getListDone();
    });
  }

  protected getListDone(): void {

  }
}
