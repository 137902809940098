import {Component, Input, OnInit} from '@angular/core';
import {AbstractListComponent} from '../../abstract/AbstractListComponent';
import {Shop} from '../../../domain/shop/shop';
import {UserShop} from '../../../domain/shop/userShop';
import {HttpClient} from '@angular/common/http';
import {ShopsComponent} from '../shops.component';
import {Defaults} from '../../../constants/defaults';
import {EmployeeEditorComponent} from './employee-editor/employee-editor.component';
import {SearchUserComponent} from './search-user/search-user.component';

@Component({
  selector: 'app-dashboard-shop-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent extends AbstractListComponent<Shop, UserShop> {
  @Input() shopsComponent: ShopsComponent;

  public searchUserComponent: SearchUserComponent;
  public employeeEditor: EmployeeEditorComponent;

  public selectedUserShop: UserShop;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.shopsComponent.setSubComponent(this);
    super.init();
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_USER_SHOP;
  }

  public setEmployeeEditorComponent(employeeEditor: EmployeeEditorComponent): void {
    this.employeeEditor = employeeEditor;
  }

  public setSearchUserComponent(searchUserComponent: SearchUserComponent): void {
    this.searchUserComponent = searchUserComponent;
  }

  public selectUserShop(userShop: UserShop): void {
    this.selectedUserShop = userShop;
  }

}
