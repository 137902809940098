<app-dashboard-header mainComponent="menu"></app-dashboard-header>
<ng-container *ngIf="user != null && user.menuAppEnabled">
  <app-dashboard-menu-navigation [leftComponent]='getComponent()' [menuComponent]="this"></app-dashboard-menu-navigation>
</ng-container>
<main>
  <ng-container *ngIf="user != null && user.menuAppEnabled">
    <div class="menu-full-tools" style="z-index: 2">
      <app-dashboard-menu-editing-menu [menuComponent]="this"></app-dashboard-menu-editing-menu>
    </div>
    <app-dashboard-menu-statistics *ngIf="getComponent() == 'statistics'" [menuComponent]='this'></app-dashboard-menu-statistics>
    <app-dashboard-menu-edit *ngIf="getComponent() == 'edit'" [menuComponent]='this' [parameter]='getParameter()'></app-dashboard-menu-edit>
    <app-dashboard-menu-variants *ngIf="getComponent() == 'variants'" [menuComponent]='this' [parameter]="getParameter()"></app-dashboard-menu-variants>
    <app-dashboard-menu-supplements *ngIf="getComponent() == 'supplements'" [menuComponent]='this' [parameter]='getParameter()'></app-dashboard-menu-supplements>
    <app-dashboard-menu-condiments *ngIf="getComponent() == 'condiments'" [menuComponent]='this' [parameter]='getParameter()'></app-dashboard-menu-condiments>
    <app-dashboard-menu-allergens *ngIf="getComponent() == 'allergens'" [menuComponent]='this' [parameter]='getParameter()'></app-dashboard-menu-allergens>
    <app-dashboard-menu-promotion *ngIf="getComponent() == 'promotion'" [menuComponent]='this' [parameter]='getParameter()'></app-dashboard-menu-promotion>
    <app-dashboard-menu-discount-coupon *ngIf="getComponent() == 'discount-coupon'" [menuComponent]='this'></app-dashboard-menu-discount-coupon>
    <app-dashboard-menu-availability *ngIf="getComponent() == 'availability'" [menuComponent]='this' [parameter]='getParameter()'></app-dashboard-menu-availability>
    <app-dashboard-menu-zones *ngIf="getComponent() == 'zones'" [menuComponent]='this' [parameter]='getParameter()'></app-dashboard-menu-zones>
    <app-dashboard-menu-qr *ngIf="getComponent() == 'qrconfig'" [menuComponent]='this' [parameter]='getParameter()'></app-dashboard-menu-qr>
    <app-dashboard-menu-tablets *ngIf="getComponent() == 'tablets'" [menuComponent]='this' [parameter]='getParameter()'></app-dashboard-menu-tablets>
    <app-dashboard-menu-delivery *ngIf="getComponent() == 'delivery'" [menuComponent]='this'></app-dashboard-menu-delivery>
    <app-dashboard-menu-pos-tablets *ngIf="getComponent() == 'pos-tablets'" [menuComponent]='this'></app-dashboard-menu-pos-tablets>
    <app-dashboard-menu-pos-printers *ngIf="getComponent() == 'pos-printers'" [menuComponent]='this'></app-dashboard-menu-pos-printers>
    <app-empty *ngIf="getComponent() == 'empty'"></app-empty>
    <div class="menu-middle-tools">
      <div class="menu-middle-tools-box">
      </div>
      <div class="menu-middle-tools-box">
      </div>
    </div>
    <!--<app-dashboard-menu-select *ngIf="getRightComponent() == 'select'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-select>
    <app-dashboard-menu-edit *ngIf="getRightComponent() == 'edit'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-edit>
    <app-dashboard-menu-supplements *ngIf="getRightComponent() == 'supplements'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-supplements>
    <app-dashboard-menu-condiments *ngIf="getRightComponent() == 'condiments'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-condiments>
    <app-dashboard-menu-allergens *ngIf="getRightComponent() == 'allergens'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-allergens>
    <app-dashboard-menu-availability *ngIf="getRightComponent() == 'availability'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-availability>
    <app-dashboard-menu-zones *ngIf="getRightComponent() == 'zones'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-zones>
    <app-dashboard-menu-printers *ngIf="getRightComponent() == 'printers'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-printers>
    <app-dashboard-menu-images *ngIf="getRightComponent() == 'images'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-images>
    <app-dashboard-menu-qr *ngIf="getRightComponent() == 'qrconfig'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-qr>
    <app-dashboard-menu-tablets *ngIf="getRightComponent() == 'tablets'" [menuComponent]="this" [parameter]='getRightParameter()' pageIndex="1"></app-dashboard-menu-tablets>
    <app-empty *ngIf="getRightComponent() == 'empty'" pageIndex="1"></app-empty>-->
  </ng-container>
  <ng-container *ngIf="user != null && !user.menuAppEnabled">
    <div>
      <div style="display: flex; align-content: center; justify-content: center; padding-bottom: 15px">
        <img width="128" height="128" src="assets/illustrations/streamline-icon-program-error-2@128x128.png">
      </div>
      <h3 style="clear: both">Sorry, menu application is not activated for this user.</h3>
    </div>
  </ng-container>
</main>

<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
