import {Component, Input, OnInit} from '@angular/core';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {CatalogNewsVariantOptionCreateObject} from './CatalogNewsVariantOptionCreateObject';
import {CatalogNewsVariantOption} from '../../../../domain/stock/CatalogNewsVariantOption';
import {HttpClient} from '@angular/common/http';
import {NewsComponent} from '../news.component';
import {Defaults} from '../../../../constants/defaults';
import {IInit} from '../../../abstract/IInit';
import {CatalogNewsVariant} from '../../../../domain/stock/CatalogNewsVariant';

@Component({
  selector: 'app-dashboard-catalog-news-variant-option-editor',
  templateUrl: './catalog-news-variant-option-editor.component.html',
  styleUrls: ['./catalog-news-variant-option-editor.component.css']
})
export class CatalogNewsVariantOptionEditorComponent extends AbstractEditorComponent<CatalogNewsVariantOptionCreateObject, CatalogNewsVariantOption> {
  @Input() newsComponent: NewsComponent;

  public variantId: string;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.newsComponent.setNewsVariantOptionEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_NEWS_VARIANT_OPTION;
  }

  protected getInitableComponent(): IInit {
    return this.newsComponent;
  }

  protected getTheObject(): CatalogNewsVariantOption {
    return this.object.newsVariantOption;
  }
}
