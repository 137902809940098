import {Component, Input} from '@angular/core';
import {CatalogEditComponent} from '../../../edit.component';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../../constants/defaults';
import {CatalogProduct} from '../../../../../../domain/stock/CatalogProduct';
import {AbstractEditorComponent} from '../../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-catalog-product-editor',
  templateUrl: './catalog-product-editor.component.html',
  styleUrls: ['./catalog-product-editor.component.css']
})
export class CatalogProductEditorComponent extends AbstractEditorComponent<CatalogProduct, CatalogProduct> {
  @Input() public editComponent: CatalogEditComponent;
  public imageRoot = Defaults.SERVER_API_CATALOG_PRODUCT;

  constructor(private router: Router, http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setCatalogProductEditorComponent(this);
  }

  /* public fileSelected(files: any): void {
    if (files == null || files.length === 0) {
      return;
    }

    let headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<CatalogProductImage>(this.http.get<CatalogProductImage>(Defaults.SERVER_API_CATALOG_PRODUCT + 'image/createNew/' + this.product.id, { headers }), (result: CatalogProductImage) => {
      const formDate = new FormData();
      formDate.append('file', files[0]);

      headers = new HttpHeaders({
        sessionId: Defaults.getSessionId(),
        sessionToken: Defaults.getSessionToken(),
        productId: this.product.id,
        imageId: result.id
      });

      this.myHttpHandlerComponent.get<CatalogProductImage>(this.http.post<CatalogProductImage>(Defaults.SERVER_API_CATALOG_PRODUCT + 'image/upload', formDate, { headers }), (result: CatalogProductImage) => {
        result.sequence = this.product.productImages.length;
        this.product.productImages.push(result);
      });

    });
  } */

  /* public moveLeft(image: CatalogProductImage): void {
    const s = image.sequence;
    if (s <= 0) {
      return;
    }

    for (const i of this.product.productImages) {
      if (i.sequence === s - 1) {
        i.sequence ++;
      }
    }

    image.sequence --;

    this.reorder();
  } */

  /* public moveRight(image: CatalogProductImage): void {
    const s = image.sequence;
    if (s >= this.product.productImages.length - 1) {
      return;
    }

    for (const i of this.product.productImages) {
      if (i.sequence === s + 1) {
        i.sequence --;
      }
    }

    image.sequence ++;

    this.reorder();
  } */

  /* private reorder(): void {
    this.product.productImages.sort((image1, image2) => image1.sequence - image2.sequence);
  } */

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_PRODUCT;
  }

  protected getTheObject(): CatalogProduct {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }
}
