import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {GalleryEditComponent} from '../../edit.component';
import {GalleryAlbum} from '../../../../../domain/gallery/GalleryAlbum';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-gallery-create-album',
  templateUrl: './create-album.component.html',
  styleUrls: ['./create-album.component.css']
})
export class CreateAlbumComponent extends AbstractEditorComponent<GalleryAlbum, GalleryAlbum>{
  @Input() public editComponent: GalleryEditComponent;

  constructor(private router: Router, http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setCreateAlbumComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_GALLERY_ALBUM;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }

  protected getTheObject(): GalleryAlbum {
    return this.object;
  }
}
