export class DateTimeUtil {

  public static customToIso(s: string): string {
    const date = new Date(s);

    const yyyy = date.getFullYear().toString();
    const mm = (date.getMonth()+1).toString(); // getMonth() is zero-based
    const dd  = date.getDate().toString();

    return yyyy + '-' + (mm[1]?mm:"0"+mm[0]) + '-' + (dd[1]?dd:"0"+dd[0]) + 'T' + date.toLocaleTimeString();
  }

  public static isoToCustom(s: string): string {
    const date = new Date(s);

    const yyyy = date.getFullYear().toString();
    const mm = (date.getMonth()+1).toString(); // getMonth() is zero-based
    const dd  = date.getDate().toString();

    return yyyy + '/' + (mm[1]?mm:"0"+mm[0]) + '/' + (dd[1]?dd:"0"+dd[0]) + ' ' + date.toLocaleTimeString().slice(0, 5);
  }
}
