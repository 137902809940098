<app-normal-popup [editorComponent]="this" title="menu">
  <ng-container *ngIf="object != null && object.user != null && object.shops != null">
    <table>
      <tr>
        <td width="64"></td>
        <th align="left" class="table-sub-header">Select shop</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
        <td>Shop</td>
        <td>
          <label>
            <select [(ngModel)]="object.user.selectedMenuShopId">
              <ng-container *ngFor="let shop of object.shops"><option  ngValue="{{shop.id}}">{{shop.name}}</option></ng-container>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td width="64"></td>
        <th align="left" class="table-sub-header">Select menu</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let shop of object.shops">
        <ng-container *ngIf="shop.id === object.user.selectedMenuShopId">
          <tr>
            <td align="right"><img class="image-icon" src="assets/icons/free.png" alt="ACTIVE"></td>
            <td>Language</td>
            <td>
              <label>
                <select [(ngModel)]="object.user.selectedMenuLanguageCode">
                  <ng-container *ngFor="let shopLanguage of shop.shopLanguages"><option  ngValue="{{shopLanguage.languageCode}}">{{shopLanguage.language.name}}</option></ng-container>
                </select>
              </label>
            </td>
          </tr>

          <tr>
            <td></td>
            <td>Menu</td>
            <td><select [(ngModel)]="object.user.selectedMenuId">
              <option *ngFor="let menu of shop.menus" [ngValue]="menu.id">{{getMenuDetail(menu.details, object.user.selectedMenuLanguageCode).name}}</option>
            </select></td>
          </tr>
        </ng-container>
      </ng-container>

    </table>
  </ng-container>
</app-normal-popup>
