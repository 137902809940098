import {HttpHandler} from '../../handlers/httpHandler';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Defaults} from '../../constants/defaults';
import {IHaveMyHttpHandler} from '../my-http-handler/IHaveMyHttpHandler';
import {EditorMode} from './EditorMode';
import {IInit} from './IInit';
import {AbstractObject} from '../../domain/common/AbstractObject';

export abstract class AbstractEditorComponent<CO, O extends AbstractObject> extends IHaveMyHttpHandler {

  public mode: EditorMode;
  public object: CO;

  public isOpen = false;

  abstract getApiRootEndpoint(): string;
  protected abstract getTheObject(): O;
  protected abstract getInitableComponent(): IInit;

  protected constructor(protected http: HttpClient) {
    super();
  }

  public open(mode: EditorMode, parentId: string, objectId: string): void {
    this.mode = mode;

    if ( mode === EditorMode.CREATE || this.mode === EditorMode.COPY ) {
      this.getNewObject(parentId);
    }
    else if ( mode === EditorMode.SELECT ) {
      this.getSelectObject();
    }
    else {
      this.getObject(objectId);
    }
  }

  private getNewObject(parentId: string): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<CO>(this.http.get<CO>(this.getApiRootEndpoint() + 'createNew/' + parentId, { headers }), (result: CO) => {
      this.object = result;

      this.getObjectDone();

      this.isOpen = true;
    });
  }

  private getSelectObject(): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<CO>(this.http.get<CO>(this.getApiRootEndpoint() + 'createNew', { headers }), (result: CO) => {
      this.object = result;

      this.getObjectDone();

      this.isOpen = true;
    });
  }

  private getObject(objectId: string): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<CO>(this.http.get<CO>(this.getApiRootEndpoint() + objectId, { headers }), (result: CO) => {
      this.object = result;

      this.getObjectDone();

      this.isOpen = true;
    });
  }

  protected getObjectDone(): void {

  }

  public submit(): void {
    this.preSubmit();

    const headers = new HttpHeaders({
      sessionId: Defaults.getSessionId(),
      sessionToken: Defaults.getSessionToken()
    });

    if ( this.mode === EditorMode.CREATE || this.mode === EditorMode.COPY ) {
      this.myHttpHandlerComponent.get<O>(this.http.post<O>(this.getApiRootEndpoint(), this.getTheObject(), { headers }), (result: O) => {
        this.submitDone();

        this.close();
        this.getInitableComponent().init();
      });
    }
    else if ( this.mode === EditorMode.UPDATE ) {
      this.myHttpHandlerComponent.get<O>(this.http.put<O>(this.getApiRootEndpoint(), this.getTheObject(), { headers }), (result: O) => {
        this.submitDone();

        this.close();
        this.getInitableComponent().init();
      });
    }
    else if ( this.mode === EditorMode.DELETE ) {
      this.myHttpHandlerComponent.get<boolean>(this.http.delete<boolean>(this.getApiRootEndpoint() + this.getTheObject().id, { headers }), (result: boolean) => {
        this.submitDone();

        this.close();
        this.getInitableComponent().init();
      });
    }
  }

  protected preSubmit(): void {

  }

  protected submitDone(): void {

  }

  public close(): void {
    this.isOpen = false;
  }

  public titleText(text: string): string {
    switch (this.mode) {
      case EditorMode.CREATE: return 'Create ' + text;
      case EditorMode.UPDATE: return 'Update ' + text;
      case EditorMode.DELETE: return 'Delete ' + text;
      case EditorMode.SELECT: return 'Select ' + text;
      case EditorMode.COPY: return 'Copy ' + text;
    }
  }

  public buttonText(): string {
    switch (this.mode) {
      case EditorMode.CREATE: return 'CREATE';
      case EditorMode.UPDATE: return 'SAVE';
      case EditorMode.DELETE: return 'DELETE';
      case EditorMode.SELECT: return 'SAVE SELECTION';
      case EditorMode.COPY: return 'COPY';
    }
  }

  public positiveButtonColor(): string {
    switch (this.mode) {
      case EditorMode.CREATE: return 'button-green';
      case EditorMode.UPDATE: return 'button-green';
      case EditorMode.DELETE: return 'button-red';
      case EditorMode.SELECT: return 'button-blue';
      case EditorMode.COPY: return 'button-green';
    }
  }

  public negativeButtonColor(): string {
    switch (this.mode) {
      case EditorMode.CREATE: return 'button-red';
      case EditorMode.UPDATE: return 'button-red';
      case EditorMode.DELETE: return 'button-green';
      case EditorMode.COPY: return 'button-red';
    }
  }
}
