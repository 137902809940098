<app-normal-popup [editorComponent]="this" title="image">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Image Details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td rowspan="2" valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
        <tr>
          <td valign="top" aria-autocomplete="none">Description</td>
          <td><textarea rows="3" style="margin-bottom: 4px; resize: vertical" [(ngModel)]="item.description"></textarea></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <td>File</td>
        <td><form [formGroup]="uploadForm" (change)="onFileSelectGallery($event)"><input name="image" type="file" accept="image/*"></form></td>
      </tr>
    </table>
  </ng-container>
</app-normal-popup>
