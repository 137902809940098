import {Component, Input} from '@angular/core';
import {QrComponent} from '../qr.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {QrConfiguration} from '../../../../domain/menu/qrConfiguration';
import {Shop} from '../../../../domain/shop/shop';
import {HttpHandler} from '../../../../handlers/httpHandler';
import {QrConfigurationCreateObject} from '../../../../domain/menu/QrConfigurationCreateObject';
import {IHaveMyHttpHandler} from '../../../my-http-handler/IHaveMyHttpHandler';
import {MenuDetails} from '../../../../domain/menu/menuDetails';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-create-qr',
  templateUrl: './menu-qr-config-editor.component.html',
  styleUrls: ['./menu-qr-config-editor.component.css']
})
export class MenuQrConfigEditor extends AbstractEditorComponent<QrConfigurationCreateObject, QrConfiguration> {
  @Input() qrComponent: QrComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.qrComponent.setMenuQrConfigEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_QR_CONFIGURATION;
  }

  protected getInitableComponent(): IInit {
    return this.qrComponent;
  }

  protected getTheObject(): QrConfiguration {
    return this.object.qrConfiguration;
  }

  public getMenuDetail(menuDetails: MenuDetails[], languageCode: String): MenuDetails {
    for ( const menuDetail of menuDetails ) {
      if ( menuDetail.languageCode === languageCode ) {
        return menuDetail;
      }
    }

    return menuDetails[0];
  }
}
