<div>
  <div class="menu-full-tools">
    <div class="menu-title">
      <h3>Availability</h3>
    </div>
    <div class="menu-tools">
    </div>
  </div>
  <div id="half-window" class="menu-half-0-content">
    <div class="availability-item">
      <div class="availability-item-stroke" *ngFor="let product of products">
        <h4>{{product.productDetails[0].name}}</h4>
        <div class="availability-item-container">
          <div class="availability-item-line"></div>
          <div class="availability-item-slot" *ngFor="let timeSlot of product.timeSlots" style="left: calc(100% / 1440 * {{timeSlot.start}}); right: calc(100% - (100% / 1440 * {{timeSlot.stop}})"><p>{{timeSlot.startTime}} - {{timeSlot.stopTime}}</p></div>
        </div>
      </div>
    </div>
    <app-trailer></app-trailer>
  </div>

  <div>
    <!-- TODO add popup components here -->
  </div>
</div>
