import {Component, Input, OnInit} from '@angular/core';
import {AbstractEditorComponent} from '../../abstract/AbstractEditorComponent';
import {MyHttpHandlerComponent} from '../../my-http-handler/my-http-handler.component';
import {IMyHttpHandlerReceiver} from '../IMyHttpHandlerReceiver';

@Component({
  selector: 'app-normal-popup',
  templateUrl: './normal-popup.component.html',
  styleUrls: ['./normal-popup.component.css']
})
export class NormalPopupComponent implements IMyHttpHandlerReceiver, OnInit {
  @Input() public editorComponent: AbstractEditorComponent<any, any>;
  @Input() title: string;
  @Input() backgroundLock: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public setMyHttpHandler(myHttpHandlerComponent: MyHttpHandlerComponent): void {
    this.editorComponent.setMyHttpHandlerComponent(myHttpHandlerComponent);
  }

  forceClose(forceClose: boolean): void {
    this.editorComponent.forceClose(forceClose);
  }

  public close() {
    if ( !this.backgroundLock ) {
      this.editorComponent.close();
    }
  }

}
