import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {EditComponent} from '../../edit.component';
import {ProductBulkRequestObject} from '../../../../../domain/menu/ProductBulkRequestObject';
import {Category} from '../../../../../domain/menu/category';
import {ProductBulkProductSelectObject} from '../../../../../domain/menu/ProductBulkProductSelectObject';
import {ProductBulkCreateObject} from '../../../../../domain/menu/ProductBulkCreateObject';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-copy-relations',
  templateUrl: './copy-relations.component.html',
  styleUrls: ['./copy-relations.component.css']
})
export class CopyRelationsComponent extends AbstractEditorComponent<ProductBulkCreateObject, ProductBulkRequestObject> {
  @Input() editComponent: EditComponent;

  public isOpen = false;

  public categories: Category[];
  public productBulkProductSelectObjects: ProductBulkProductSelectObject[];
  public productBulkRequestObject: ProductBulkRequestObject;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setCopyRelationsComponent(this);
  }


  protected getObjectDone() {
    super.getObjectDone();

    this.productBulkRequestObject = new ProductBulkRequestObject();
    this.productBulkRequestObject.productId = this.object.productId;
    this.productBulkRequestObject.productIds = Array();

    this.categories = this.object.categories;
    this.productBulkProductSelectObjects = Array();

    for ( const category of this.object.categories ) {
      for ( const product of category.products ) {
        if ( product.id != this.object.productId ) {
          const productBulkProductSelectObject = new ProductBulkProductSelectObject();
          productBulkProductSelectObject.product = product;
          productBulkProductSelectObject.selected = false;
          this.productBulkProductSelectObjects.push(productBulkProductSelectObject);
        }
      }
    }
  }

  public close(): void {
    super.close();

    this.categories = null;
    this.productBulkProductSelectObjects = null;
    this.productBulkRequestObject = null;
    this.isOpen = false;
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT_BULK;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }

  protected getTheObject(): ProductBulkRequestObject {
    for ( const productBulkProductSelectObject of this.productBulkProductSelectObjects ) {
      if ( productBulkProductSelectObject.selected ) {
        this.productBulkRequestObject.productIds.push(productBulkProductSelectObject.product.id);
      }
    }

    return this.productBulkRequestObject;
  }
}
