import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IInit} from '../../abstract/IInit';
import {Defaults} from '../../../constants/defaults';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {StockComponent} from '../stock.component';
import {CatalogPromotion} from '../../../domain/stock/CatalogPromotion';
import {CatalogPromotionEditorComponent} from './promotion-editor/catalog-promotion-editor.component';
import {Catalog} from '../../../domain/stock/Catalog';

@Component({
  selector: 'app-dashboard-catalog-promotion',
  templateUrl: './catalog-promotion.component.html',
  styleUrls: ['./catalog-promotion.component.css']
})
export class CatalogPromotionComponent extends SortableObjectPanel<Catalog, CatalogPromotion> implements IInit {
  @Input() stockComponent: StockComponent;

  public promotionEditorComponent: CatalogPromotionEditorComponent;

  public selectedPromotion: CatalogPromotion;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.stockComponent.setSubComponent(this);

    super.init();
  }

  public setCatalogPromotionEditorComponent(promotionEditorComponent: CatalogPromotionEditorComponent): void {
    this.promotionEditorComponent = promotionEditorComponent;
  }

  public selectPromotion(promotion: CatalogPromotion): void {
    this.selectedPromotion = promotion;
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_PROMOTION;
  }

}
