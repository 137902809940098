import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {Zone} from '../../../../domain/menu/zone';
import {ZonesComponent} from '../zones.component';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-create-zone',
  templateUrl: './menu-zone-editor.component.html',
  styleUrls: ['./menu-zone-editor.component.css']
})
export class MenuZoneEditor extends AbstractEditorComponent<Zone, Zone>{
  @Input() zonesComponent: ZonesComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.zonesComponent.setMenuZoneEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_ZONE;
  }

  protected getInitableComponent(): IInit {
    return this.zonesComponent;
  }

  protected getTheObject(): Zone {
    return this.object;
  }


}
