import {Component, Input} from '@angular/core';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {IInit} from '../../../abstract/IInit';
import {EditorMode} from '../../../abstract/EditorMode';
import {CatalogNewsChannel} from '../CatalogNewsChannel';
import {NewsComponent} from '../news.component';
import {HttpHandler} from '../../../../handlers/httpHandler';
import {CatalogNewsStartObject} from '../CatalogNewsStartObject';

@Component({
  selector: 'app-dashboard-catalog-news-channel',
  templateUrl: './news-channel.component.html',
  styleUrls: ['./news-channel.component.css']
})
export class NewsChannelComponent extends AbstractEditorComponent<any, any> {
  @Input() newsComponent: NewsComponent;

  private pusher: any;

  public startObject: CatalogNewsStartObject;
  public current: number;
  public done: boolean;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.newsComponent.setNewsChannelComponent(this);
  }

  public openToSend(newsChannel: CatalogNewsChannel): void {
    this.startObject = null;
    this.current = 0;
    this.done = false;

    // @ts-ignore
    this.pusher = new Pusher('002883e435e9ef4ed05b', {
      cluster: 'eu'
    });

    const statusComponent = this;

    const channel = this.pusher.subscribe(newsChannel.id);
    channel.bind('SEND_EMAIL_UPDATE', function(data) {
      if ( data > statusComponent.current ) {
        statusComponent.current = data;
      }

      if ( data === -1 ) {
        statusComponent.done = true;
      }
    });

    const headers = HttpHandler.getSessionHeaders();
    this.myHttpHandlerComponent.get<CatalogNewsStartObject>(this.http.get<CatalogNewsStartObject>(this.getApiRootEndpoint() + 'notifyClients/' + newsChannel.id, { headers }), (result: CatalogNewsStartObject) => {
      this.startObject = result;
      this.isOpen = true;
    });
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_NEWS;
  }

  protected getInitableComponent(): IInit {
    return null;
  }

  protected getTheObject(): any {
    return null;
  }

  public titleText(text: string): string {
    return text;
  }

  public positiveButtonColor(): string {
    if ( this.mode === EditorMode.OTHERS ) {
      return 'button-blue';
    }

    return super.positiveButtonColor();
  }

  public buttonText(): string {
    if ( this.mode === EditorMode.OTHERS ) {
      return 'SEND EMAILS';
    }

    return super.buttonText();
  }

  public submit() {

  }

}
