<app-normal-popup [editorComponent]="this" title="email template">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Template status</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Status</td>
        <td>
          <label>
            <select [(ngModel)]="object.active">
              <option value="A">Active</option>
              <option value="I">Inactive</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Template details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td rowspan="2" valign="top" style="width: 64px"><img width="40" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
        <tr>
          <td valign="top" aria-autocomplete="none">Description</td>
          <td><textarea rows="3" [(ngModel)]="item.description"></textarea></td>
        </tr>
        <tr>
          <td></td>
          <td>Subject / body</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.subject"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="2"><textarea rows="7" [(ngModel)]="item.content"></textarea></td>
        </tr>
      </ng-container>
    </table>
  </ng-container>
</app-normal-popup>
