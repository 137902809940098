import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Shop} from '../../../domain/shop/shop';
import {Defaults} from '../../../constants/defaults';
import {MenuPosPrintZoneEditor} from './popups/create-print-zone/menu-pos-print-zone-editor.component';
import {PosPrintZone} from '../../../domain/pos/PosPrintZone';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {MenuPosPrinterEditor} from './popups/create-printer/menu-pos-printer-editor.component';
import {MenuComponent} from '../../menu/menu.component';
import {MenuSubComponent} from '../MenuSubComponent';

@Component({
  selector: 'app-dashboard-menu-pos-printers',
  templateUrl: './printers.component.html',
  styleUrls: ['./printers.component.css']
})
export class PosPrintersComponent extends SortableObjectPanel<Shop, PosPrintZone> implements MenuSubComponent {
  @Input() menuComponent: MenuComponent;

  public selectedPrintZone: PosPrintZone;

  public posPrintZoneEditor: MenuPosPrintZoneEditor;
  public posPrinterEditor: MenuPosPrinterEditor;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);
    super.init();
  }

  protected getListDone() {
    super.getListDone();

    if ( this.selectedPrintZone != null ) {
      for ( const posPrintZone of this.listObject.posPrintZones ) {
        if ( this.selectedPrintZone.id === posPrintZone.id ) {
          this.selectPrintZone(posPrintZone);
        }
      }
    }
  }

  public selectPrintZone(printZone: PosPrintZone): void {
    this.selectedPrintZone = printZone;
  }

  public getPrintZoneBaseEndpoint(): string {
    return Defaults.SERVER_API_POS_PRINT_ZONE;
  }

  public getPrinterBaseEndpoint(): string {
    return Defaults.SERVER_API_POS_PRINT_PRINTER;
  }

  public setMenuPosPrintZoneEditor(posPrintZoneEditor: MenuPosPrintZoneEditor): void {
    this.posPrintZoneEditor = posPrintZoneEditor;
  }

  public setMenuPosPrinterEditor(posPrinterEditor: MenuPosPrinterEditor): void {
    this.posPrinterEditor = posPrinterEditor;
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_POS_PRINT_ZONE;
  }

}
