import {Component, Input} from '@angular/core';
import {EmployeeComponent} from '../employee.component';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {UserShop} from '../../../../domain/shop/userShop';
import {HttpClient} from '@angular/common/http';
import {IInit} from '../../../abstract/IInit';
import {Defaults} from '../../../../constants/defaults';
import {EditorMode} from '../../../abstract/EditorMode';

@Component({
  selector: 'app-dashboard-shop-employee-editor',
  templateUrl: './employee-editor.component.html',
  styleUrls: ['./employee-editor.component.css']
})
export class EmployeeEditorComponent extends AbstractEditorComponent<UserShop, UserShop>{
  @Input() employeeComponent: EmployeeComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.employeeComponent.setEmployeeEditorComponent(this);
  }

  public openCreate(userShop: UserShop): void {
    this.mode = EditorMode.CREATE;
    this.object = userShop;
    this.isOpen = true;
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_USER_SHOP;
  }

  protected getInitableComponent(): IInit {
    return this.employeeComponent;
  }

  protected getTheObject(): UserShop {
    return this.object;
  }

}
