import {Component, Input} from '@angular/core';
import {Shop} from '../../../../domain/shop/shop';
import {HttpClient} from '@angular/common/http';
import {HttpHandler} from '../../../../handlers/httpHandler';
import {Defaults} from '../../../../constants/defaults';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../abstract/IInit';
import {AbstractEditingSelectionComponent} from '../../../menu/editing-menu/AbstractEditingSelectionComponent';
import {ShopCreateObject} from './ShopCreateObject';

@Component({
  selector: 'app-dashboard-shop-shop-editor',
  templateUrl: './shop-editor.component.html',
  styleUrls: ['./shop-editor.component.css']
})
export class ShopEditorComponent extends AbstractEditorComponent<ShopCreateObject, Shop>{
  @Input() public editingSelectionComponent: AbstractEditingSelectionComponent<ShopCreateObject, Shop>;

  public imageRoot = Defaults.SERVER_API_SHOP;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editingSelectionComponent.setAbstractEditorComponent(this);
  }

  public onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      const headers = HttpHandler.getSessionHeaders();

      const formDate = new FormData();
      formDate.append('file', file);

      this.myHttpHandlerComponent.get<ShopCreateObject>(this.http.post<ShopCreateObject>(Defaults.SERVER_API_SHOP + this.object.shop.id + '/uploadLogo', formDate, { headers }), (result: ShopCreateObject) => {
        this.object = result;
      });
    }
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_SHOP;
  }

  protected getInitableComponent(): IInit {
    return this.editingSelectionComponent;
  }

  protected getTheObject(): Shop {
    return this.object.shop;
  }
}
