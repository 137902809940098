<app-normal-popup [editorComponent]="this" title="menu">
  <table>
    <ng-container *ngIf="object != null">
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Menu details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0px 0px 8px" src="assets/flags/{{item.languageCode}}-s.jpg"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.name"></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Payment details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let item of object.details">
        <tr>
          <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0px 0px 8px"></td>
          <td>Cash text</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.cashOptionsText"></td>
        </tr>
        <tr>
          <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0px 0px 8px"></td>
          <td>Online text</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="item.onlineOptionsText"></td>
        </tr>
        <tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Menu qr theme</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Background color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.backgroundColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Header color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.headerColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Header title color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.headerTitleColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Category name color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.categoryNameColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Selected category name color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.selectedCategoryNameColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product name color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.productNameColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product description color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.productDescriptionColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product price color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.productPriceColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product selection color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.productSelectionColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product border color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.productBorderColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product border size</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.productBorderSize"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product shadow color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.productShadowColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product shadow size</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.productShadowSize"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product shadow x offset</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.productShadowXOffset"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product shadow y offset</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.productShadowYOffset"></td>
      </tr>

      <tr>
        <td></td>
        <td>Product background color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.productBackgroundColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Popup background color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.popupBackgroundColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Popup border color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.popupBorderColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Popup border size</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.popupBorderSize"></td>
      </tr>

      <tr>
        <td></td>
        <td>Popup shadow color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.popupShadowColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Popup shadow size</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.popupShadowSize"></td>
      </tr>

      <tr>
        <td></td>
        <td>Popup shadow x offset</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.popupShadowXOffset"></td>
      </tr>

      <tr>
        <td></td>
        <td>Popup shadow y offset</td>
        <td><input type="number" autocomplete="none" [(ngModel)]="object.popupShadowYOffset"></td>
      </tr>

      <tr>
        <td></td>
        <td>Popup button color</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.popupButtonColor"></td>
      </tr>

      <tr>
        <td></td>
        <td>Text color light</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.textColorLight"></td>
      </tr>

      <tr>
        <td></td>
        <td>Text color dark</td>
        <td><input type="color" autocomplete="none" [(ngModel)]="object.textColorDark"></td>
      </tr>

    </ng-container>

  </table>
</app-normal-popup>
<app-dashboard-select-shop1 [iHaveSelectShopComponent]="this"></app-dashboard-select-shop1>
<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
