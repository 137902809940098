import {Component, Input, OnInit} from '@angular/core';
import {CatalogEmailConfigurationsComponent} from '../catalog-email-configurations.component';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {HttpClient} from '@angular/common/http';
import {IInit} from '../../../abstract/IInit';
import {Defaults} from '../../../../constants/defaults';
import {CatalogEmailConfiguration} from '../../../../domain/stock/CatalogEmailConfiguration';

@Component({
  selector: 'app-dashboard-stock-email-configuration-editor',
  templateUrl: './catalog-email-configuration-editor.component.html',
  styleUrls: ['./catalog-email-configuration-editor.component.css']
})
export class CatalogEmailConfigurationEditorComponent extends AbstractEditorComponent<CatalogEmailConfiguration, CatalogEmailConfiguration>{
  @Input() emailConfigurationsComponent: CatalogEmailConfigurationsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.emailConfigurationsComponent.setCatalogEmailConfigurationEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_EMAIL_CONFIGURATION;
  }

  protected getInitableComponent(): IInit {
    return this.emailConfigurationsComponent;
  }

  protected getTheObject(): CatalogEmailConfiguration {
    return this.object;
  }

}
