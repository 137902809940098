import {Component, Input, OnInit} from '@angular/core';
import {MenuComponent} from '../menu.component';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.css']
})
export class EmptyComponent implements OnInit {
  @Input() menuComponent: MenuComponent;

  constructor() { }

  ngOnInit(): void {
  }

  public onItemSelected(sender: number) {
  }

}
