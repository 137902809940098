import {Component, Input, OnInit} from '@angular/core';
import {MenuComponent} from '../menu.component';

@Component({
  selector: 'app-dashboard-menu-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  constructor() { }

  ngOnInit(): void {
  }

}
