import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpHandler} from '../../../handlers/httpHandler';
import {Defaults} from '../../../constants/defaults';
import {Gallery} from '../../../domain/gallery/Gallery';
import {CreateAlbumComponent} from './subpanels/create-album/create-album.component';
import {CreateImageComponent} from './subpanels/create-image/create-image.component';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {MenuSubComponent} from '../../menu/MenuSubComponent';
import {IInit} from '../../abstract/IInit';
import {GalleryAlbum} from '../../../domain/gallery/GalleryAlbum';
import {GalleryComponent} from '../gallery.component';
import {GalleryImage} from '../../../domain/gallery/GalleryImage';
import {Menu} from '../../../domain/menu/menu';
import {Category} from '../../../domain/menu/category';

@Component({
  selector: 'app-dashboard-gallery-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class GalleryEditComponent extends SortableObjectPanel<Gallery, GalleryAlbum> implements MenuSubComponent, OnChanges, IInit {
  @Input() galleryComponent: GalleryComponent;
  @Input() public parameter: string;

  public SERVER_CONTENT = Defaults.SERVER_GALLERY_CONTENT;

  public albumEditor: CreateAlbumComponent;
  public imageEditor: CreateImageComponent;

  public gallery: Gallery;
  public album: GalleryAlbum;
  public albums: GalleryAlbum[];
  public images: GalleryImage[];
  public selectedAlbum: GalleryAlbum;
  public selectedImage: GalleryImage;

  constructor(http: HttpClient) {
    super(http);
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parameter) {
      this.init();
    }
  }

  public init(): void {
    if ( this.parameter === 'root' ) {
      this.getGallery();
    }
    else {
      this.getAlbum();
    }
  }

  public getGallery(): void {
    if ( this.myHttpHandlerComponent == null ) {
      return;
    }

    const headers = new HttpHeaders({
      sessionId: Defaults.getSessionId(),
      sessionToken: Defaults.getSessionToken()
    });

    this.myHttpHandlerComponent.get<Gallery>(this.http.get<Gallery>(Defaults.SERVER_API_GALLERY + 'editingGallery', {headers}), (result: Gallery) => {
      this.gallery = result;
      this.album = null;
      this.albums = result.albums;
      this.images = null;
      this.selectedAlbum = null;
      this.selectedImage = null;

      this.getDone();
    });
  }

  public getAlbum(): void {
    if ( this.myHttpHandlerComponent == null ) {
      return;
    }

    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<GalleryAlbum>(this.http.get<GalleryAlbum>(Defaults.SERVER_API_GALLERY_ALBUM + 'inEditingLanguage/' + this.parameter, {headers}), (result: GalleryAlbum) => {
      this.gallery = null;
      this.album = result;
      this.albums = result.albums;
      this.images = result.images;
      this.selectedAlbum = null;
      this.selectedImage = null;

      this.getDone();
    });
  }

  private getDone(): void {
    if (this.selectedAlbum != null) {
      for (const album of this.albums) {
        if (album.id === this.selectedAlbum.id) {
          this.selectAlbum(album);
        }
      }
    }

    if (this.selectedImage != null) {
      for (const image of this.images) {
        if (image.id === this.selectedImage.id) {
          this.selectImage(image);
        }
      }
    }
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_GALLERY;
  }

  public setCreateAlbumComponent(albumEditor: CreateAlbumComponent): void {
    this.albumEditor = albumEditor;
  }


  public setCreateImageComponent(imageEditor: CreateImageComponent): void {
    this.imageEditor = imageEditor;
  }

  public selectAlbum(album: GalleryAlbum): void {
    this.selectedAlbum = album;
  }

  public selectImage(image: GalleryImage): void {
    this.selectedImage = image;
  }

  private reset(): void {
    this.gallery = null;
    this.album = null;
    this.albums = null;
    this.images = null;
    this.selectedAlbum = null;
    this.selectedImage = null;
  }

  public openAlbum(albumId: string): void {
    this.reset();
    this.galleryComponent.setParameter(albumId);
  }

}
