import {AbstractObject} from '../common/AbstractObject';

export class ProductBulkRequestObject extends AbstractObject{

  public productId: string;

  public categoryId: string;

  public productIds: string[];
}
