<app-dashboard-header mainComponent="gallery"></app-dashboard-header>
<ng-container *ngIf="user != null && user.galleryAppEnabled">
  <app-dashboard-gallery-navigation [component]="component" [galleryComponent]="this"></app-dashboard-gallery-navigation>
</ng-container>
<main>
  <ng-container *ngIf="user != null && user.galleryAppEnabled">
    <div class="menu-full-tools" style="z-index: 2">
      <app-dashboard-gallery-editing-gallery [galleryComponent]="this"></app-dashboard-gallery-editing-gallery>
    </div>
    <app-dashboard-gallery-edit *ngIf="component === 'edit'" [galleryComponent]="this" [parameter]="parameter"></app-dashboard-gallery-edit>
    <app-dashboard-gallery-tokens *ngIf="component === 'tokens'"></app-dashboard-gallery-tokens>
  </ng-container>
  <ng-container *ngIf="user != null && !user.galleryAppEnabled">
    <div>
      <div style="display: flex; align-content: center; justify-content: center; padding-bottom: 15px">
        <img width="128" height="128" src="assets/illustrations/streamline-icon-program-error-2@128x128.png">
      </div>
      <h3 style="clear: both">Sorry, gallery application is not activated for this user.</h3>
    </div>
  </ng-container>
</main>

<app-my-http-handler [iHaveMyHttpHandler]="this"></app-my-http-handler>
