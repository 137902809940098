import {Component, Input} from '@angular/core';
import {StockVariantComponent} from '../variant.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../constants/defaults';
import {IInit} from '../../../abstract/IInit';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {CatalogVariant} from '../../../../domain/stock/CatalogVariant';

@Component({
  selector: 'app-dashboard-stock-variant-editor',
  templateUrl: './variant-editor.component.html',
  styleUrls: ['./variant-editor.component.css']
})
export class StockVariantEditorComponent extends AbstractEditorComponent<CatalogVariant, CatalogVariant> {
  @Input() variantsComponent: StockVariantComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.variantsComponent.setCreateVariantComponent(this);
  }

  protected submitDone() {
    this.variantsComponent.selectVariant(this.object);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_VARIANT;
  }

  protected getTheObject(): CatalogVariant {
    return this.object;
  }

  protected getInitableComponent(): IInit {
    return this.variantsComponent;
  }
}
