<app-normal-popup [editorComponent]="this" title="shop">
  <ng-container *ngIf=" object != null && object.shop != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Shop name</th>
        <td></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td>Name</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.shop.name"></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td style="border-bottom: 1px solid lightgray">Vat nr</td>
        <td style="border-bottom: 1px solid lightgray"><input type="text" autocomplete="none" [(ngModel)]="object.shop.vatNr"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Shop logo</th>
        <td></td>
      </tr>

      <tr>
        <img style="width: 48px; height: 48px" src="{{imageRoot}}small_1_1/{{object.shop.imageId}}.png">
        <td style="border-bottom: 1px solid lightgray">File</td>
        <td style="border-bottom: 1px solid lightgray"><div><input class="left" type="file" (change)="onFileSelect($event)"><button class="right"></button></div></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Shop details</th>
        <td></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td>Address</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.shop.address"></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td>Zipcode</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.shop.zipcode"></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td>Town</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.shop.town"></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td>Time zone</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.shop.timeZone"></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td>Phone</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.shop.phone"></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td>Email</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.shop.email"></td>
      </tr>

      <tr>
        <td valign="top" style="width: 64px"></td>
        <td>Website</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.shop.website"></td>
      </tr>
    </table>
  </ng-container>
</app-normal-popup>
