import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MenuComponent} from '../menu.component';
import {Zone} from '../../../domain/menu/zone';
import {MenuZoneEditor} from './create-zone/menu-zone-editor.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../constants/defaults';
import {MenuTableEditor} from './tables/create-table/menu-table-editor.component';
import {Shop} from '../../../domain/shop/shop';
import {SortableObjectPanel} from '../../SortableObjectPanel';
import {MenuSubComponent} from '../MenuSubComponent';

@Component({
  selector: 'app-dashboard-menu-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.css']
})
export class ZonesComponent extends SortableObjectPanel<Shop, Zone> implements MenuSubComponent, OnChanges {
  @Input() menuComponent: MenuComponent;
  @Input() parameter: string;

  public zoneEditor: MenuZoneEditor;
  public tableEditor: MenuTableEditor;

  public selectedZone: Zone;

  constructor(http: HttpClient) {
    super(http);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  public init(): void {
    this.menuComponent.setSubComponent(this);
    super.init();
  }


  protected getListDone() {
    super.getListDone();

    if ( this.selectedZone != null ) {
      for ( const zone of this.listObject.menuZones ) {
        if ( zone.id === this.selectedZone.id ) {
          this.selectedZone = zone;
        }
      }
    }
  }

  public setMenuZoneEditor(zoneEditor: MenuZoneEditor): void {
    this.zoneEditor = zoneEditor;
  }

  public selectZone(zone: Zone): void {
    this.selectedZone = zone;
  }

  public setMenuTableEditor(tableEditor: MenuTableEditor): void {
    this.tableEditor = tableEditor;
  }

  public getZoneBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_ZONE;
  }

  public getTableBaseEndpoint(): string {
    return Defaults.SERVER_API_MENU_TABLE;
  }

  public getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_ZONE;
  }
}
