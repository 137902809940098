import {Component, Input} from '@angular/core';
import {PosPrintersComponent} from '../../printers.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {PosPrintZone} from '../../../../../domain/pos/PosPrintZone';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-pos-create-print-zone',
  templateUrl: './menu-pos-print-zone-editor.component.html',
  styleUrls: ['./menu-pos-print-zone-editor.component.css']
})
export class MenuPosPrintZoneEditor extends AbstractEditorComponent<PosPrintZone, PosPrintZone>{
  @Input() printersComponent: PosPrintersComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.printersComponent.setMenuPosPrintZoneEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_POS_PRINT_ZONE;
  }

  protected getInitableComponent(): IInit {
    return this.printersComponent;
  }

  protected getTheObject(): PosPrintZone {
    return this.object;
  }
}
