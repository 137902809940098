import {Component, Input} from '@angular/core';
import {EditComponent} from '../../edit.component';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {Condiment} from '../../../../../domain/menu/Condiment';
import {MenuVariant} from '../../../../../domain/menu/MenuVariant';
import {ProductCondiment} from '../../../../../domain/menu/ProductCondiment';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';
import {MenuProductCondimentCreateObject} from './MenuProductCondimentCreateObject';

@Component({
  selector: 'app-dashboard-menu-add-condiment',
  templateUrl: './menu-product-condiment-editor.component.html',
  styleUrls: ['./menu-product-condiment-editor.component.css']
})
export class MenuProductCondimentEditor extends AbstractEditorComponent<MenuProductCondimentCreateObject, ProductCondiment> {
  @Input() editComponent: EditComponent;
  @Input() menuCategoryId: number;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.editComponent.setMenuProductCondimentEditor(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_PRODUCT_CONDIMENT;
  }

  protected getInitableComponent(): IInit {
    return this.editComponent;
  }

  protected getTheObject(): ProductCondiment {
    return this.object.productCondiment;
  }
}
