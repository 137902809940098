<app-normal-popup [editorComponent]="this" title="email subscription">
  <ng-container *ngIf="object != null && object.newsEmailSubscription != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Subscriber</th>
        <td></td>
      </tr>

      <tr>
        <td rowspan="2" valign="top" style="width: 64px"></td>
        <td>Lastname</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.newsEmailSubscription.lastName"></td>
      </tr>
      <tr>
        <td>Firstname</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.newsEmailSubscription.firstName"></td>
      </tr>
      <tr>
        <td></td>
        <td>Email</td>
        <td><input type="text" autocomplete="none" [(ngModel)]="object.newsEmailSubscription.email"></td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Language</td>
        <td>
          <label>
            <select [(ngModel)]="object.newsEmailSubscription.languageCode">
              <option *ngFor="let shopLanguage of object.shopLanguages" [ngValue]="shopLanguage.languageCode">{{shopLanguage.language.name}}</option>
            </select>
          </label>
        </td>
      </tr>
    </table>
  </ng-container>
</app-normal-popup>
