import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../constants/defaults';
import {User} from '../../domain/user/user';
import {HttpHandler} from '../../handlers/httpHandler';
import {IHaveMyHttpHandler} from '../my-http-handler/IHaveMyHttpHandler';
import {MenuSubComponent} from '../menu/MenuSubComponent';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent extends IHaveMyHttpHandler {

  private componentNames: Array<string> = ['select', 'edit', 'tokens'];
  public component: string;
  public parameter: string;

  public user: User;

  private subComponent: MenuSubComponent;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {
    super();
  }

  public init(): void {
    this.setComponents();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setComponents();
      }
    });

    this.getUser();
  }

  private getUser(): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<User>(this.http.get<User>(Defaults.SERVER_API_USER, { headers }), (result: User) => {
      this.user = result;
    })
  }

  private setComponents(): void {
    this.component = this.route.snapshot.paramMap.get('component');
    this.parameter = this.route.snapshot.paramMap.get('parameter');

    if (this.componentNames.indexOf(this.component) === -1) {
      this.component = this.componentNames[this.componentNames.length - 1];
    }
    if ( this.parameter === null ) {
      this.parameter = '-1';
    }
  }

  public setSubComponent(subComponent: MenuSubComponent) {
    this.subComponent = subComponent;
  }

  public selectionChanged(): void {
    if ( this.subComponent != null ) {
      this.subComponent.init();
    }
  }

  public openComponent(component: string): void {
    this.component = component;
    this.parameter = 'root';

    this.navigate();
  }

  public setParameter(parameter: string): void {
    this.parameter = parameter;
    this.navigate();
  }

  private navigate(): void {
    let url = '/gallery/';

    url += this.component;
    url += '/' + this.parameter;

    this.router.navigateByUrl(url);
  }

}
