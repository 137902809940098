<app-left-side-container [iHaveMyHttpHandler]="this">
  <div class="dashboard-item">
    <!-- Albums -->
    <div>
      <h4 class="left"><ng-container *ngIf="album != null">Sub </ng-container>Albums</h4>
      <div class="right">
        <img src="assets/icons/add.png" alt="Add Icon" class="image-button right" (click)="albumEditor.open(0, parameter, null)">
      </div>
    </div>

    <ng-container *ngIf="albums != null">
      <ng-container *ngFor="let album of albums">
        <div class="bordered-item hoverable" [ngClass]="{'selected': album === selectedAlbum, 'inactive-item': album.active !== 'A'}" (click)="selectAlbum(album)" (dblclick)="openAlbum(album.id)">
          <div class="dashboard-bordered-item-sub-left">
            <div class="table-menu-image-container"><img class="table-menu-image" src="{{SERVER_CONTENT}}/menu/8563332019131217091257114363.jpeg"></div>
          </div>
          <div class="dashboard-bordered-item-sub-left width-img-4buttons">
            <p><strong><ng-container *ngIf="album.details != null && album.details.length > 0">{{album.details[0].name}}</ng-container></strong></p>
            <p><ng-container *ngIf="album.details != null && album.details.length > 0">{{album.details[0].description}}</ng-container></p>
          </div>
          <div class="dashboard-bordered-item-sub-right">
            <div class="dashboard-bordered-item-sub-right-top">
              <div class="image-button-box"><img *ngIf="album.sequence > 0" (click)="moveUp(album, albumEditor.getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
              <div class="image-button-box"><img *ngIf="album.sequence < albums.length - 1" (click)="moveDown(album, albumEditor.getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
              <div class="image-button-box"><img (click)="openAlbum(album.id)" class="image-button" src="assets/icons/select_dark.png" alt="SELECT"></div>
              <div class="image-button-box"><img (click)="albumEditor.open(1, null, album.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="dashboard-item">
    <ng-container *ngIf="parameter !== 'root'">
      <!-- Images -->
      <div>
        <h4 class="left">Images</h4>
        <div class="right">
          <img src="assets/icons/add.png" alt="Add Icon" class="image-button right" (click)="imageEditor.open(0, parameter, null)">
        </div>
      </div>

      <ng-container *ngIf="images != null">
        <ng-container *ngFor="let image of images">
          <div class="bordered-item hoverable" [ngClass]="{'selected': image === selectedImage, 'inactive-item': image.active !== 'A'}" (click)="selectImage(image)" (dblclick)="imageEditor.open(1, null, image.id)">
            <div class="dashboard-bordered-item-sub-left">
              <div class="table-menu-image-container"><img class="table-menu-image" src="{{SERVER_CONTENT}}/get/96/4-4/{{image.imageId}}.png"></div>
            </div>
            <div class="dashboard-bordered-item-sub-left width-img-4buttons">
              <p><strong><ng-container *ngIf="image.details != null && image.details.length > 0">{{image.details[0].name}}</ng-container></strong></p>
              <p><ng-container *ngIf="image.details != null && image.details.length > 0">{{image.details[0].description}}</ng-container></p>
            </div>
            <div class="dashboard-bordered-item-sub-right">
              <div class="dashboard-bordered-item-sub-right-top">
                <div class="image-button-box"><img *ngIf="image.sequence > 0" (click)="moveUp(image, imageEditor.getApiRootEndpoint())" class="image-button" src="assets/icons/up.png"></div>
                <div class="image-button-box"><img *ngIf="image.sequence < images.length - 1" (click)="moveDown(image, imageEditor.getApiRootEndpoint())" class="image-button" src="assets/icons/down.png"></div>
                <div class="image-button-box"><img (click)="imageEditor.open(1, null, image.id)" class="image-button" src="assets/icons/edit_dark.png"></div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</app-left-side-container>

<app-dashboard-gallery-create-album [editComponent]="this"></app-dashboard-gallery-create-album>
<app-dashboard-gallery-create-image [editComponent]="this"></app-dashboard-gallery-create-image>
