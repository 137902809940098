import {Component, Input, OnInit} from '@angular/core';
import {MenuClosureComponent} from '../menu-closure.component';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {Shop} from '../../../../domain/shop/shop';
import {HttpClient} from '@angular/common/http';
import {IInit} from '../../../abstract/IInit';
import {Defaults} from '../../../../constants/defaults';
import {ShopCreateObject} from '../../shop/update-shop-component/ShopCreateObject';

@Component({
  selector: 'app-dashboard-shop-menu-closure-editor',
  templateUrl: './menu-closure-editor.component.html',
  styleUrls: ['./menu-closure-editor.component.css']
})
export class MenuClosureEditorComponent extends AbstractEditorComponent<ShopCreateObject, Shop> {
  @Input() menuClosureComponent: MenuClosureComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.menuClosureComponent.setMenuClosureEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_SHOP;
  }

  protected getInitableComponent(): IInit {
    return this.menuClosureComponent;
  }

  protected getTheObject(): Shop {
    return this.object.shop;
  }

}
