import {IHaveMyHttpHandler} from '../../my-http-handler/IHaveMyHttpHandler';
import {EditingSelectionDisplayNames} from './EditingSelectionDisplayNames';
import {AbstractSelectComponent} from './popups/select-menu/AbstractSelectComponent';
import {AbstractRootEditorComponent} from '../../abstract/AbstractRootEditorComponent';
import {AbstractObject} from '../../../domain/common/AbstractObject';
import {AbstractEditorComponent} from '../../abstract/AbstractEditorComponent';
import {EditingSelectionCreateObject} from './EditingSelectionCreateObject';
import {IInit} from '../../abstract/IInit';
import {HttpHandler} from '../../../handlers/httpHandler';

export abstract class AbstractEditingSelectionComponent<CO, C extends AbstractObject> extends AbstractEditorComponent<C, C>{

  public abstractSelectComponent: AbstractSelectComponent<CO, C>;
  public editorComponent: AbstractEditorComponent<CO, C>;

  public displayNames: EditingSelectionDisplayNames;

  public abstract selectionChanged(): void;

  public init() {
    this.getEditingNames();
  }

  private getEditingNames(): void {
    const headers = HttpHandler.getSessionHeaders();

    this.myHttpHandlerComponent.get<EditingSelectionDisplayNames>(this.http.get<EditingSelectionDisplayNames>( this.getApiRootEndpoint() + 'getDisplayNames', { headers }), (result: EditingSelectionDisplayNames) => {
      this.displayNames = result;
    });
  }

  public setSelectComponent(abstractSelectComponent: AbstractSelectComponent<CO, C>): void {
    this.abstractSelectComponent = abstractSelectComponent;
  }

  public setAbstractEditorComponent(editorComponent: AbstractEditorComponent<CO, C>): void {
    this.editorComponent = editorComponent;
  }

  protected getInitableComponent(): IInit {
    return null;
  }

  protected getTheObject(): C {
    return null;
  }
}
