import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Defaults} from '../../../../../constants/defaults';
import {CondimentsComponent} from '../../condiments.component';
import {Condiment} from '../../../../../domain/menu/Condiment';
import {AbstractEditorComponent} from '../../../../abstract/AbstractEditorComponent';
import {IInit} from '../../../../abstract/IInit';

@Component({
  selector: 'app-dashboard-menu-condiment-editor',
  templateUrl: './menu-condiment-editor.component.html',
  styleUrls: ['./menu-condiment-editor.component.css']
})
export class menuCondimentEditor extends AbstractEditorComponent<Condiment, Condiment> {
  @Input() condimentsComponent: CondimentsComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.condimentsComponent.setCreateCondimentComponent(this);
  }

    getApiRootEndpoint(): string {
    return Defaults.SERVER_API_MENU_CONDIMENT;
  }

  protected getInitableComponent(): IInit {
    return this.condimentsComponent;
  }

  protected getTheObject(): Condiment {
    return this.object;
  }
}
