<div class="theleft">
  <div class="nav">
    <table>

      <tr id="statistics" title="Menu" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'statistics'}" (click)="openComponent('statistics')">
        <td class="nav-item-img"><img src="assets/icons/statistics_dark.png" alt="EDIT"></td>
        <td class="nav-item-title"><h4>Statistics</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="menu" title="Menu" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'edit'}" (click)="openComponent('edit')">
        <td class="nav-item-img"><img src="assets/icons/dark/streamline-icon-restaurant-menu@24x24.png" alt="EDIT"></td>
        <td class="nav-item-title"><h4>Menu</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="variants" title="Supplements" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'variants'}" (click)="openComponent('variants')">
        <td class="nav-item-img"><img src="assets/icons/variants.png" alt="VARIANTS"></td>
        <td class="nav-item-title"><h4>Variants</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="supplements" title="Supplements" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'supplements'}" (click)="openComponent('supplements')">
        <td class="nav-item-img"><img src="assets/icons/dark/streamline-icon-tea-cup@24x24.png" alt="SUPPLEMENTS"></td>
        <td class="nav-item-title"><h4>Supplements</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="condiments" title="Condiments" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'condiments'}" (click)="openComponent('condiments')">
        <td class="nav-item-img"><img src="assets/icons/dark/streamline-icon-seasoning-pepper@24x24.png" alt="CONDIMENTS"></td>
        <td class="nav-item-title"><h4>Condiments</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>

      <tr id="promotion" title="Promotion" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'promotion'}" (click)="openComponent('promotion')">
        <td class="nav-item-img"><img src="assets/icons/streamline-icon-tag-euro@24x24.png" alt="PROMOTION"></td>
        <td class="nav-item-title"><h4>Promotions</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="discount-coupon" title="Promotion" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'discount-coupon'}" (click)="openComponent('discount-coupon')">
        <td class="nav-item-img"><img src="assets/icons/streamline-icon-discount-bubble@24x24.png" alt="DISCOUNT COUPON"></td>
        <td class="nav-item-title"><h4>Discount coupons</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="availability" title="Availability" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'availability'}" (click)="openComponent('availability')">
        <td class="nav-item-img"><img src="assets/icons/dark/streamline-icon-time-clock-hand-1@24x24.png" alt="AVAILABILITY"></td>
        <td class="nav-item-title"><h4>Availability</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>

      <tr id="delivery" title="Images" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'delivery'}" (click)="openComponent('delivery')">
        <td class="nav-item-img"><img src="assets/icons/delivery.png" alt="IMAGES"></td>
        <td class="nav-item-title"><h4>Delivery</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="zones" title="Zones" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'zones'}" (click)="openComponent('zones')">
        <td class="nav-item-img"><img src="assets/icons/dark/streamline-icon-style-three-pin-bench-1@24x24.png" alt="ZONES"></td>
        <td class="nav-item-title"><h4>Tables (Horeca)</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="parking" title="Zones" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'parking'}" (click)="openComponent('parking')">
        <td class="nav-item-img"><img src="assets/icons/streamline-icon-parking-p-1@24x24.png" alt="PARKING"></td>
        <td class="nav-item-title"><h4>Parking (Horeca)</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="offices" title="Zones" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'offices'}" (click)="openComponent('offices')">
        <td class="nav-item-img"><img src="assets/icons/streamline-icon-office-desk-2@24x24.png" alt="OFFICES"></td>
        <td class="nav-item-title"><h4>Offices (Companies)</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="rooms" title="Zones" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'rooms'}" (click)="openComponent('rooms')">
        <td class="nav-item-img"><img src="assets/icons/streamline-icon-hotel-double-bed-2@24x24.png" alt="ROOMS"></td>
        <td class="nav-item-title"><h4>Rooms (Hotels)</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>
      <tr class="nav-separator"><td></td></tr>

      <tr id="qr" title="Images" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'qrconfig'}" (click)="openComponent('qrconfig')">
        <td class="nav-item-img"><img src="assets/icons/qr_dark.png" alt="QR"></td>
        <td class="nav-item-title"><h4>QR Configuration</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="tablet" title="Images" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'tablets'}" (click)="openComponent('tablets')">
        <td class="nav-item-img"><img src="assets/icons/tablet_dark.png" alt="TABLETS"></td>
        <td class="nav-item-title"><h4>Menu Tablets</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="tablets" title="Pos Tablets" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'pos-tablets'}" (click)="openComponent('pos-tablets')">
        <td class="nav-item-img"><img src="assets/icons/tablet_dark.png" alt="VARIANTS"></td>
        <td class="nav-item-title"><h4>Pos Tablets</h4></td>
      </tr>

      <tr class="nav-separator"><td></td></tr>

      <tr id="printers" title="Pos Printers" class="nav-item" [ngClass]="{'nav-item-selected': getLeftComponent() === 'pos-printers'}" (click)="openComponent('pos-printers')">
        <td class="nav-item-img"><img src="assets/icons/printer_dark.png" alt="SUPPLEMENTS"></td>
        <td class="nav-item-title"><h4>Pos Printers</h4></td>
      </tr>
    </table>
  </div>
</div>
