<app-normal-popup [editorComponent]="this" title="option">
  <ng-container *ngIf="object != null">
    <table>
      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Variant Details</th>
        <td></td>
      </tr>

      <ng-container *ngFor="let detail of object.details">
        <tr>
          <td valign="top" style="width: 64px"><img width="40" style="margin: 8px 0 0 8px" src="assets/flags/{{detail.languageCode}}-s.jpg" alt="Language illustration flag"></td>
          <td>Name</td>
          <td><input type="text" autocomplete="none" [(ngModel)]="detail.name"></td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Product Price</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Euro</td>
        <td><input [(ngModel)]="object.price"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Option options</th>
        <td></td>
      </tr>
      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/streamline-icon-cursor-choose.png" alt="ACTIVE"></td>
        <td>Is default option: </td>
        <td>
          <label>
            <select [(ngModel)]="object.defaultOption">
              <option value="Y">YES</option>
              <option value="N">NO</option>
            </select>
          </label>
        </td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Condition</th>
        <td></td>
      </tr>

      <tr>
        <td></td>
        <td>Condition key</td>
        <td><input type="text" value="0" [(ngModel)]="object.conditionKey"/></td>
      </tr>

      <tr>
        <td align="right"><img class="image-icon" src="assets/icons/variants.png"></td>
        <td>Enabled by condition</td>
        <td>
          <select [disabled]="mode === 2" [(ngModel)]="object.conditionEnabled">
            <option value="Y">Yes</option>
            <option value="N">No</option>
          </select>
        </td>
      </tr>

      <tr>
        <td align="right"></td>
        <td>Condition</td>
        <td><input type="text" [disabled]="mode === 2" [(ngModel)]="object.enablingCondition"></td>
      </tr>

      <tr>
        <td></td>
        <th align="left" class="table-sub-header">Similar option</th>
        <td></td>
      </tr>

      <tr>
        <td align="right"></td>
        <td>Unique maker</td>
        <td><input type="text" [disabled]="mode === 2" [(ngModel)]="object.similarOptionUniqueMaker"></td>
      </tr>

    </table>
  </ng-container>
</app-normal-popup>
