import {Component, Input} from '@angular/core';
import {EmailTemplatesComponent} from '../email-templates.component';
import {AbstractEditorComponent} from '../../../abstract/AbstractEditorComponent';
import {CatalogEmailTemplate} from '../../../../domain/stock/CatalogEmailTemplate';
import {HttpClient} from '@angular/common/http';
import {IInit} from '../../../abstract/IInit';
import {Defaults} from '../../../../constants/defaults';

@Component({
  selector: 'app-dashboard-stock-email-template-editor',
  templateUrl: './email-template-editor.component.html',
  styleUrls: ['./email-template-editor.component.css']
})
export class EmailTemplateEditorComponent extends AbstractEditorComponent<CatalogEmailTemplate, CatalogEmailTemplate>{
  @Input() emailTemplatesComponent: EmailTemplatesComponent;

  constructor(http: HttpClient) {
    super(http);
  }

  public init(): void {
    this.emailTemplatesComponent.setEmailTemplateEditorComponent(this);
  }

  getApiRootEndpoint(): string {
    return Defaults.SERVER_API_CATALOG_EMAIL_TEMPLATE;
  }

  protected getInitableComponent(): IInit {
    return this.emailTemplatesComponent;
  }

  protected getTheObject(): CatalogEmailTemplate {
    return this.object;
  }

}
